import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'

import type { SkillResponse } from 'api/skills'

import { showError, showSuccess } from 'slices/notificationSlice'
import { clearErrorMessage, updateSkill, selectSkillsStatus, getSkillList } from 'slices/skillsSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'

import { InputFormat, List, CardSubmitFooter, CustomButton, NavMenu } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { ListItem } from 'components/common/types'
import { ColumnSizes } from 'components/common/utils'

import placeholderImage from 'images/allEmpty.svg'

import SkillCreate from './SkillCreate'
import SkillDelete from './SkillDelete'

import styles from './Skills.module.scss'

const Skills: React.FC = () => {
  const [skills, setSkills] = React.useState<SkillResponse[]>([])
  const [skillNameValidity, setSkillNameValidity] = React.useState(false)
  const [skillName, setSkillName] = React.useState<string | undefined>(undefined)
  const [selectedSkillId, setSelectedSkillId] = React.useState<number | undefined>(undefined)
  const [openCreate, setOpenCreate] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const dispatch = useDispatch()

  const skillsStatus = useSelector(selectSkillsStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getSkillList())
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    setSkills(skillsStatus.skills)
    const skill = skillsStatus.skills.find(s => s.skillId === selectedSkillId)
    setSkillName(skill?.name || skillsStatus.skills[0]?.name)
    setSelectedSkillId(skill?.skillId || skillsStatus.skills[0]?.skillId)
  }, [skillsStatus.skills, selectedSkillId])

  const hasSkills = React.useMemo(() => skills.length > 0, [skills])
  const selected = React.useMemo(
    () => skills.find(skill => skill.skillId === selectedSkillId),
    [selectedSkillId, skills]
  )

  const skillItems: ListItem[] = React.useMemo(
    () => (hasSkills ? skills.map(skill => ({ id: skill.skillId, title: skill.name })) : []),
    [skills, hasSkills]
  )

  const disabled = React.useMemo(() => !(skillName && skillNameValidity), [skillName, skillNameValidity])
  const unchanged = React.useMemo(() => skillName === selected?.name, [selected, skillName])

  const handleCreate = () => {
    setOpenCreate(false)
    dispatch(showSuccess())
  }

  const handleDelete = () => {
    setOpenDelete(false)
    dispatch(showSuccess())
  }

  const onCancel = () => {
    setSkillName(selected?.name)
  }

  const onSubmit = () => {
    if (selectedSkillId && skillName) {
      setSubmitted(true)
      dispatch(updateSkill(selectedSkillId, skillName))
    } else {
      dispatch(showError())
    }
  }
  React.useEffect(() => {
    if (skillsStatus.isRequesting || !submitted) {
      return
    }
    if (skillsStatus.errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, skillsStatus.isRequesting, skillsStatus.errorMessage, dispatch])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between">
          <div className="font-x-large fw-bold align-self-center">スキル管理</div>
          <CustomButton icon="plus" outline onClick={() => setOpenCreate(true)}>
            スキル追加
          </CustomButton>
        </div>

        <Row className="py-3">
          <Col md={4}>
            <Card className={`position-sticky ${styles.list}`}>
              {hasSkills ? (
                <List
                  items={skillItems}
                  selectedId={selectedSkillId}
                  onAction={((skillId: number) => setSelectedSkillId(skillId)) as (s: string | number) => void}
                />
              ) : (
                <CardBody className={`${styles.placeholder} text-center my-5`}>
                  <img src={placeholderImage} alt="" />
                  <h3 className="mt-4">スキルがまだ登録されていません</h3>
                  <p>まずは最初のスキルを登録してみましょう。</p>
                  <Button color="secondary" size="sm" outline>
                    スキルについてもっと詳しく
                  </Button>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8}>
            <Card className="h-100 d-flex flex-column">
              {hasSkills ? (
                <>
                  <CardBody className="flex-grow-0">
                    <CardTitle className="font-large fw-bold">スキル情報</CardTitle>
                    <InputFormat
                      label="名前※"
                      placeholder="名前を入力"
                      value={skillName}
                      validations={[Rules.Required]}
                      maxLength={100}
                      size={ColumnSizes.middle}
                      onChange={value => setSkillName(value)}
                      onValidate={setSkillNameValidity}
                    />
                  </CardBody>

                  <CardBody className="flex-grow-1">
                    <CardTitle className="font-large fw-bold">スキルの削除</CardTitle>
                    <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                      このスキルを削除
                    </Button>
                  </CardBody>
                  <div className="flex-grow-0">
                    <CardSubmitFooter
                      onCancel={onCancel}
                      onSubmit={onSubmit}
                      updatedBy={selected?.updatedBy}
                      updatedAt={selected?.updatedAt}
                      cancelDisabled={unchanged}
                      submitDisabled={unchanged || disabled}
                    />
                  </div>
                </>
              ) : (
                <CardBody className={`${styles.placeholder} text-center my-5`}>
                  <img src={placeholderImage} alt="" />
                  <h3 className="mt-4">スキルが選択されていません</h3>
                  <p>スキルを選択して、詳細情報を編集しましょう。</p>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <SkillCreate isOpen={openCreate} onSuccess={handleCreate} onCancel={() => setOpenCreate(false)} />

        {selectedSkillId && (
          <SkillDelete
            isOpen={openDelete}
            skillId={selectedSkillId}
            onSuccess={handleDelete}
            onCancel={() => setOpenDelete(false)}
          />
        )}
      </div>
    </NavMenu>
  )
}

export default Skills
