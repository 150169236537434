import moment from 'moment'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { BopExportDataTypes } from 'api/tenants'

import { getWorkspaceList } from 'slices/workspacesSlice'

import { NavMenu, BadgeLabel, CustomButton, PeriodSelect } from 'components/common'

import useBopReportsQuery from 'hooks/useBopReportsQuery'

import CsvExportDialog from './CsvExportDialog'

type Props = {
  selectedWorkspaceIds: number[]
  children: React.ReactElement
}

const pathItems = [
  {
    path: '/bop-reports/bop',
    label: '収支',
    exportDataType: BopExportDataTypes.Summary,
  },
  {
    path: '/bop-reports/profit-and-loss',
    label: '損益詳細',
    exportDataType: BopExportDataTypes.Detail,
  },
]

const bopPeriodList = [
  {
    label: '今週',
    periodStart: moment().day() === 0 ? moment().subtract(1, 'days').day(1).toDate() : moment().day(1).toDate(),
    periodEnd: moment().day() === 0 ? moment().toDate() : moment().day(1).add(6, 'days').toDate(),
    id: 'thisWeek',
  },
  {
    label: '先週',
    periodStart:
      moment().day() === 0
        ? moment().subtract(8, 'days').day(1).toDate()
        : moment().subtract(7, 'days').day(1).toDate(),
    periodEnd:
      moment().day() === 0
        ? moment().subtract(7, 'days').toDate()
        : moment().subtract(7, 'days').day(1).add(6, 'days').toDate(),
    id: 'lastWeek',
  },
  {
    label: '今月',
    periodStart: moment().startOf('month').toDate(),
    periodEnd: moment().endOf('month').toDate(),
    id: 'thisMonth',
  },
  {
    label: '先月',
    periodStart: moment().subtract(1, 'month').startOf('month').toDate(),
    periodEnd: moment().subtract(1, 'month').endOf('month').toDate(),
    id: 'lastMonth',
  },
  {
    label: '開始日と終了日を設定',
    periodStart: moment().toDate(),
    periodEnd: moment().toDate(),
    id: 'user_setting',
  },
]

const BopReportsCommon: React.FC<Props> = props => {
  const { selectedWorkspaceIds, children } = props
  const { queryStart, queryEnd } = useBopReportsQuery()
  const [openCsvExportDialog, setOpenCsvExportDialog] = React.useState(false)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const pathItem = React.useMemo(() => pathItems.find(({ path }) => matchPath({ path }, pathname)), [pathname])

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const onPeriodChange = React.useCallback(
    (start: Date, end: Date) => {
      navigate(`?start=${moment(start).format('YYYY-MM-DD')}&end=${moment(end).format('YYYY-MM-DD')}`, {
        replace: true,
      })
    },
    [navigate]
  )

  return (
    <NavMenu>
      <div className="m-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold">収支レポート</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={pathItem?.label || ''} />
            </div>
          </div>
          <div className="d-flex  ms-auto">
            <CustomButton className="me-2" outline onClick={() => setOpenCsvExportDialog(true)}>
              CSVエクスポート
            </CustomButton>
            <PeriodSelect
              period={{ start: moment(queryStart).toDate(), end: moment(queryEnd).toDate() }}
              periodListData={bopPeriodList}
              minDate={moment().subtract(425, 'days').toDate()}
              maxDate={moment().add(45, 'days').toDate()}
              onChange={(start, end) => onPeriodChange(start, end)}
            />
          </div>
        </div>
        <div>{children}</div>

        <CsvExportDialog
          open={openCsvExportDialog}
          setOpen={setOpenCsvExportDialog}
          selectedWorkspaceIds={selectedWorkspaceIds}
          exportDataType={pathItem?.exportDataType || BopExportDataTypes.Summary}
        />
      </div>
    </NavMenu>
  )
}

export default BopReportsCommon
