import axios from 'axios'

import type { ScheduleResponse } from 'api/schedules'

import type { CommonParams } from 'slices/utils'

import type { UpdateWorkSchedule } from './works'

export type PerformanceIndexState = {
  scheduleTypeId: number
  index: number | null
}

type WorkRecordState = {
  time: string
  value: number
}

type HourlyWorkRecordState = {
  scheduleTypeId: number
  records: WorkRecordState[]
}

type WorkersUploadResponse = {
  uploadUrl: string
}

export type WorkerResponse = {
  workerId: number
  name: string
  wmsMemberId: string
  officialDutyId: number | null
  workspaceId: number
  groupId: number | null
  groupLeader: boolean
  hiredAt: string
  skillIds: number[]
  performanceIndices: PerformanceIndexState[]
  records: HourlyWorkRecordState[]
  schedules: ScheduleResponse[]
  createdAt: string
  updatedBy: string
  updatedAt: string
}

export type WorkerListResponse = {
  workers: WorkerResponse[]
}

export type WorkerScheduleUpdateType = {
  scheduleTypeId: number | null
  startAt: string
  duration: number
}

export type WorkerShiftsUpdateType = {
  schedules: UpdateWorkSchedule[]
  originalSchedules: ScheduleResponse[]
}

export type WorkerEditDataType = {
  name?: string
  wmsMemberId?: string
  officialDutyId?: number | null
  workspaceId?: number
  groupId?: number | null
  groupLeader?: boolean
  hiredAt?: string | null
  skillIds?: number[]
  performanceIndices?: PerformanceIndexState[]
}

export type CheckExportDataReadyResponse = {
  downloadUrl: string
}

export const getWorkerList = (params: CommonParams, useSchedules = true): Promise<WorkerListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { useSchedules } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorker = (
  params: CommonParams,
  workerId: number,
  useSchedules = true,
  from: string,
  to: string,
  filterByShift = false
): Promise<WorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers/${workerId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { useSchedules, from, to, filterByShift } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createWorker = (params: CommonParams, data: WorkerEditDataType): Promise<WorkerResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateWorker = (
  params: CommonParams,
  workerId: number,
  data: WorkerEditDataType
): Promise<WorkerResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers/${workerId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteWorker = (params: CommonParams, workerId: number): Promise<WorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers/${workerId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers, params: { updatedBy: params.updatedBy } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const workersUploadUrl = (params: CommonParams, fileName: string): Promise<WorkersUploadResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers/upload_url`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { fileName }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const putUploadUrl = (params: CommonParams, url: string, csvContent: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const headers = {
      'Content-Type': 'text/csv',
    }
    axios
      .put(url, csvContent, { headers })
      .then(() => resolve())
      .catch(error => reject(error))
  })
}

export const exportWorkers = (
  params: CommonParams,
  targetWorkspaces: number[],
  forecastProductivity: boolean,
  includeUnaffiliatedWorker: boolean
): Promise<CheckExportDataReadyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers/data_export`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { targetWorkspaces, forecastProductivity, includeUnaffiliatedWorker }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateWorkerShifts = (
  params: CommonParams,
  workspaceId: number,
  workerId: number,
  date: string,
  data: WorkerShiftsUpdateType
): Promise<WorkerResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/workers/${workerId}/date/${date}/schedules`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
