type NumberWithMaxCharacters = {
  maxCharacters: number
  decimalPlaces: number
}

export const Required = (value: string | undefined): string => {
  if (typeof value === 'undefined' || value.length > 0) {
    return ''
  }
  return '入力必須項目です'
}

export const MinLength =
  (n: number) =>
  (value: string | undefined): string => {
    if (typeof value === 'undefined' || value.length >= n) {
      return ''
    }
    return `${n}文字以上入力してください`
  }

export const Email = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^[\x20-\x7e]+@[\x20-\x7e]+$/.test(value)) {
    return ''
  }
  return '正しいメールアドレスを入力してください'
}

export const Password = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^[\x21-\x7e]{8,32}$/.test(value)) {
    return ''
  }
  return 'パスワードの形式が間違っています'
}

export const PasswordConfirmation =
  (password: string | undefined) =>
  (value: string | undefined): string => {
    if (typeof value === 'undefined' || value === password) {
      return ''
    }
    return 'パスワードが一致していません'
  }

export const DifferentPassword =
  (old: string | undefined) =>
  (value: string | undefined): string => {
    if (typeof value === 'undefined' || value !== old) {
      return ''
    }
    return '現在設定中のパスワードは利用できません'
  }

export const PhoneNumber = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^[-\d()]*$/.test(value)) {
    return ''
  }
  return '使用可能な文字は半角の数字・ハイフン・カッコのみです'
}

export const PostalCode = (value: string | undefined): string => {
  if (typeof value === 'undefined' || value === '' || /^\d{7}$/.test(value)) {
    return ''
  }
  return '正しい郵便番号を入力してください'
}

export const PositiveInteger = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^(\d*)$/.test(value)) {
    return ''
  }
  return '0以上の整数を入力してください'
}

export const StringLengthRange =
  (min: number, max: number) =>
  (value: string | undefined): string => {
    if (typeof value === 'undefined' || (value.length >= min && value.length <= max)) {
      return ''
    }

    return `${min}文字以上､${max}文字以下で入力してください`
  }

export const validateNumberWithMaxCharacters = (options: NumberWithMaxCharacters) => {
  const { maxCharacters, decimalPlaces } = options

  return (value: string | undefined): string => {
    if (typeof value === 'undefined') {
      return ''
    }
    // 文字数のチェック
    if (value.length > maxCharacters) {
      return `${maxCharacters}文字以内で入力してください`
    }

    // 数値の形式をチェック
    const regex =
      decimalPlaces > 0 ? new RegExp(`^(0|[1-9][0-9]*)(\\.\\d{0,${decimalPlaces}})?$`) : new RegExp(`^(0|[1-9][0-9]*)$`)

    if (!regex.test(value)) {
      return `小数点含め${maxCharacters}文字以内で、小数点第${decimalPlaces}位まで入力してください`
    }

    return ''
  }
}
