import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearErrorMessage, getAllocationList, deleteAllocation, selectAllocationStatus } from 'slices/allocationsSlice'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  workspaceId: number
  allocationId: number | undefined
  onSuccess: () => void
  onCancel: () => void
}

const WorkspaceAllocationDelete: React.FC<Props> = ({ isOpen, workspaceId, allocationId, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectAllocationStatus)

  const handleDeleteClick = () => {
    if (allocationId) {
      setSubmitted(true)
      dispatch(deleteAllocation(workspaceId, allocationId))
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      setModalErrorMessage('削除できませんでした。')
      dispatch(clearErrorMessage())
    }
    dispatch(getAllocationList(workspaceId))
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, workspaceId, dispatch, onSuccess])

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="作業間配布設定の削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">この作業間配賦設定を本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default WorkspaceAllocationDelete
