import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, CardBody, CardText } from 'reactstrap'

import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import useBopReportsQuery from 'hooks/useBopReportsQuery'

import BopReportsCommon from './BopReportsCommon'

import styles from './BopReportsBop.module.scss'

const BopReportsBop: React.FC = () => {
  const { queryStart, queryEnd } = useBopReportsQuery()
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const workspaceIds = React.useMemo(() => workspaces.map(w => w.workspaceId).slice(0, 5), [workspaces])

  return (
    <BopReportsCommon selectedWorkspaceIds={workspaceIds}>
      <div className={styles.row}>
        <Card className="mb-3 h-100">
          <CardBody>
            <div>収支</div>
            <Card>
              <CardBody>
                <CardText>
                  収支テスト start={queryStart} end={queryEnd}
                </CardText>
                <div>{workspaceIds.toString()}</div>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </div>
    </BopReportsCommon>
  )
}

export default BopReportsBop
