import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import moment from 'moment'

import * as API from 'api/sessions'
import * as UserAPI from 'api/users'
import { UNREACHABLE_ERROR_STATUS_CODE, INCORRECT_OLD_PASSWORD_MESSAGE, makeErrorMessage } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { ColorType } from 'components/common/types'
import { ColorTypes } from 'components/common/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type TeamState = {
  workspaceId: number
  workspaceName: string
  groupId: number
  groupName: string
  groupColor: ColorType
  workerId: number
  workerName: string
}

type SessionState = API.LoginResponse & {
  isRequesting: boolean
  errorMessage: string
  loggedIn: boolean
  activated: boolean
  team: TeamState
}

const cleanState: SessionState = {
  loggedIn: false,
  activated: false,
  newPasswordRequired: false,
  session: null,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  expirationDate: null,
  user: {
    userId: '',
    email: '',
    name: '',
    role: UserAPI.Role.TeamAdmin,
    canViewBOP: false,
    canManageBOP: false,
    tenants: [],
  },
  team: {
    workspaceId: 0,
    workspaceName: '',
    groupId: 0,
    groupName: '',
    groupColor: ColorTypes.Silver,
    workerId: 0,
    workerName: '',
  },
  isRequesting: false,
  errorMessage: '',
}

const initialState = _.cloneDeep(cleanState)

const sessionStorageValue = sessionStorage.getItem('sessionState')
if (sessionStorageValue) {
  const storageSessionState = JSON.parse(sessionStorageValue) as SessionState
  _.merge(initialState, storageSessionState)
}

export const sessionSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    requestLogin: state => {
      _.merge(state, cleanState, { isRequesting: true })
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    loginSuccess: (state, action: PayloadAction<API.LoginResponse>) => {
      _.merge(state, action.payload, {
        isRequesting: false,
        loggedIn: true,
        activated: !action.payload.newPasswordRequired,
      })
      if (!action.payload.newPasswordRequired) {
        if (action.payload.user.role === UserAPI.Role.TenantAdmin) {
          // tenantAdmin は強制的に 30 分でログアウトさせる
          state.expirationDate = moment().add(30, 'minutes').toISOString()
        } else {
          // 念のためにセッションが切れる 30 分前にリフレッシュするよう期限を縮める
          state.expirationDate = moment(state.expirationDate).subtract(30, 'minutes').toISOString()
        }
      }

      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    activateSuccess: (state, action: PayloadAction<API.ActivateResponse>) => {
      _.merge(state, action.payload, {
        isRequesting: false,
        loggedIn: true,
        activated: true,
      })
      if (action.payload.user.role === UserAPI.Role.TenantAdmin) {
        state.expirationDate = moment().add(30, 'minutes').toISOString()
      } else {
        state.expirationDate = moment(state.expirationDate).subtract(30, 'minutes').toISOString()
      }

      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    updateAccountInformationSuccsess: (state, action: PayloadAction<UserAPI.UserResponse>) => {
      _.merge(state, { user: action.payload }, { isRequesting: false, errorMessage: '' })
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    refreshSuccess: (state, action: PayloadAction<API.RefreshResponse>) => {
      _.merge(state, action.payload)
      state.expirationDate = moment(state.expirationDate).subtract(30, 'minutes').toISOString()
    },
    sessionClear: state => {
      _.merge(state, cleanState)
      sessionStorage.removeItem('sessionState')
    },
    setTeamWorkspace: (state, action: PayloadAction<{ workspaceId: number; workspaceName: string }>) => {
      state.team.workspaceId = action.payload.workspaceId
      state.team.workspaceName = action.payload.workspaceName
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    setTeamGroup: (state, action: PayloadAction<{ groupId: number; groupName: string; groupColor: ColorType }>) => {
      state.team.groupId = action.payload.groupId
      state.team.groupName = action.payload.groupName
      state.team.groupColor = action.payload.groupColor
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
    setTeamWorker: (state, action: PayloadAction<{ workerId: number; workerName: string }>) => {
      state.team.workerId = action.payload.workerId
      state.team.workerName = action.payload.workerName
      sessionStorage.setItem('sessionState', JSON.stringify(state))
    },
  },
})

export const {
  requestLogin,
  clearErrorMessage,
  startRequest,
  apiFailure,
  loginSuccess,
  activateSuccess,
  updateAccountInformationSuccsess,
  refreshSuccess,
  sessionClear,
  setTeamWorkspace,
  setTeamGroup,
  setTeamWorker,
} = sessionSlice.actions

export const login =
  ({ email, password }: API.LoginParams): AppThunk =>
  dispatch => {
    dispatch(requestLogin())
    dispatch(Spinner.start())
    API.login({ email, password })
      .then((res: API.LoginResponse) => {
        if (res.user) {
          dispatch(loginSuccess(res))
          return
        }

        // signup のときには res.user === null となってしまうので
        // API.LoginResponse に型を合わせつつ activate で使う email を保存しておく
        dispatch(
          loginSuccess({
            ...res,
            user: { ...cleanState.user, email },
          })
        )
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const logout = (): AppThunk => (dispatch, getState) => {
  const { accessToken, idToken } = getState().session
  dispatch(Spinner.start())
  API.logout({ accessToken, idToken }).finally(() => {
    dispatch(sessionClear())
    dispatch(Spinner.stop())
  })
}

export const activate =
  ({ password, name }: { password: string; name: string }): AppThunk =>
  (dispatch, getState) => {
    const {
      session,
      user: { email },
    } = getState().session
    if (!session) {
      dispatch(apiFailure({ errorMessage: 'null session' }))
      return
    }

    dispatch(startRequest())
    dispatch(Spinner.start())
    API.activate({ email, password, session, name })
      .then((res: API.ActivateResponse) => dispatch(activateSuccess(res)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateAccountInformation =
  (data: UserAPI.UpdateUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    UserAPI.updateUser(commonParams(getState), data)
      .then((res: UserAPI.UserResponse) => dispatch(updateAccountInformationSuccsess(res)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateAccountPassword =
  (data: UserAPI.UpdateUserPasswordProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    UserAPI.updateUserPassword(commonParams(getState), data)
      .then((res: UserAPI.UserResponse) => dispatch(updateAccountInformationSuccsess(res)))
      .catch((res: AxiosError<{ message: string }>) => {
        if (res.response?.data?.message === INCORRECT_OLD_PASSWORD_MESSAGE) {
          dispatch(apiFailure({ errorMessage: INCORRECT_OLD_PASSWORD_MESSAGE }))
        } else {
          dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
        }
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteAccount = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  const params = commonParams(getState)
  UserAPI.deleteUser(params, params.userId)
    .then(() => dispatch(logout()))
    .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
    .finally(() => dispatch(Spinner.stop()))
}

// セッションの期限の管理
export const validateToken = (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
  const { session } = getState()
  if (moment(session.expirationDate).isAfter(moment())) {
    return true
  }
  if (session.user.role === UserAPI.Role.TenantAdmin) {
    dispatch(SessionTimeoutDialog.open())
    return false
  }

  // トークンのリフレッシュでは Spinner もエラーダイアログも出さない
  await API.refresh({ refreshToken: session.refreshToken! }).then((res: API.RefreshResponse) =>
    dispatch(refreshSuccess(res))
  )
  return true
}

export const selectSessionStatus = (state: RootState) => ({ ...state.session })

export default sessionSlice.reducer
