import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectGroupsStatus } from 'slices/groupsSlice'
import { selectSkillsStatus } from 'slices/skillsSlice'

import { FilteringButton, FilteringInputField } from 'components/common'
import type { FilterItem } from 'components/common/types'

import styles from './AssignmentWorkerFilter.module.scss'

import type { ScheduleEditType } from './DroppingWorkerCards'

type Props = {
  initSchedules: ScheduleEditType[]
  onChange: React.Dispatch<React.SetStateAction<ScheduleEditType[]>>
  setShouldReset: React.Dispatch<React.SetStateAction<boolean>>
  shouldReset: boolean
}

const BLANK_ID = -1

const AssignmentWorkerFilter: React.FC<Props> = ({ initSchedules, onChange, setShouldReset, shouldReset }) => {
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const [filterWord, setFilterWord] = React.useState('')
  const [selectedFilterGroups, setSelectedFilterGroups] = React.useState<number[]>([])
  const [selectedFilterSkills, setSelectedFilterSkills] = React.useState<number[]>([])

  React.useEffect(() => groups && setSelectedFilterGroups(groups.map(g => g.groupId).concat(BLANK_ID)), [groups])

  React.useEffect(() => skills && setSelectedFilterSkills(skills.map(s => s.skillId).concat(BLANK_ID)), [skills])

  React.useEffect(() => {
    if (!shouldReset) {
      return
    }
    groups && setSelectedFilterGroups(groups.map(g => g.groupId).concat(BLANK_ID))
    skills && setSelectedFilterSkills(skills.map(s => s.skillId).concat(BLANK_ID))
    setFilterWord('')
    setShouldReset(false)
  }, [groups, skills, shouldReset, setShouldReset])
  const filterGroupItems = React.useMemo<FilterItem[]>(
    () =>
      groups
        .map(g => ({ key: g.groupId, label: g.name, checked: selectedFilterGroups.includes(g.groupId) }))
        .concat({ key: BLANK_ID, label: '未所属', checked: selectedFilterGroups.includes(BLANK_ID) }),
    [groups, selectedFilterGroups]
  )

  const filterSkillItems = React.useMemo<FilterItem[]>(
    () =>
      skills
        .map(s => ({ key: s.skillId, label: s.name, checked: selectedFilterSkills.includes(s.skillId) }))
        .concat({ key: BLANK_ID, label: '未設定', checked: selectedFilterSkills.includes(BLANK_ID) }),
    [skills, selectedFilterSkills]
  )

  const selectedGroupNames = React.useMemo(() => {
    const names = groups.filter(g => selectedFilterGroups.includes(g.groupId)).map(g => g.name)
    selectedFilterGroups.includes(BLANK_ID) && names.push('未所属')
    return names
  }, [selectedFilterGroups, groups])

  React.useEffect(
    () =>
      onChange(prev => {
        const editWorkers = prev.flatMap(s => s.workers.map(w => ({ ...w, type: s.type })))
        return initSchedules.map(s => {
          const workers = s.workers
            .filter(w => !editWorkers.some(e => e.id === w.id))
            .concat(editWorkers.filter(w => w.type === s.type))
            .map(w => {
              const hasGroups = selectedGroupNames.includes(w.groupName || '未所属')
              const hasBlankSkill = selectedFilterSkills.includes(BLANK_ID) && _.isEmpty(w.skillIds)
              const hasSelectedSkill = hasBlankSkill || !_.isEmpty(_.intersection(selectedFilterSkills, w.skillIds))
              const hasFilterWord =
                filterWord === '' || w.name.includes(filterWord) || w.wmsMemberId.includes(filterWord)

              return { ..._.omit(w, 'type'), visible: hasGroups && hasSelectedSkill && hasFilterWord }
            })
          return { ...s, workers }
        })
      }),
    [selectedGroupNames, selectedFilterSkills, filterWord, onChange, initSchedules]
  )

  return (
    <div className={`d-flex mb-3 ${styles.filterButtonGroup}`}>
      <div className="w-25">
        <FilteringInputField onChange={setFilterWord} value={filterWord} />
      </div>
      <FilteringButton
        items={filterGroupItems}
        onChange={setSelectedFilterGroups}
        value={selectedFilterGroups}
        label="グループで絞り込み"
        disabled={_.isEmpty(groups)}
      />
      <FilteringButton
        items={filterSkillItems}
        onChange={setSelectedFilterSkills}
        value={selectedFilterSkills}
        label="スキルで絞り込み"
        disabled={_.isEmpty(skills)}
      />
    </div>
  )
}
export default AssignmentWorkerFilter
