import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardTitle, FormGroup } from 'reactstrap'

import type { UpdateWorkSchedule } from 'api/works'
import type { WorkspaceResponse } from 'api/workspaces'

import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { showSuccess } from 'slices/notificationSlice'
import { getWorkerList, selectWorkersStatus, updateWorkerShifts } from 'slices/workersSlice'
import { getWorkByDate, selectWorksStatus } from 'slices/worksSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import type { EditGroupsWorkerType, EditShiftsType } from 'components/Schedules/types'
import { List, DatePicker, CardSubmitFooter, CustomButton, NavMenu } from 'components/common'
import { getOriginalSchedulesFromWork, hasOverlappedSchedule, SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useQuery from 'hooks/useQuery'

import placeholder from 'images/allEmpty.svg'

import ShiftImport from './ShiftImport'
import WorkerSchedule from './WorkerSchedule'

import styles from './Shifts.module.scss'

const Shifts: React.FC = () => {
  const [shiftImportOpen, setShiftImportOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [editGroups, setEditGroups] = React.useState<EditShiftsType[]>([])
  const [initGroups, setInitGroups] = React.useState<EditShiftsType[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { workers } = useSelector(selectWorkersStatus, shallowEqual)
  const { works } = useSelector(selectWorksStatus, shallowEqual)
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)

  const query = useQuery()
  const { workspaceId, date } = React.useMemo(() => {
    const id = Number(query.get('id')) || workspaces[0]?.workspaceId || 0
    const dateParams = query.get('date') || moment().format('YYYY-MM-DD')
    return { workspaceId: id, date: dateParams }
  }, [workspaces, query])

  const work = React.useMemo(() => works.find(w => moment(date).isSame(w.date, 'day')), [date, works])

  React.useEffect(() => {
    dispatch(getWorkerList(false))
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getGroupList(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    if (!workspaceId || !date) {
      return
    }
    dispatch(getWorkByDate(workspaceId, date, true))
    navigate(`/shifts?id=${workspaceId}&date=${date}`)
  }, [workspaceId, date, dispatch, navigate])

  const handleWorkspaceIdListChange = (selectedId: string | number) => {
    navigate(`/shifts?id=${selectedId}&date=${date}`, { replace: true })
  }

  const createEditGroupWorkers = React.useCallback(
    (groupId: number | null) => {
      const target = work?.groups.find(g => g.groupId === groupId)?.workers || []
      return workers
        .filter(worker => worker.workspaceId === workspaceId && worker.groupId === groupId)
        .map<EditGroupsWorkerType>(worker => {
          const shiftSchedules =
            target
              .find(w => w.workerId === worker.workerId)
              ?.schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
              .map(schedule => ({
                ...schedule,
                editable: true,
              })) || []
          return {
            ...worker,
            schedules: shiftSchedules,
          }
        })
    },
    [workers, workspaceId, work]
  )

  React.useEffect(() => {
    if (!groups) {
      return
    }
    setEditGroups(prev => {
      const selectedGroups: EditShiftsType[] = groups
        .map<{ groupId: number | null; name: string }>(({ groupId, name }) => ({ groupId, name }))
        .concat([{ groupId: null, name: '未所属' }])
        .map(g => ({
          ...g,
          schedules: [],
          supportedWorkspaceId: null,
          supportedWorkspaceName: null,
          workers: createEditGroupWorkers(g.groupId),
          isOpen: prev.find(editGroup => editGroup.groupId === g.groupId)?.isOpen || true,
        }))
        .filter(g => !_.isEmpty(g.workers))
      setInitGroups(selectedGroups)
      return selectedGroups
    })
  }, [groups, createEditGroupWorkers])

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      dispatch(getWorkByDate(workspaceId, date, true))
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId, date])

  const listItems = React.useMemo(
    () =>
      workspaces.map((workspace: WorkspaceResponse) => {
        return {
          id: workspace.workspaceId,
          title: workspace.name,
        }
      }),
    [workspaces]
  )

  const onSubmit = () => {
    if (!workspaceId) {
      return
    }

    setSubmitted(true)
    const schedules = editGroups.flatMap(cur => {
      const initGroup = initGroups.find(ig => ig.name === cur.name && !_.isEqual(ig, cur))

      if (!initGroup) {
        return []
      }

      // worker schedules の追加or更新
      const updateWorker = cur.workers.flatMap(w =>
        w.schedules.reduce((sacc: UpdateWorkSchedule[], scur) => {
          const isUnChanged = initGroup.workers.some(
            init => init.workerId === w.workerId && init.schedules.some(s => _.isEqual(s, scur))
          )

          if (!isUnChanged) {
            sacc.push({
              scheduleId: !scur.scheduleId || scur.scheduleId < 1 ? null : scur.scheduleId,
              schedule: {
                scheduleTypeId: scur.scheduleTypeId,
                supportWorkspaceId: scur.supportWorkspaceId,
                startAt: scur.startAt,
                duration: scur.duration,
                workerId: w.workerId,
                groupId: null,
              },
            })
          }
          return sacc
        }, [])
      )

      // worker schedules の削除データ
      const curWorkerScheduleIds = cur.workers.flatMap(w => w.schedules.map(s => s.scheduleId))
      const deleteWorkerSchedule: UpdateWorkSchedule[] = initGroup.workers
        .flatMap(init =>
          init.schedules.filter(s => !curWorkerScheduleIds.includes(s.scheduleId)).map(s => s.scheduleId)
        )
        .map(scheduleId => ({ scheduleId, schedule: null }))

      return updateWorker.concat(deleteWorkerSchedule)
    })

    const originalSchedules = getOriginalSchedulesFromWork(schedules, work).filter(
      s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID
    )
    dispatch(updateWorkerShifts({ schedules, originalSchedules }, workspaceId, date))
  }

  const onCancel = () => {
    setEditGroups(initGroups)
  }

  const handleShiftImport = () => {
    setShiftImportOpen(false)
    dispatch(
      showSuccess({
        successMessage: 'シフト情報のアップロードに成功しました。反映までしばらく時間がかかる場合があります。',
      })
    )
  }

  const handleDatePickerChange = (input: string | undefined) => {
    const formatDate = moment(input).local().format('YYYY-MM-DD')
    if (!input || formatDate === date) {
      return
    }
    navigate(`/shifts?id=${workspaceId}&date=${formatDate}`, { replace: true })
  }

  const unchanged = React.useMemo(
    () =>
      _.isEqual(
        initGroups.flatMap(g => g.workers),
        editGroups.flatMap(g => g.workers)
      ),
    [initGroups, editGroups]
  )

  const disabled = React.useMemo(
    () => editGroups.some(editGroup => editGroup.workers.some(worker => hasOverlappedSchedule(worker.schedules, true))),
    [editGroups]
  )
  const handleWorkPlanButtonClick = () => navigate(`/schedules/${workspaceId}/${work?.workId}`)

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className={`d-flex justify-content-between ${styles.topContents}`}>
          <div className="font-x-large fw-bold align-self-center">シフト管理</div>
          <CustomButton icon="plus" outline onClick={() => setShiftImportOpen(true)}>
            シフトインポート
          </CustomButton>
        </div>
        <Row className={`${styles.row} py-3`}>
          <Col md={4} className="h-100">
            <Card className={`h-100 ${styles.list}`}>
              {listItems.length > 0 ? (
                <List
                  items={listItems}
                  selectedId={workspaceId}
                  onAction={((id: number) => handleWorkspaceIdListChange(id)) as (selected: string | number) => void}
                />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto ${styles.placeholderImage}`} src={placeholder} alt="" width="80%" />
                    <div className="font-middle fw-bold py-4">メンバーがいません</div>
                    <div>メンバーを追加して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {!workspaceId ? (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">メンバーが選択されていません</div>
                    <div>メンバーを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              ) : (
                <div className="h-100">
                  <div className={styles.shiftDetail}>
                    <CardBody className="h-100">
                      <div className={styles.top}>
                        <CardTitle className="font-large fw-bold">シフト情報</CardTitle>
                        <FormGroup row className="pe-3">
                          <Col md={6} className="d-flex align-items-center">
                            <div className="text-nowrap me-3">日付を選択</div>
                            <DatePicker id="dateSelect" value={date} onChange={handleDatePickerChange} />
                          </Col>
                          <Col className="p-0">
                            <CustomButton
                              icon="schedule"
                              iconPosition="left"
                              outline
                              onClick={handleWorkPlanButtonClick}
                              className="w-100 d-flex justify-content-end p-0"
                              disabled={!work}
                              name="shifts-move-work-plan"
                            >
                              作業計画
                            </CustomButton>
                          </Col>
                        </FormGroup>
                      </div>
                      <div className={`${styles.workerSchedule} my-3`}>
                        <WorkerSchedule
                          date={date}
                          editGroups={editGroups}
                          onEditGroupsChange={setEditGroups}
                        ></WorkerSchedule>
                      </div>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    cancelDisabled={unchanged}
                    submitDisabled={unchanged || disabled}
                    updatedAt={work?.updatedAt}
                    updatedBy={work?.updatedBy}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>

        <ShiftImport
          isOpen={shiftImportOpen}
          onSuccess={handleShiftImport}
          onCancel={() => setShiftImportOpen(false)}
        />
      </div>
    </NavMenu>
  )
}

export default Shifts
