import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Card, CardText } from 'reactstrap'

import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { selectSessionStatus, setTeamGroup } from 'slices/sessionSlice'

import type { ColorType } from 'components/common/types'

import useLogout from 'hooks/useLogout'

import styles from './styles.module.scss'

const TeamSelectGroups: React.FC = () => {
  const dispatch = useDispatch()
  const { logout } = useLogout()
  const {
    team: { workspaceId },
  } = useSelector(selectSessionStatus, shallowEqual)
  React.useEffect(() => {
    dispatch(getGroupList(workspaceId, false))
  }, [dispatch, workspaceId])

  const groupsStatus = useSelector(selectGroupsStatus, shallowEqual)
  const groups = _.sortBy(groupsStatus.groups, 'name')

  const navigate = useNavigate()
  const handleClick = (groupId: number, groupName: string, groupColor: ColorType) => {
    dispatch(setTeamGroup({ groupId, groupName, groupColor }))
    navigate(`/team-select-workers`)
  }

  return (
    <>
      <Container fluid className={styles.header}>
        <Row>
          <Col>
            <i className="icf-chevron_left font-x-large" onClick={() => navigate('/team-select-workspaces')} />
          </Col>
          <Col className="font-x-large fw-bold text-center">グループ選択</Col>
          <Col className="text-end" onClick={() => logout()}>
            ログアウト
          </Col>
        </Row>
      </Container>
      <div className={`position-sticky ${styles.list}`}>
        {groups.map(group => (
          <Card
            className={`${styles.singleTextCard} p-3 mb-2`}
            key={group.groupId}
            onClick={() => handleClick(group.groupId, group.name, group.color)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="overflow-hidden">
                <CardText className="m-0 text-truncate">{group.name}</CardText>
              </div>
              <i className="icf-chevron_right font-large" />
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}

export default TeamSelectGroups
