import axios from 'axios'

import type { SkillResponse } from 'api/skills'

import type { CommonParams } from 'slices/utils'

import type { ColorType, ConnectionType } from 'components/common/types'
import { getUUID } from 'components/common/utils'

export type ScheduleTypeFilterRow = {
  id: string
  key: string
  value: string
}

export type ScheduleTypeFilter = {
  id: string
  data: ScheduleTypeFilterRow[]
}

export type ScheduleTypeResponse = {
  scheduleTypeId: number
  name: string
  color: ColorType
  requiredSkills: SkillResponse[]
  connectionType: ConnectionType
  dataConnection: boolean
  targetDatabase: string | null
  targetColumn: string | null
  unit: string | null
  filter: ScheduleTypeFilter[]
  defaultPerformanceIndex: number | null
  isKey: boolean
  isCostless: boolean
  createdAt: string
  updatedBy: string
  updatedAt: string
}

type OriginalScheduleTypeResponse = {
  scheduleTypeId: number
  name: string
  color: ColorType
  requiredSkills: SkillResponse[]
  connectionType: ConnectionType
  dataConnection: boolean
  targetDatabase: string | null
  targetColumn: string | null
  unit: string | null
  filter: ScheduleTypeFilterRow[][]
  defaultPerformanceIndex: number | null
  isKey: boolean
  isCostless: boolean
  createdAt: string
  updatedBy: string
  updatedAt: string
}

export type ScheduleTypeListResponse = {
  scheduleTypes: ScheduleTypeResponse[]
}

export type ScheduleTypeEditDataType = {
  name: string
  color: ColorType
  requiredSkillIds: number[]
  dataConnection: boolean
  connectionType: ConnectionType
  targetDatabase: string | null
  targetColumn: string | null
  unit: string | null
  filter: ScheduleTypeFilter[]
  defaultPerformanceIndex: number | null
  isKey: boolean
  isCostless: boolean
}

export const getScheduleTypeList = (params: CommonParams, workspaceId: number): Promise<ScheduleTypeListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/schedule_types`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }

    axios
      .get(url, { headers })
      .then(response => resolve(parseListResponse(response.data)))
      .catch(error => reject(error))
  })
}

export const createScheduleType = (
  params: CommonParams,
  workspaceId: number,
  data: ScheduleTypeEditDataType
): Promise<ScheduleTypeResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/schedule_types`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, convertToEditRequest(data, params.updatedBy), { headers })
      .then(response => resolve(parseResponse(response.data)))
      .catch(error => reject(error))
  })
}

export const updateScheduleType = (
  params: CommonParams,
  workspaceId: number,
  scheduleTypeId: number,
  data: ScheduleTypeEditDataType
): Promise<ScheduleTypeResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/schedule_types/${scheduleTypeId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, convertToEditRequest(data, params.updatedBy), { headers })
      .then(response => resolve(parseResponse(response.data)))
      .catch(error => reject(error))
  })
}

export const deleteScheduleType = (params: CommonParams, workspaceId: number, scheduleTypeId: number): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/schedule_types/${scheduleTypeId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers, params: { updatedBy: params.updatedBy } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

const parseListResponse = (listResponse: {
  scheduleTypes: OriginalScheduleTypeResponse[]
}): ScheduleTypeListResponse => {
  const scheduleTypes = listResponse.scheduleTypes.map(response => parseResponse(response))
  return { scheduleTypes }
}

/**
 * ScheduleTypeResponseのfilterにidを加えて画面側で扱い形式に変換する。
 */
const parseResponse = (response: OriginalScheduleTypeResponse): ScheduleTypeResponse => {
  const filter: ScheduleTypeFilter[] = response.filter.map(parent => {
    const data: ScheduleTypeFilterRow[] = parent.map(child => {
      return {
        id: getUUID(),
        key: child.key,
        value: child.value,
      }
    })
    return {
      id: getUUID(),
      data,
    }
  })
  return { ...response, filter }
}

/**
 * 画面側で加えたidを除外してAPI用の値に変換する。updatedByも付加する。
 */
const convertToEditRequest = (src: ScheduleTypeEditDataType, updatedBy: string) => {
  const filter = src.filter.map(parent => {
    return parent.data.map(row => ({ key: row.key, value: row.value }))
  })
  return { ...src, filter: filter.length > 0 ? filter : [[]], updatedBy }
}
