import * as React from 'react'
import { CardBody, Button } from 'reactstrap'

import { SelectBox } from 'components/common'

import styles from './FilterCard.module.scss'

export type Filters = {
  key: string
  label: string
  values: string[]
}

type Props = {
  index: number
  last: boolean
  filters: Filters[]
  filterKey: string
  filterValue: string
  disabled?: boolean
  onFilterKeyChange: (key: string) => void
  onFilterValueChange: (value: string) => void
  onAddClick: () => void
  onDeleteClick: () => void
}

const FilterCard: React.FC<Props> = props => {
  const {
    index,
    last,
    filters,
    filterKey,
    filterValue,
    disabled,
    onFilterKeyChange,
    onFilterValueChange,
    onAddClick,
    onDeleteClick,
  } = props
  const filterKeyOptions = React.useMemo(
    () => filters.map(filter => ({ key: filter.key, value: filter.label })),
    [filters]
  )
  const filterValueOptions = React.useMemo(
    () => filters.find((filter: Filters) => filter.key === filterKey)?.values.map(v => ({ value: v })) || [],
    [filters, filterKey]
  )
  const filterKeyValue = React.useMemo(
    () => filters.find(filter => filter.key === filterKey)?.label,
    [filterKey, filters]
  )

  return (
    <CardBody className={`d-flex align-items-center ${styles.filterCard}`}>
      <SelectBox
        id={`filter-key-${filterKey}-${index}`}
        items={filterKeyOptions}
        value={filterKeyValue}
        disabled={disabled}
        onSelect={item => onFilterKeyChange(item?.key?.toString() || item.value)}
        className="w-25"
      ></SelectBox>
      <span className="px-2">が</span>
      <SelectBox
        id={`filter-object-${filterKey}-${index}`}
        items={filterValueOptions}
        value={filterValue}
        disabled={disabled}
        onSelect={item => onFilterValueChange(item.value)}
        className="w-25"
      ></SelectBox>
      <span className="px-2 flex-grow-1">と一致する。</span>
      {last && (
        <Button outline size="sm" disabled={disabled} className="d-flex align-items-center" onClick={onAddClick}>
          <i className="icf-plus font-large" />
          <div className="px-3">OR</div>
        </Button>
      )}
      <Button
        outline
        color="danger"
        size="sm"
        disabled={disabled}
        className="d-flex align-items-center ms-1"
        onClick={onDeleteClick}
      >
        削除
      </Button>
    </CardBody>
  )
}

export default FilterCard
