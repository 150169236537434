import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'

import type { UpdateWorkSchedule } from 'api/works'

import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceSummary, selectTenantsStatus, getTenant } from 'slices/tenantsSlice'
import { getWorker, updateWorkerShifts, selectWorkersStatus } from 'slices/workersSlice'

import { summarizeWorkspaceSummaryGroup } from 'components/Dashboard/utils'
import type { InputScheduleType } from 'components/TeamSchedules/TeamWorkPlan/ShiftInputScale'
import ShiftInputScale from 'components/TeamSchedules/TeamWorkPlan/ShiftInputScale'
import { BadgeLabel, DatePicker, TimeScale, ShiftBar, ShiftPopover, MultipleFooter } from 'components/common'
import TableScale from 'components/common/AssignedNumberTable/TableScale'
import type { ShiftBarItemType, EditSchedule } from 'components/common/types'
import {
  getShiftBarWidthByDuration,
  getEditSchedules,
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
  getRandomNumber,
} from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import TeamWorkerCard from './TeamWorkerCard'
import { getWorkerPerformance } from './utils'

import styles from './TeamWorkerDetail.module.scss'

const TeamWorkerDetail: React.FC = () => {
  const params = useParams<'workerId'>()
  const workerId = Number(params.workerId)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [scheduleDate, setScheduleDate] = React.useState(moment().format('YYYY-MM-DD'))
  const [inputMode, setInputMode] = React.useState(false)
  const [inputSchedule, setInputSchedule] = React.useState<InputScheduleType | undefined>()
  const [workerSchedule, setWorkerSchedule] = React.useState<EditSchedule[]>([])
  const [initWorkerSchedule, setInitWorkerSchedule] = React.useState<EditSchedule[]>([])
  const [submitted, setSubmitted] = React.useState(false)

  const {
    team: { workspaceId, groupId, groupName, groupColor },
  } = useSelector(selectSessionStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getWorkspaceSummary(workspaceId, moment().format('YYYY-MM-DD')))
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])
  const { workspaceSummary } = useSelector(selectTenantsStatus, shallowEqual)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getWorker(workerId, true, scheduleDate, scheduleDate, true))
  }, [dispatch, workerId, scheduleDate])

  const { workers, isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      navigate(-1)
    }
    setSubmitted(false)
  }, [dispatch, errorMessage, navigate, isRequesting, submitted])

  const worker = React.useMemo(() => workers.find(w => w.workerId === workerId), [workers, workerId])
  const badgeItems = React.useMemo(() => {
    if (!worker) {
      return []
    }
    const now = moment()
    return worker.schedules
      .filter(schedule => schedule.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
      .filter(schedule => {
        const from = moment(schedule.startAt)
        const to = moment(schedule.startAt).add(schedule.duration, 'seconds')
        return now.isBetween(from, to, 'minute', '[]')
      })
      .map(schedule => {
        if (schedule.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID) {
          return {
            key: schedule.supportWorkspaceId ?? SUPPORT_SCHEDULE_TYPE_ID,
            label: schedule.supportWorkspaceName ?? '',
          }
        }
        const scheduleType = scheduleTypes.find(s => s.scheduleTypeId === schedule.scheduleTypeId)
        return {
          key: scheduleType?.scheduleTypeId || getRandomNumber(),
          label: scheduleType?.name || '',
          color: scheduleType?.color || undefined,
        }
      })
  }, [worker, scheduleTypes])

  const { user } = useSelector(selectSessionStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])

  const {
    businessHourBlocks,
    businessStartTime,
    businessEndTime,
    businessDuration,
    getTimesByShiftBarX,
    getShiftBarXbyStartTime,
  } = useBusinessTime()

  React.useEffect(() => {
    const shifts = getEditSchedules(
      worker?.schedules.filter(
        schedule =>
          schedule.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID &&
          moment(schedule.startAt).local().format('YYYY-MM-DD') === scheduleDate
      ) || []
    )

    setWorkerSchedule(_.sortBy(shifts, ['startAt']))
    setInitWorkerSchedule(_.sortBy(shifts, ['startAt']))
  }, [scheduleDate, worker?.schedules])

  const onDelete = React.useCallback(
    (id: string) => {
      setWorkerSchedule(workerSchedule.filter(s => s.scheduleId?.toString() !== id))
    },
    [setWorkerSchedule, workerSchedule]
  )

  const shiftBarItems = React.useMemo(
    (): ShiftBarItemType[] =>
      workerSchedule.map((schedule, index) => {
        const startAt = moment(schedule.startAt).local()
        const endAt = moment(startAt).add(schedule.duration, 'second').local()
        const x = getShiftBarXbyStartTime(schedule.startAt)
        const width = getShiftBarWidthByDuration(schedule.duration)
        const id = schedule.scheduleId || getRandomNumber()

        return {
          id: id.toString(),
          content: (
            <ShiftPopover
              label={`勤務時間${index + 1}`}
              time={`${startAt.format('H:mm')}〜${endAt.format('H:mm')}`}
              deleteKey={id.toString()}
              onDelete={onDelete}
            />
          ),
          x,
          width,
        }
      }),
    [workerSchedule, onDelete, getShiftBarXbyStartTime]
  )

  const workerPerformance = React.useMemo(() => {
    if (!workspaceSummary) {
      return undefined
    }
    const groupPerformances = summarizeWorkspaceSummaryGroup(workspaceSummary.workspaceData.groups)
    return groupPerformances.flatMap(g => g.workerDataList).find(w => w.workerId === workerId)
  }, [workspaceSummary, workerId])

  const unchanged = React.useMemo(
    () => _.isEqual(initWorkerSchedule, workerSchedule),
    [initWorkerSchedule, workerSchedule]
  )

  const handleDatePickerChange = (date: string | undefined) => {
    if (!date || moment(date).local().format('YYYY-MM-DD') === scheduleDate) {
      return
    }
    cancelInputMode()
    setScheduleDate(moment(date).local().format('YYYY-MM-DD'))
  }

  const shiftInputScaleClick = (x: number) => {
    if (!inputSchedule || inputSchedule.workerId !== workerId || inputSchedule.startX !== inputSchedule.endX) {
      setInputSchedule({ workerId, startX: x, endX: x })
    } else {
      setInputSchedule({ ...inputSchedule, endX: x })
    }
  }

  const cancelInputMode = () => {
    setInputMode(false)
    setInputSchedule(undefined)
  }

  const onDecisionButtonClick = () => {
    if (!inputSchedule) {
      return
    }

    const startTime = getTimesByShiftBarX(inputSchedule.startX)
    let addSchedule: EditSchedule = {
      scheduleId: null,
      scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID,
      startAt: moment(`${scheduleDate} ${startTime.hours}:${startTime.minutes}`, 'YYYY-MM-DD HH:mm').utc().format(),
      duration: (inputSchedule.endX - inputSchedule.startX + 1) * 900,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
    }

    const newWorkerSchedules = workerSchedule.reduce((acc: EditSchedule[], cur) => {
      const end = moment(addSchedule.startAt).add(addSchedule.duration, 'seconds').format()
      const curEnd = moment(cur.startAt).add(cur.duration, 'seconds').format()
      if (
        moment(addSchedule.startAt).isBetween(cur.startAt, curEnd, 'minutes', '[]') &&
        moment(end).isBetween(cur.startAt, curEnd, 'minutes', '[]')
      ) {
        addSchedule = { ...cur, startAt: addSchedule.startAt, duration: addSchedule.duration }
      } else if (
        moment(cur.startAt).isBetween(addSchedule.startAt, end, 'minutes', '()') &&
        moment(curEnd).isBetween(addSchedule.startAt, end, 'minutes', '()')
      ) {
        return acc
      } else if (moment(addSchedule.startAt).isBetween(cur.startAt, curEnd, 'minutes', '[]')) {
        const diff = Math.abs(moment(curEnd).unix() - moment(addSchedule.startAt).unix())
        addSchedule = { ...cur, duration: cur.duration + addSchedule.duration - diff }
      } else if (moment(end).isBetween(cur.startAt, curEnd, 'minutes', '[]')) {
        const diff = Math.abs(moment(end).unix() - moment(cur.startAt).unix())
        addSchedule = { ...cur, startAt: addSchedule.startAt, duration: cur.duration + addSchedule.duration - diff }
      } else {
        acc.push(cur)
      }
      return acc
    }, [])

    setWorkerSchedule(newWorkerSchedules.concat(_.compact([addSchedule])))
    cancelInputMode()
  }

  const onSubmit = () => {
    if (!worker) {
      return
    }
    setSubmitted(true)

    // worker schedules の追加or更新
    const updateWorkerSchedules = workerSchedule.reduce((sacc: UpdateWorkSchedule[], scur) => {
      const unchangedWorkerSchedule = initWorkerSchedule.some(s => _.isEqual(s, scur))
      if (!unchangedWorkerSchedule) {
        sacc.push({
          scheduleId: !scur.scheduleId || scur.scheduleId < 1 ? null : scur.scheduleId,
          schedule: {
            scheduleTypeId: scur.scheduleTypeId,
            supportWorkspaceId: scur.supportWorkspaceId,
            startAt: scur.startAt,
            duration: scur.duration,
            workerId,
            groupId: null,
          },
        })
      }
      return sacc
    }, [])

    // worker schedules の削除
    const workerScheduleIds = workerSchedule.map(w => w.scheduleId)
    const deleteWorkerSchedules: UpdateWorkSchedule[] = initWorkerSchedule
      .filter(s => !workerScheduleIds.includes(s.scheduleId))
      .map(({ scheduleId }) => ({ scheduleId, schedule: null }))

    const schedules = updateWorkerSchedules.concat(deleteWorkerSchedules)
    const originalSchedules = worker.schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
    dispatch(updateWorkerShifts({ schedules, originalSchedules }, workspaceId, scheduleDate))
  }

  const counts = React.useMemo(
    () =>
      businessHourBlocks.reduce((acc, cur) => {
        const workerData = workerPerformance?.workerData || {}
        const rate = workerData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks, workerPerformance]
  )
  return (
    <div className={`${styles.container} d-flex flex-column`}>
      <div className={`d-flex align-items-center font-x-large ${styles.header}`}>
        <div className="fw-bold text-center flex-grow-1">メンバー詳細</div>
        <i className="icf-close px-3" onClick={() => navigate(-1)} />
      </div>

      <div className={`${styles.main} flex-grow-1`}>
        <TeamWorkerCard
          workerId={workerId}
          workerName={worker?.name || ''}
          groupName={groupName}
          groupColor={groupColor}
          performance={getWorkerPerformance(workspaceSummary, groupId, workerId)}
          disabled={true}
        />

        <div className="d-flex mt-4">
          <div className="me-3">現在の作業</div>
          {badgeItems.map(badgeItem => (
            <BadgeLabel key={badgeItem.key} label={badgeItem.label} color={badgeItem.color} />
          ))}
        </div>

        <div className={`${styles.tableWrapper} mt-3`}>
          <TimeScale round />
          <table className={styles.table}>
            <tbody>
              <tr>
                <TableScale items={counts} />
              </tr>
            </tbody>
          </table>
        </div>

        <div className="font-large fw-bold">シフト情報</div>
        <Row className="py-3 pe-3">
          <Col md={6} className="d-flex align-items-center">
            <div className="me-5 text-nowrap">日付を選択</div>
            <DatePicker
              id="dateSelect"
              value={scheduleDate}
              minDate={moment().toDate()}
              onChange={handleDatePickerChange}
            />
          </Col>
          <Col className="text-end p-0">
            <Button className="px-4" outline disabled={inputMode} onClick={() => setInputMode(true)}>
              シフト編集
            </Button>
          </Col>
        </Row>

        <div className={styles.tableWrapper}>
          <TimeScale />
          <div className={styles.tableContent}>
            <ShiftBar
              items={shiftBarItems}
              businessStartTime={businessStartTime}
              shiftBarWidth={businessDuration}
              disabled={inputMode}
              isTeam={true}
            />
            <ShiftInputScale
              show={inputMode}
              businessStartTime={businessStartTime}
              businessEndTime={businessEndTime}
              shiftBarWidth={businessDuration}
              inputSchedule={inputSchedule}
              selectedScheduleType={{ scheduleTypeId: 1, name: '勤務時間', color: 'secondary' }}
              onClick={shiftInputScaleClick}
            />
          </div>
        </div>
      </div>

      {inputMode ? (
        <MultipleFooter
          stepText="時間帯を選択"
          disabled={!inputSchedule}
          selectorOpen={false}
          decisionButtonClick={onDecisionButtonClick}
          onCancel={cancelInputMode}
        />
      ) : (
        <Container fluid className={styles.footer}>
          <Row>
            <Col>
              <Button outline onClick={() => navigate(-1)}>
                キャンセル
              </Button>
            </Col>
            <Col className="text-end">
              <Button color="primary" disabled={unchanged} className="px-4" onClick={onSubmit}>
                保存
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default TeamWorkerDetail
