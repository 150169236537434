import { InputGroupFormatWithCostsBadge, InputGroupFormatWithSalesBadge } from 'components/common'

export type CostArrayToString = {
  name: string
  cost: string
}

export type UnitCostsArrayToString = {
  scheduleTypeId: number
  scheduleTypeName: string
  unitCost: string
  materialCosts: CostArrayToString[]
}

export type ValidationType = {
  nameValidations?: ValidationFunction[]
  costValidations?: ValidationFunction[]
}

export const FieldNames = {
  name: 'name',
  cost: 'cost',
  unitCost: 'unitCost',
  materialCosts: 'materialCosts',
} as const

type ValidationFunction = (value?: string) => string

export const BadgeLabelTypes = {
  sales: InputGroupFormatWithSalesBadge,
  costs: InputGroupFormatWithCostsBadge,
} as const

export type ValidationListType = {
  first: boolean
  second: boolean
}
