import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Button, Col, Card, CardBody, CardTitle } from 'reactstrap'

import { ENABLE_DIALOG_ERROR_STATUS_CODES, CONFLICT_ERROR_STATUS_CODE } from 'api/utils'

import { clearErrorMessage, getGroupList, selectGroupsStatus, updateGroup, createGroup } from 'slices/groupsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  NavMenu,
  List,
  InputFormat,
  CustomButton,
  ColorPickerFormat,
  CardSubmitFooter,
  BadgeLabel,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { ListItem, ColorType } from 'components/common/types'
import { ColorTypes, ColumnSizes } from 'components/common/utils'

import placeholderImage from 'images/allEmpty.svg'

import GroupDelete from './GroupDelete'

import styles from './GroupEdit.module.scss'

const NEW_GROUP_ID = 0

const GroupEdit: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [groupNameValidity, setGroupNameValidity] = React.useState(false)
  const [groupName, setGroupName] = React.useState<string | undefined>(undefined)
  const [groupColor, setGroupColor] = React.useState<ColorType>(ColorTypes.Silver)
  const [selectedGroupId, setSelectedGroupId] = React.useState<number | undefined>(undefined)
  const [groupItems, setGroupItems] = React.useState<ListItem[]>([])
  const [openDelete, setOpenDelete] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getGroupList(workspaceId, false))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  const groupsStatus = useSelector(selectGroupsStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { groups, isRequesting, errorMessage } = groupsStatus

  const hasGroups = React.useMemo(() => groups.length > 0, [groups])
  const hasGroupItems = React.useMemo(() => !_.isEmpty(groupItems), [groupItems])
  const selected = React.useMemo(
    () => (selectedGroupId === NEW_GROUP_ID ? undefined : groups.find(group => group.groupId === selectedGroupId)),
    [selectedGroupId, groups]
  )
  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaces, workspaceId])

  React.useEffect(() => {
    if (selectedGroupId === NEW_GROUP_ID) {
      setGroupName('')
      setGroupColor(ColorTypes.Silver)
      return
    }
    setGroupName(selected?.name || groups[0]?.name)
    setGroupColor(selected?.color || groups[0]?.color || ColorTypes.Silver)
    setSelectedGroupId(selected?.groupId || groups[0]?.groupId)
  }, [groups, selectedGroupId, selected])

  React.useEffect(() => {
    setGroupItems(
      hasGroups
        ? groups.map(group => ({
            id: group.groupId,
            title: group.name,
            color: group.color,
            option: <div className={`${styles.square} bg-${group.color}`} />,
          }))
        : []
    )
  }, [groups, hasGroups])

  React.useEffect(() => {
    if (_.isEmpty(groupItems)) {
      return
    }

    setSelectedGroupId(prev => {
      // 追加アイテムを保存した時
      if (prev === NEW_GROUP_ID && !groupItems.some(item => item.id === NEW_GROUP_ID)) {
        return Number(_.last(groupItems)!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !groupItems.some(item => item.id === prev)) {
        return Number(groupItems[0].id)
      }
      return prev
    })
  }, [groupItems, selectedGroupId])

  const onDetailClick = () => {
    window.open('https://help.smileboard.jp/create_group', '_blank')
  }

  const disabled = React.useMemo(
    () => !(groupName && groupNameValidity && groupColor),
    [groupName, groupNameValidity, groupColor]
  )

  const unchanged = React.useMemo(
    () => groupName === selected?.name && groupColor === selected?.color,
    [selected, groupName, groupColor]
  )

  const badgeLabel = React.useMemo(() => {
    const item = workspaces.find(w => w.workspaceId === workspaceId)
    return item?.name || ''
  }, [workspaces, workspaceId])

  const onSubmit = () => {
    if (selectedGroupId === NEW_GROUP_ID && groupName && groupColor) {
      dispatch(createGroup(workspaceId, groupName, groupColor))
      setSubmitted(true)
      return
    }
    if (selectedGroupId && groupName) {
      dispatch(updateGroup(workspaceId, selectedGroupId, groupName, groupColor))
      setSubmitted(true)
    }
  }

  const onCancel = React.useCallback(() => {
    if (selectedGroupId === NEW_GROUP_ID) {
      setGroupItems(prev => prev.filter(item => item.id !== NEW_GROUP_ID))
      const newId = !_.isEmpty(groups) && groups[0]?.groupId
      setSelectedGroupId(newId || undefined)
    }

    setGroupName(selected?.name || groups[0]?.name)
    setGroupColor(selected?.color || groups[0]?.color || ColorTypes.Silver)
  }, [groups, selectedGroupId, selected])

  const onAddNewItem = React.useCallback(() => {
    setGroupItems(prev =>
      prev.concat({
        title: '',
        id: NEW_GROUP_ID,
        color: ColorTypes.Silver,
        option: <div className={`${styles.square} bg-${ColorTypes.Silver}`} />,
      })
    )
    setSelectedGroupId(NEW_GROUP_ID)
  }, [])

  const onListItemChange = React.useCallback((id: number) => {
    setSelectedGroupId(prevGroupId => {
      if (prevGroupId === id) {
        return prevGroupId
      }
      if (prevGroupId === NEW_GROUP_ID) {
        setGroupItems(prev => prev.filter(item => item.id !== NEW_GROUP_ID))
      }

      return id
    })
  }, [])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: 'すでにこの名前のグループは存在しています。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div className="font-x-large flex-grow-1 fw-bold">グループ管理</div>
              <div className="px-2 align-self-center">{workspace && <BadgeLabel label={badgeLabel} />}</div>
            </div>
            <CustomButton
              icon="plus"
              className="ms-2"
              onClick={onAddNewItem}
              disabled={groupItems.some(item => item.id === NEW_GROUP_ID)}
            >
              グループの追加
            </CustomButton>
          </div>
        </div>
        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={styles.list}>
              {hasGroupItems ? (
                <List
                  items={groupItems}
                  selectedId={selectedGroupId}
                  onAction={((id: number) => onListItemChange(id)) as (s: string | number) => void}
                />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholderImage} alt="" />
                    <div className="font-middle fw-bold py-4">グループがまだ登録されていません</div>
                    <div>
                      ワークスペース内のメンバーをグループ、熟練度などでまとめて管理する事ができます。グループに属する作業者に一括で作業を予定したり、グループ毎の生産性を見ることもできます。
                    </div>
                    <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                      グループについてもっと詳しく
                    </Button>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {selectedGroupId || selectedGroupId === NEW_GROUP_ID ? (
                <>
                  <div className="h-100 overflow-auto">
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <CardTitle className="font-large fw-bold">グループ情報</CardTitle>
                        <span className="font-x-small text-muted">※必須項目</span>
                      </div>
                      <InputFormat
                        label="名前※"
                        placeholder="名前を入力"
                        value={groupName}
                        validations={[Rules.Required]}
                        maxLength={100}
                        size={ColumnSizes.middle}
                        onChange={value => setGroupName(value)}
                        onValidate={setGroupNameValidity}
                        className="mb-3"
                      />
                      <ColorPickerFormat
                        label="グループカラー"
                        color={groupColor}
                        size={ColumnSizes.middle}
                        onChange={setGroupColor}
                      />
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">グループの削除</CardTitle>
                      <Button
                        outline
                        color="danger"
                        className="my-3"
                        onClick={() => setOpenDelete(true)}
                        disabled={selectedGroupId === NEW_GROUP_ID}
                      >
                        このグループを削除
                      </Button>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={() => onCancel()}
                    onSubmit={onSubmit}
                    submitDisabled={disabled || unchanged}
                    cancelDisabled={unchanged}
                    updatedBy={selected?.updatedBy}
                    updatedAt={selected?.updatedAt}
                  />
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholderImage} alt="" />
                    <div className="font-middle fw-bold py-4">グループが選択されていません</div>
                    <div>グループを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <GroupDelete
          isOpen={openDelete}
          workspaceId={workspaceId}
          groupId={selectedGroupId}
          onSuccess={() => setOpenDelete(false)}
          onCancel={() => setOpenDelete(false)}
        />
      </div>
    </NavMenu>
  )
}

export default GroupEdit
