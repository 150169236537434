import { useDispatch } from 'react-redux'

import { logout as sessionLogout } from 'slices/sessionSlice'
import { clearTenantSummary } from 'slices/tenantsSlice'
import { clearWorkspaces } from 'slices/workspacesSlice'

// ログアウト時は必ずこのフックを使用する

const useLogout = () => {
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(sessionLogout())
    dispatch(clearTenantSummary())
    dispatch(clearWorkspaces())
  }
  return { logout }
}
export default useLogout
