import * as React from 'react'
import { Card, CardBody, CardText, CardTitle } from 'reactstrap'

import type { ColorType } from 'components/common/types'

import styles from './styles.module.scss'

export type ListItem = {
  title: string
  id: string | number
  data?: string | React.ReactElement
  option?: React.ReactElement
  color?: ColorType
}

type Props = {
  items: ListItem[]
  selectedId: string | number | undefined
  onAction?: (selected: string | number) => void
}

const List: React.FC<Props> = ({ items, selectedId, onAction = () => null }) => {
  return (
    <div>
      {items.map((item: ListItem, index: number) => {
        const cardTextColor = item.id === selectedId ? 'text-white-50' : 'text-black-50'
        return (
          <Card
            key={index}
            className={styles.card}
            onClick={() => onAction(item.id)}
            color={item.id === selectedId ? 'primary' : 'default'}
            inverse={item.id === selectedId}
          >
            <CardBody className="p-2 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="overflow-hidden">
                  <CardTitle className="m-0 text-truncate">{item.title || '\u00A0'}</CardTitle>
                  {item.data &&
                    (typeof item.data === 'string' ? (
                      <CardText className={`font-x-small mt-2 text-truncate ${cardTextColor}`}>{item.data}</CardText>
                    ) : (
                      item.data
                    ))}
                </div>
                <div>{item.option}</div>
              </div>
            </CardBody>
          </Card>
        )
      })}
    </div>
  )
}

export default List
