import _ from 'lodash'
import * as React from 'react'
import { Card, CardBody } from 'reactstrap'

import BadgeLabel from 'components/common/BadgeLabel/BadgeLabel'

type Props = {
  amount: string
  ratio?: string
  badgeLabel: string
  badgeColor: string
}

const AmountCard: React.FC<Props> = ({ amount, ratio, badgeLabel, badgeColor }) => {
  return (
    <Card>
      <CardBody>
        <CardBody className="d-flex p-3">
          <BadgeLabel label={badgeLabel} color={badgeColor} />
        </CardBody>
        <CardBody className="d-flex justify-content-center align-items-baseline pt-0 pb-3 px-3">
          <div className="font-x-large fw-bold me-1">{amount}</div>
          <div>円</div>
          {ratio && (
            <>
              <div className="font-x-large fw-bold ms-3 me-1">{_.floor(Number(ratio), 2).toFixed(2)}</div>
              <div>%</div>
            </>
          )}
        </CardBody>
      </CardBody>
    </Card>
  )
}

export default AmountCard
