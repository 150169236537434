import { TenantStatus } from 'api/tenants'
import { Role } from 'api/users'
import type { UserResponse } from 'api/users'

import type { TeamState } from 'slices/sessionSlice'

import type { LinkItem, Item } from 'components/common/Drawer/Drawer'

import type { DropdownMenu } from './HeaderNavbar'
import type { NavigateFunction } from 'react-router-dom'

export const DrawerItems = (user: UserResponse): Item[] => {
  if (user.role === Role.TenantAdmin) {
    return [
      {
        to: '/tenants',
        match: /^\/tenants(\/\d+\/(detail|users|statistics|services))?$/,
        page: 'tenant',
        icon: 'tenant',
        label: 'テナント管理',
      },
    ]
  }

  const canViewBP = user.canViewBOP
  const canManageBP = user.canManageBOP

  const menuItems = [
    {
      to: '/dashboard',
      match: /^\/dashboard(\/\d+)?/,
      page: 'dashboard',
      icon: 'dashboard',
      label: 'ダッシュボード',
    },
    {
      to: '/schedules',
      match: /^\/schedules(\/\d+)+$/,
      page: 'schedules',
      icon: 'plan',
      label: '作業計画',
    },
    {
      to: '/assignment',
      match: /^\/assignment(\/\d+)?$/,
      page: 'assignment',
      icon: 'relocation',
      label: '人員配置',
    },
    {
      to: '/workspaces',
      match: /^\/workspaces((\/\d+)?|\/\d+\/(edit|groups|allocation|templates))$/,
      page: 'workspaces',
      icon: 'workspace',
      label: 'ワークスペース\n管理',
    },
    {
      to: '/workers',
      match: /^\/(workers|shifts(\/\d+(\/\d{4}-\d{2}-\d{2})?)|skills)$/,
      page: 'workers',
      icon: 'member',
      label: 'メンバー管理',
    },
    {
      to: '/reports',
      match: /^\/reports(\/\d+(\/\d{4}-\d{2}-\d{2})?)?$/,
      page: 'reports',
      icon: 'report',
      label: '業務レポート',
    },
  ]

  if (canManageBP) {
    menuItems.splice(menuItems.length - 1, 0, {
      to: '/balance-of-payments',
      match: /^\/(balance-of-payments|hourly-wage)$/,
      page: 'balance-of-payments',
      icon: 'incomePence',
      label: '収支管理',
    })
  }

  if (canManageBP || canViewBP) {
    menuItems.push({
      to: '/bop-reports/bop',
      match: /^\/bop-reports\/$/,
      page: 'bop reports',
      icon: 'incomePenceReport',
      label: '収支レポート',
    })
  }

  return menuItems
}

export const TeamDrawerItems = (): Item[] => {
  return [
    {
      to: '/team-assignment',
      match: /^\/team-assignment/,
      page: 'team-assignment',
      icon: 'relocation',
      label: '人員配置',
    },
    {
      to: '/team-schedules',
      match: /^\/team-schedules/,
      page: 'team-schedules',
      icon: 'plan',
      label: '作業計画',
    },
    {
      to: '/team-progress',
      match: /^\/team-progress/,
      page: 'team-progress',
      icon: 'dashboard',
      label: '作業進捗',
    },
  ]
}

export const DropdownItems = (logout: () => void, navigate: NavigateFunction, user: UserResponse): DropdownMenu => {
  if (user.role === Role.TenantAdmin) {
    return {
      name: user.name,
      menu: [
        {
          label: 'アカウントと設定',
          action: () => navigate('/account/edit'),
        },
        {
          label: 'ログアウト',
          action: () => logout(),
        },
      ],
    }
  }

  const menuItems = []
  if (user.tenants[0]?.status === TenantStatus.Active) {
    // 利用停止中テナントの場合はアカウントと設定への遷移をできないようにする
    menuItems.push({
      label: 'アカウントと設定',
      action: () => navigate('/account/edit'),
    })
  }
  menuItems.push(
    {
      label: 'スマイルボードの使い方',
      action: () => window.open('https://help.smileboard.jp/', '_blank'),
    },
    {
      label: 'ログアウト',
      action: () => logout(),
    }
  )

  return {
    name: `${user.tenants[0]?.name || ''}\u2001${user.name}`,
    menu: menuItems,
  }
}

export const TeamDropdownItems = (logout: () => void, navigate: NavigateFunction, team: TeamState): DropdownMenu => {
  const menuItems = [
    {
      label: '操作メンバー切替',
      action: () => navigate('/team-select-workers'),
    },
    {
      label: 'ログアウト',
      action: () => logout(),
    },
  ]

  return {
    name: `${team.workspaceName}/${team.groupName}\u2001${team.workerName}`,
    menu: menuItems,
  }
}

export const DrawerLinks = (): LinkItem[] => [
  {
    to: '/users',
    match: /^\/users$/,
    label: 'ユーザー管理',
  },
  {
    to: '/account/edit',
    match: /^\/account\/edit$|^\/company\/edit$/,
    label: 'アカウントと設定',
  },
]
