import * as React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

import { CheckboxGroup } from 'components/common/CheckboxGroup/CheckboxGroup'
import { CustomButton } from 'components/common/CustomButton/CustomButton'
import type { FilterItem } from 'components/common/types'

import styles from './FilteringButton.module.scss'

type Props = {
  items: FilterItem[]
  onChange: (filterItems: number[]) => void
  value: number[]
  label: string
  disabled?: boolean
  size?: 'sm' | 'lg'
}

const FilteringButton: React.FC<Props> = ({ items, value, onChange, label, disabled, size }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const handleCheckboxChange = (filterItems: FilterItem[]) => {
    const selectedItems = filterItems.reduce((acc, cur) => {
      if (cur.checked && !acc.includes(cur.key)) {
        return acc.concat(cur.key)
      }
      if (!cur.checked && acc.includes(cur.key)) {
        return acc.filter(key => key !== cur.key)
      }
      return acc
    }, value)
    onChange(selectedItems)
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle tag="div" disabled={disabled}>
        <CustomButton outline icon="filter" size={size} disabled={disabled}>
          {label}
        </CustomButton>
      </DropdownToggle>
      <DropdownMenu end={true} className={styles.dropdown}>
        <div className="w-100">
          <CheckboxGroup filterItems={items} onChange={handleCheckboxChange} />
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default FilteringButton
