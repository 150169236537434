import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { selectSessionStatus } from 'slices/sessionSlice'
import { selectTenantsStatus, getTenant } from 'slices/tenantsSlice'
import { getDashboardFilter } from 'slices/usersSlice'
import { selectWorksStatus } from 'slices/worksSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { BadgeLabel, CustomButton, DropdownButton, MoveDropdown, NavMenu } from 'components/common'

import useDateQuery from 'hooks/useDateQuery'

import CSVManualRecordsInputDialog from './CSVManualRecordsInputDialog'
import DashboardDateChangeButton from './DashboardDateChangeButton'
import ManualInputDialog from './ManualInputDialog/ManualInputDialog'
import { getManualInputWorkspaces } from './utils'

import type { Moment } from 'moment'

const MANUAL_INPUT_AVAILABLE_PERIOD = 32

type Props = {
  onInterval: () => void
  children: React.ReactNode
}

const Dashboard: React.FC<Props> = ({ children, onInterval }) => {
  const [openMoment, setOpenMoment] = React.useState<Moment>(moment())
  const { workspaceId } = useParams<'workspaceId'>()
  const [isOpenManualInputDialog, setIsOpenManualInputDialog] = React.useState(false)
  const [isOpenCSVManualRecordsInputDialog, setIsOpenCSVManualRecordsInputDialog] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const date = useDateQuery()

  // IndividualSummaryでのみ使用し、getWorkByDateで取得しているため、ここでは取得しない
  const { works } = useSelector(selectWorksStatus, shallowEqual)
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectTenantsStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const isTotalSummary = React.useMemo(() => !workspaceId, [workspaceId])
  const isBopMonitoring = React.useMemo(() => pathname === '/dashboard/balance-of-payments', [pathname])

  const workspaceName = React.useMemo(
    () => tenantSummary?.workspaceData.find(data => data.workspaceId === Number(workspaceId))?.workspaceName || '',
    [tenantSummary?.workspaceData, workspaceId]
  )

  React.useEffect(() => {
    dispatch(getDashboardFilter())
  }, [dispatch])

  React.useEffect(() => {
    const tenantId = user.tenants[0].tenantId
    dispatch(getTenant(tenantId))
    dispatch(getWorkspaceList(tenantId))
  }, [dispatch, user.tenants])

  const changeDateQuery = React.useCallback(
    (newDate: string) => navigate(`${pathname}?date=${newDate}`),
    [navigate, pathname]
  )

  React.useEffect(() => {
    changeDateQuery(date)
  }, [date, changeDateQuery])

  React.useEffect(() => {
    const timerId = setTimeout(
      () => {
        onInterval()
        setOpenMoment(moment())
      },
      15 * 60 * 1000
    )
    return () => clearTimeout(timerId)
  }, [dispatch, openMoment, date, onInterval])

  const movePaths = React.useMemo(() => {
    const items = []
    if (!_.isEmpty(works)) {
      items.push({ label: '作業計画', onClick: () => navigate(`/schedules/${workspaceId}/${works[0].workId}`) })
    }
    if (date === moment().format('YYYY-MM-DD')) {
      items.push({ label: '人員配置', onClick: () => navigate(`/assignment/${workspaceId}`) })
    }
    return items
  }, [navigate, works, workspaceId, date])

  const showManualInput = React.useMemo(() => {
    if (isBopMonitoring) {
      return false
    }
    const selectedWorkspace = isTotalSummary
      ? workspaces
      : workspaces.filter(w => w.workspaceId === Number(workspaceId))
    const existManualInput = !_.isEmpty(getManualInputWorkspaces(selectedWorkspace))
    const today = moment().startOf('d')
    const diff = moment(today).diff(date, 'd')
    const inPeriod = diff >= 0 && diff <= MANUAL_INPUT_AVAILABLE_PERIOD

    return existManualInput && inPeriod
  }, [isBopMonitoring, isTotalSummary, workspaces, date, workspaceId])

  const handleManualInputSuccess = () => {
    setIsOpenManualInputDialog(false)
    onInterval()
  }
  const handleCSVManualRecordsInputSuccess = () => {
    setIsOpenCSVManualRecordsInputDialog(false)
    onInterval()
  }

  const badgeLabel = React.useMemo(() => {
    if (isBopMonitoring) {
      return '収支モニタリング'
    }
    if (workspaceId) {
      return workspaceName
    }
    return 'サマリー'
  }, [isBopMonitoring, workspaceId, workspaceName])

  return (
    <NavMenu>
      <div>
        <div className="d-flex m-4">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold pe-2">ダッシュボード</div>
            <BadgeLabel label={badgeLabel} />
          </div>
          <div className="d-flex ms-auto">
            {!isTotalSummary && !_.isEmpty(movePaths) && (
              <div className="me-2">
                <MoveDropdown items={movePaths} name="individual-summary-move-dropdown"></MoveDropdown>
              </div>
            )}
            {showManualInput && (
              <DropdownButton
                buttonLabel="実績入力"
                onClickButton={() => setIsOpenManualInputDialog(true)}
                dropdownItems={[
                  { label: '実績CSV一括入力', onClick: () => setIsOpenCSVManualRecordsInputDialog(true) },
                ]}
                className="me-2"
              />
            )}
            {!isTotalSummary && (
              <CustomButton icon="edit" outline className="me-2" onClick={() => navigate(`/workspaces/${workspaceId}`)}>
                ワークスペース設定
              </CustomButton>
            )}
            <DashboardDateChangeButton onChange={changeDateQuery} />
          </div>
        </div>
        <div className="mt-3 mx-3">{children}</div>
        <ManualInputDialog
          isOpen={isOpenManualInputDialog}
          onCancel={() => setIsOpenManualInputDialog(false)}
          onSuccess={handleManualInputSuccess}
        />
        <CSVManualRecordsInputDialog
          isOpen={isOpenCSVManualRecordsInputDialog}
          onCancel={() => setIsOpenCSVManualRecordsInputDialog(false)}
          date={date}
          onSuccess={handleCSVManualRecordsInputSuccess}
        />
      </div>
    </NavMenu>
  )
}

export default Dashboard
