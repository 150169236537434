import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardTitle, CardBody, Col, Row, Button } from 'reactstrap'

import { ENABLE_DIALOG_ERROR_STATUS_CODES, CONFLICT_ERROR_STATUS_CODE } from 'api/utils'

import { showError, showSuccess } from 'slices/notificationSlice'
import {
  getOfficialDutyList,
  selectOfficialDutiesStatus,
  updateOfficialDuty,
  createOfficialDuty,
  getOfficialDuty,
  clearOfficialDuty,
} from 'slices/officialDutiesSlice'

import {
  NavMenu,
  List,
  InputFormat,
  InputGroupFormatWithCostsBadge,
  CustomButton,
  CardSubmitFooter,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { ListItem } from 'components/common/types'
import { ColumnSizes } from 'components/common/utils'

import placeholder from 'images/allEmpty.svg'

import HourlyWageDelete from './HourlyWageDelete'

import styles from './HourlyWage.module.scss'

const NEW_OFFICIAL_DUTY_ID = 0
const MAX_ITEMS = 20

const HourlyWage: React.FC = () => {
  const [officialDutyName, setOfficialDutyName] = React.useState<string | undefined>(undefined)
  const [officialDutyNameValidity, setOfficialDutyNameValidity] = React.useState(false)
  const [averageHourlyWage, setAverageHourlyWage] = React.useState<number>(0)
  const [averageHourlyWageValidity, setAverageHourlyWageValidity] = React.useState(false)
  const [selectedOfficialDutyId, setSelectedOfficialDutyId] = React.useState<number | undefined>(undefined)
  const [officialDutyItems, setOfficialDutyItems] = React.useState<ListItem[]>([])
  const { officialDuties, isRequesting, errorMessage, officialDuty } = useSelector(
    selectOfficialDutiesStatus,
    shallowEqual
  )
  const hasOfficialDutyItems = React.useMemo(() => !_.isEmpty(officialDutyItems), [officialDutyItems])
  const [openDelete, setOpenDelete] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getOfficialDutyList())
  }, [dispatch])

  React.useEffect(() => {
    if (selectedOfficialDutyId === NEW_OFFICIAL_DUTY_ID) {
      setOfficialDutyName('')
      setAverageHourlyWage(0)
      return
    }
    if (selectedOfficialDutyId === undefined) {
      return
    }
    // selectedOfficialDutyIdが変化した時、個別データを取得する
    dispatch(getOfficialDuty(selectedOfficialDutyId))
  }, [selectedOfficialDutyId, dispatch])

  // officialDutyから、フォームに値を設定する
  React.useEffect(() => {
    if (!officialDuty) {
      return
    }
    setOfficialDutyName(officialDuty.name)
    setAverageHourlyWage(officialDuty.averageHourlyWage)
  }, [officialDuty])

  const initOfficialDutyItems = React.useCallback(() => {
    setOfficialDutyItems(
      officialDuties.map(duty => ({
        id: duty.id,
        title: duty.name || '',
      }))
    )
  }, [officialDuties])

  // officialDutiesのレスポンス更新時にofficialDutyItemsを初期化する
  React.useEffect(() => {
    initOfficialDutyItems()
  }, [initOfficialDutyItems])

  React.useEffect(() => {
    setSelectedOfficialDutyId(prev => {
      // 0件の場合、undefinedで未登録画面を表示させる
      if (_.isEmpty(officialDutyItems)) {
        return undefined
      }
      // 追加アイテムを保存した時
      if (prev === NEW_OFFICIAL_DUTY_ID && !officialDutyItems.some(item => item.id === NEW_OFFICIAL_DUTY_ID)) {
        return Number(_.last(officialDutyItems)!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !officialDutyItems.some(item => item.id === prev)) {
        return Number(officialDutyItems[0].id)
      }
      return prev
    })
  }, [officialDutyItems])

  const onListItemChange = React.useCallback((id: number) => {
    setSelectedOfficialDutyId(prevId => {
      if (prevId === id) {
        return prevId
      }
      if (prevId === NEW_OFFICIAL_DUTY_ID) {
        setOfficialDutyItems(prev => prev.filter(item => item.id !== NEW_OFFICIAL_DUTY_ID))
      }
      return id
    })
  }, [])

  const onAddNewItem = React.useCallback(() => {
    setOfficialDutyItems(prev =>
      prev.concat({
        title: '',
        id: NEW_OFFICIAL_DUTY_ID,
      })
    )
    // selectedOfficialDutyIdを初期化する
    setSelectedOfficialDutyId(NEW_OFFICIAL_DUTY_ID)
  }, [])

  const disabled = React.useMemo(
    () => !(officialDutyName && officialDutyNameValidity && averageHourlyWage && averageHourlyWageValidity),
    [officialDutyName, officialDutyNameValidity, averageHourlyWage, averageHourlyWageValidity]
  )

  const unchanged = React.useMemo(
    () => officialDutyName === officialDuty?.name && averageHourlyWage === officialDuty?.averageHourlyWage,
    [officialDuty, officialDutyName, averageHourlyWage]
  )

  const onCancel = React.useCallback(() => {
    initOfficialDutyItems()
    setSelectedOfficialDutyId(officialDuty?.id)
    if (officialDuty) {
      setOfficialDutyName(officialDuty.name)
      setAverageHourlyWage(officialDuty.averageHourlyWage)
    }
  }, [officialDuty, initOfficialDutyItems])

  const onSubmit = React.useCallback(() => {
    if (!officialDutyName || !averageHourlyWage) {
      return
    }
    if (selectedOfficialDutyId === NEW_OFFICIAL_DUTY_ID) {
      // 作成
      dispatch(createOfficialDuty(officialDutyName, averageHourlyWage))
      setSubmitted(true)
      return
    }
    if (selectedOfficialDutyId) {
      // 更新
      dispatch(updateOfficialDuty(selectedOfficialDutyId, officialDutyName, averageHourlyWage))
      setSubmitted(true)
    }
  }, [selectedOfficialDutyId, officialDutyName, averageHourlyWage, dispatch])

  const handleHourlyWageDeleteSuccess = React.useCallback(() => {
    dispatch(showSuccess())
    dispatch(clearOfficialDuty())
    dispatch(getOfficialDutyList())
    setOpenDelete(false)
  }, [dispatch])

  // 平均時給のonChangeハンドラー
  const handleAverageHourlyWageChange = (value: string) => {
    const numericValue = Number(value)
    if (!isNaN(numericValue)) {
      setAverageHourlyWage(numericValue)
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(getOfficialDutyList())
      if (selectedOfficialDutyId) {
        dispatch(getOfficialDuty(selectedOfficialDutyId))
      }
      dispatch(showSuccess())
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: '登録上限に達しているため、保存できませんでした。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, selectedOfficialDutyId])

  const onDetailClick = () => {
    window.open('https://help.smileboard.jp/', '_blank')
  }

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-self-center">
            <div className="font-x-large fw-bold">職掌別時給管理</div>
            <div className="d-flex align-items-center">
              <div className="me-2 text-gray">
                {officialDutyItems.length} / {MAX_ITEMS} 利用中
              </div>
              <CustomButton
                icon="plus"
                className="ms-2"
                onClick={onAddNewItem}
                disabled={
                  officialDutyItems.some(item => item.id === NEW_OFFICIAL_DUTY_ID) ||
                  officialDutyItems.length >= MAX_ITEMS
                }
              >
                職掌を追加
              </CustomButton>
            </div>
          </div>
        </div>

        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={`h-100 ${styles.list}`}>
              {hasOfficialDutyItems ? (
                <List
                  items={officialDutyItems}
                  selectedId={selectedOfficialDutyId}
                  onAction={selected => {
                    if (typeof selected === 'number') {
                      onListItemChange(selected)
                    }
                  }}
                />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">職掌がまだ登録されていません</div>
                    <div>まずは最初の職掌を登録してみましょう。</div>
                    <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                      職掌についてもっと詳しく
                    </Button>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {hasOfficialDutyItems ? (
                <>
                  <div className="h-100 overflow-auto">
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <CardTitle className="font-large fw-bold">職掌設定</CardTitle>
                        <span className="font-small">※必須項目</span>
                      </div>
                      <InputFormat
                        label="名称※"
                        placeholder="名称を入力"
                        value={officialDutyName}
                        validations={[Rules.Required]}
                        maxLength={30}
                        size={ColumnSizes.middle}
                        className="mb-3"
                        onChange={value => setOfficialDutyName(value)}
                        onValidate={setOfficialDutyNameValidity}
                      />
                      <InputGroupFormatWithCostsBadge
                        label="平均時給※"
                        addonText="円 / 時間"
                        size={ColumnSizes.middle}
                        maxLength={10}
                        value={averageHourlyWage.toString()}
                        onChange={handleAverageHourlyWageChange}
                        validations={[Rules.PositiveInteger]}
                        onValidate={setAverageHourlyWageValidity}
                      />
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">職掌の削除</CardTitle>
                      職掌を削除すると、その職掌が設定されているメンバーは原価計算に反映されません。
                      <Button
                        outline
                        color="danger"
                        className="my-3 d-block"
                        onClick={() => setOpenDelete(true)}
                        disabled={selectedOfficialDutyId === NEW_OFFICIAL_DUTY_ID}
                      >
                        職掌を削除
                      </Button>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={() => onCancel()}
                    onSubmit={() => onSubmit()}
                    submitDisabled={disabled || unchanged}
                    cancelDisabled={unchanged}
                    updatedBy={officialDuty?.updatedByName}
                    updatedAt={officialDuty?.updatedAt}
                  />
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">職掌が選択されていません</div>
                    <div>職掌を選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <HourlyWageDelete
          isOpen={openDelete}
          officialDutyId={selectedOfficialDutyId}
          onSuccess={handleHourlyWageDeleteSuccess}
          onCancel={() => setOpenDelete(false)}
        />
      </div>
    </NavMenu>
  )
}

export default HourlyWage
