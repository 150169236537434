import moment from 'moment/moment'
import React from 'react'

import useQuery from './useQuery'

// pathに含まれるDateクエリパラメータを取得する

const useDateQuery = () => {
  const query = useQuery()
  return React.useMemo(() => query.get('date') || moment().format('YYYY-MM-DD'), [query])
}

export default useDateQuery
