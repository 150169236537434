import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/bop'
import * as DashboardAPI from 'api/dashboard'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE, UNREACHABLE_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type BopState = {
  isRequesting: boolean
  errorMessage: string
  bop: API.BopObject | undefined
  updateBopResponse: API.UpdateBopResponse | undefined
  profitAndLoss: DashboardAPI.BopProfitAndLossResponse | undefined
}

const initialState: BopState = {
  isRequesting: false,
  errorMessage: '',
  bop: undefined,
  updateBopResponse: undefined,
  profitAndLoss: undefined,
}

export const bopSlice = createSlice({
  name: 'bop',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getBopSuccess: (state, action: PayloadAction<API.BopResponse>) => {
      state.isRequesting = false
      state.bop = action.payload.bop
    },
    updateBopSuccess: (state, action: PayloadAction<API.UpdateBopResponse>) => {
      state.isRequesting = false
      state.updateBopResponse = action.payload
    },
    getBopProfitAndLossSuccess: (state, action: PayloadAction<DashboardAPI.BopProfitAndLossResponse>) => {
      state.isRequesting = false
      state.profitAndLoss = action.payload
    },
  },
})

export const { startRequest, apiFailure, getBopSuccess, updateBopSuccess, getBopProfitAndLossSuccess } =
  bopSlice.actions

export const getBop =
  (workspaceId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBop(commonParams(getState), workspaceId)
      .then((res: API.BopResponse) => dispatch(getBopSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateBop =
  (workspaceId: number, requestBody: API.UpdateBopRequest): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateBop(commonParams(getState), workspaceId, requestBody)
      .then((res: API.UpdateBopResponse) => dispatch(updateBopSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getBopProfitAndLoss =
  (date: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())

    DashboardAPI.getBopProfitAndLoss(commonParams(getState), date)
      .then((res: DashboardAPI.BopProfitAndLossResponse) => dispatch(getBopProfitAndLossSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectBopStatus = (state: RootState) => ({ ...state.bop })

export default bopSlice.reducer
