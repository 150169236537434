import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { Input } from 'reactstrap'

import type { WorkPlan } from 'api/works'

import { showError, showSuccess } from 'slices/notificationSlice'
import { clearErrorMessage, updateTargetValuesLite, selectWorksLiteStatus } from 'slices/worksLiteSlice'

import styles from './PlanItem.module.scss'
import './plan-item.css'

type Props = {
  workPlans: WorkPlan[]
  workspaceId: number
  workId: number
  scheduleTypeId: number
  disabled?: boolean
}

const PlanItem: React.FC<Props> = props => {
  const { workPlans, workspaceId, workId, scheduleTypeId } = props

  const [edit, setEdit] = React.useState(false)
  const [invalid, setInvalid] = React.useState(false)
  const [inputValue, setInputValue] = React.useState<number | '-'>('-')
  const [submitted, setSubmitted] = React.useState(false)

  const { isRequesting, errorMessage } = useSelector(selectWorksLiteStatus, shallowEqual)
  const dispatch = useDispatch()

  const targetValue = React.useMemo(() => {
    const plan = workPlans.find(p => p.scheduleTypeId === scheduleTypeId)
    return plan ? plan.targetValue : '-'
  }, [scheduleTypeId, workPlans])
  const disabled = React.useMemo(() => props.disabled || targetValue === '-', [props.disabled, targetValue])

  React.useEffect(() => setInputValue(targetValue), [targetValue])

  React.useEffect(() => {
    if (!edit || isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      setEdit(!edit)
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [edit, submitted, errorMessage, isRequesting, dispatch])

  const saveInputValue = () => {
    if (inputValue === targetValue || invalid) {
      setEdit(!edit)
      setInvalid(false)
    } else {
      setSubmitted(true)
      const data = {
        targetValues: workPlans.map(plan => {
          if (plan.scheduleTypeId === scheduleTypeId) {
            return { scheduleTypeId, targetValue: Number(inputValue) }
          }
          return { scheduleTypeId: plan.scheduleTypeId, targetValue: plan.targetValue }
        }),
      }
      dispatch(updateTargetValuesLite(workspaceId, workId, data, true))
    }
  }

  const trigger = (
    <div className="ms-2 align-self-center">
      <i className={`${edit ? 'icf-save' : 'icf-edit'} font-large text-gray`} role="button" onClick={saveInputValue} />
    </div>
  )

  const onInputChange = (value: string) => {
    if (/^(\+|-)/.test(value) || !Number.isSafeInteger(Number(value)) || value === '') {
      setInvalid(true)
    } else {
      setInputValue(Number(value))
      setInvalid(false)
    }
  }

  const onKeyPress = (key: string) => {
    if (key === 'Enter' && !invalid) {
      saveInputValue()
    }
  }

  return (
    <div className={`d-flex pt-1 ps-3 ${edit ? '' : 'mt-2'}`}>
      {edit ? (
        <Input
          className={styles.input}
          maxLength={10}
          defaultValue={targetValue}
          onChange={e => onInputChange(e.target.value)}
          onKeyPress={e => onKeyPress(e.key)}
          invalid={invalid}
          valid={inputValue !== targetValue}
        />
      ) : (
        targetValue.toLocaleString()
      )}
      {!disabled && (
        <Popup trigger={trigger} position="bottom center" on="hover" className="plan-item">
          <div className={styles.toolTip}>作業目標の設定</div>
        </Popup>
      )}
    </div>
  )
}

export default PlanItem
