import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import type { BopProfitAndLossResponse, BopWorkspaceData } from 'api/dashboard'

import { selectBopStatus } from 'slices/bopSlice'

import BopTable from 'components/common/BopReports/BopTable'

import useBopProfitAndLoss from 'hooks/useBopProfitAndLoss'

const BopMonitoringProfitAndLoss: React.FC = () => {
  const [isCheckedEstimate, setIsCheckedEstimate] = React.useState(true)
  const { formatUpdatedAt, processProfitAndLossData } = useBopProfitAndLoss()
  const { profitAndLoss } = useSelector(selectBopStatus, shallowEqual)

  const transformToBopReports = (data: BopProfitAndLossResponse) => {
    const transformData = (workspace: BopWorkspaceData) => {
      return {
        workspaceId: workspace.id,
        workspaceName: workspace.name,
        data: workspace.data,
      }
    }
    return {
      estimates: data.estimate.workspaces.map(transformData),
      actuals: data.actual.workspaces.map(transformData),
      updatedAt: data.updatedAt,
      updatedByName: data.updatedByName,
    }
  }

  const bopCols = React.useMemo(() => {
    if (!profitAndLoss) {
      return []
    }
    const transformedProfitAndLoss = transformToBopReports(profitAndLoss)
    return processProfitAndLossData(transformedProfitAndLoss.actuals, transformedProfitAndLoss.estimates)
  }, [profitAndLoss, processProfitAndLossData])

  return (
    <Card className="mt-2">
      <CardBody className="p-4">
        <CardTitle className="mb-3 fw-bold font-large">損益詳細</CardTitle>

        <div className="d-flex mb-3">
          <div className="form-check me-3">
            <Input
              className="form-check-input"
              id="estimate"
              checked={isCheckedEstimate}
              type="checkbox"
              onChange={e => setIsCheckedEstimate(e.target.checked)}
            />
            <Label className="form-check-label" for="estimate">
              見込表示
            </Label>
          </div>
        </div>

        <BopTable bopCols={bopCols} isCheckedEstimate={isCheckedEstimate} />

        <div className="d-flex justify-content-end align-self-center pt-4 text-muted">
          <i className="icf-updated pe-1" />
          <small>{formatUpdatedAt(profitAndLoss?.updatedAt)}</small>
        </div>
      </CardBody>
    </Card>
  )
}

export default BopMonitoringProfitAndLoss
