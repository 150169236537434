import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/official_duties'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE, UNREACHABLE_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type OfficialDutyResponse = {
  id: number
  name: string
}

type OfficialDutiesState = {
  officialDuties: API.OfficialDutyListArray[]
  officialDuty?: API.OfficialDutyDetailArray
  isRequesting: boolean
  errorMessage: string
}

const initialState: OfficialDutiesState = {
  isRequesting: false,
  errorMessage: '',
  officialDuties: [],
  officialDuty: undefined,
}

export const officialDutiesSlice = createSlice({
  name: 'duties',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getOfficialDutyListSuccess: (state, action: PayloadAction<API.OfficialDutyListResponse>) => {
      state.isRequesting = false
      state.officialDuties = action.payload.officialDuties
    },
    getOfficialDutySuccess: (state, action: PayloadAction<API.OfficialDutyDetailResponse>) => {
      state.isRequesting = false
      state.officialDuty = action.payload.officialDuties[0]
    },
    createOfficialDutySuccess: state => {
      state.isRequesting = false
    },
    updateOfficialDutySuccess: state => {
      state.isRequesting = false
    },
    deleteOfficialDutySuccess: state => {
      state.isRequesting = false
    },
    clearOfficialDuty: state => {
      state.officialDuty = undefined
    },
  },
})

export const {
  startRequest,
  apiFailure,
  getOfficialDutyListSuccess,
  getOfficialDutySuccess,
  createOfficialDutySuccess,
  updateOfficialDutySuccess,
  deleteOfficialDutySuccess,
  clearOfficialDuty,
} = officialDutiesSlice.actions

export const getOfficialDutyList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getOfficialDutyList(commonParams(getState))
    .then((res: API.OfficialDutyListResponse) => dispatch(getOfficialDutyListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getOfficialDuty =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getOfficialDuty(commonParams(getState), id)
      .then((res: API.OfficialDutyDetailResponse) => dispatch(getOfficialDutySuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createOfficialDuty =
  (name: string, averageHourlyWage: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createOfficialDuty(commonParams(getState), name, averageHourlyWage)
      .then(() => dispatch(createOfficialDutySuccess()))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateOfficialDuty =
  (id: number, name: string, averageHourlyWage: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateOfficialDuty(commonParams(getState), id, name, averageHourlyWage)
      .then(() => dispatch(updateOfficialDutySuccess()))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteOfficialDuty =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteOfficialDuty(commonParams(getState), id)
      .then(() => dispatch(deleteOfficialDutySuccess()))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectOfficialDutiesStatus = (state: RootState) => ({ ...state.officialDuties })

export default officialDutiesSlice.reducer
