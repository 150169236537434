import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectAllocationStatus } from 'slices/allocationsSlice'

type Props = {
  allocationItemId: number
}

const WorkspaceAllocationErrorIcon: React.FC<Props> = ({ allocationItemId }) => {
  const { selectedAllocationId } = useSelector(selectAllocationStatus, shallowEqual)

  const cardTextColor = React.useMemo(
    () => (allocationItemId === selectedAllocationId ? 'text-white' : 'text-black-50'),
    [allocationItemId, selectedAllocationId]
  )

  return <i className={`icf-alert font-large ${cardTextColor}`} />
}

export default WorkspaceAllocationErrorIcon
