import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { CardBody, Row, Col, Input } from 'reactstrap'

import type { WorkspaceSummaryData } from 'api/dashboard'

import WorkerFilter from 'components/Workers/WorkerFilter'
import { List, BadgeButton, Chart } from 'components/common'
import { createLineChartOptions } from 'components/common/Chart/Chart'
import type { BadgeItem, Series, FilterItem } from 'components/common/types'

import useDateQuery from 'hooks/useDateQuery'

import { createXAxis, colorTypeToCode, NULL_GROUP_ID, NULL_GROUP_NAME } from './utils'

import styles from './WorkerPerformanceGraph.module.scss'

type WorkerPerformanceBadgeItem = BadgeItem & {
  unit: string
}

export type Props = {
  workspaceId: number
  workerId: number | undefined
  workspaceSummaryData: WorkspaceSummaryData | undefined
  businessStartTime: string
  businessEndTime: string
}

const WorkerPerformanceGraph: React.FC<Props> = props => {
  const { workspaceId, workerId, workspaceSummaryData, businessStartTime, businessEndTime } = props
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined)
  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])
  const [filterWord, setFilterWord] = React.useState('')
  const [groupFilterItems, setGroupFilterItems] = React.useState<FilterItem[]>([])
  const navigate = useNavigate()
  const date = useDateQuery()

  const listItems = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }
    const { workspaceName } = workspaceSummaryData
    const visibleGroupIds = groupFilterItems.filter(item => item.checked).map(item => item.key)
    return workspaceSummaryData.groups
      .filter(group => visibleGroupIds.includes(group.groupId ? group.groupId : NULL_GROUP_ID))
      .flatMap(group =>
        group.workers
          .filter(
            worker =>
              filterWord === '' ||
              worker.workerName.includes(filterWord) ||
              worker.workerWmsMemberId.includes(filterWord)
          )
          .map(worker => ({
            id: worker.workerId,
            title: worker.workerName,
            wmsMemberId: worker.workerWmsMemberId,
            data: `${workspaceName} / ${group.groupName ? group.groupName : NULL_GROUP_NAME}`,
          }))
      )
      .sort((a, b) => a.wmsMemberId.localeCompare(b.wmsMemberId, 'ja'))
  }, [workspaceSummaryData, filterWord, groupFilterItems])

  React.useEffect(() => {
    setSelectedId(workerId ? workerId : listItems[0]?.id)
  }, [workerId, listItems])

  const onListAction = (id: number) => {
    navigate(`/dashboard/${workspaceId}/performance-graph/workers/${id}?date=${date}`)
  }

  React.useEffect(() => {
    if (!workspaceSummaryData) {
      setGroupFilterItems([])
      return
    }
    setGroupFilterItems(
      workspaceSummaryData.groups.map(group => ({
        key: group.groupId ? group.groupId : NULL_GROUP_ID,
        label: group.groupName ? group.groupName : NULL_GROUP_NAME,
        checked: true,
      }))
    )
  }, [workspaceSummaryData])

  const onSearchClick = (filterItems: FilterItem[]) => {
    setGroupFilterItems(filterItems)
  }

  const graphBadges = React.useMemo(() => {
    if (!selectedId || !workspaceSummaryData) {
      return []
    }
    return workspaceSummaryData.groups.flatMap(group =>
      group.workers
        .filter(worker => worker.workerId === selectedId)
        .flatMap(worker =>
          worker.hourlyWorkerData.map<WorkerPerformanceBadgeItem>(data => ({
            color: data.scheduleTypeColor,
            key: data.scheduleTypeId,
            label: data.scheduleTypeName,
            unit: data.unit,
          }))
        )
    )
  }, [selectedId, workspaceSummaryData])

  React.useEffect(() => {
    // graphBadgesが更新されたら初回はbadgeを全て選択状態にする
    setSelectedBadgeKeys(graphBadges.map(graphBadge => graphBadge.key))
  }, [graphBadges])

  const chartOptions = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return {}
    }

    const selectedBadges = graphBadges.filter(badge => selectedBadgeKeys.findIndex(key => key === badge.key) !== -1)
    const xAxisData = createXAxis(businessStartTime, businessEndTime, false, date)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const hourlyWorkerRows = workspaceSummaryData.groups.flatMap(group =>
        group.workers
          .filter(worker => worker.workerId === selectedId)
          .flatMap(worker => worker.hourlyWorkerData)
          .filter(hourlyData => hourlyData.scheduleTypeId === graphBadge.key)
          .flatMap(hourlyData => hourlyData.data)
      )

      // areaの上にlineを表示するためにareaを先にしておく
      const recordData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = hourlyWorkerRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.recordCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })

      const planData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = hourlyWorkerRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.planCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    _.merge<Highcharts.Options, Highcharts.Options>(options, {
      tooltip: {
        formatter() {
          return (
            '<div style="text-align:center">' +
            [
              `${moment(this.x).format('HH:mm')}~${moment(this.x).add(1, 'h').format('HH:mm')}`,
              this.series.name,
              `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}`,
            ].join('<br>') +
            '</div>'
          )
        },
      },
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 1,
      },
    })
    return options
  }, [workspaceSummaryData, graphBadges, businessStartTime, businessEndTime, date, selectedBadgeKeys, selectedId])

  return (
    <Row>
      <Col md={4}>
        <div className="d-flex mb-3">
          <Input placeholder="メンバー名もしくはID・識別番号で検索" onChange={e => setFilterWord(e.target.value)} />
          <div className="ms-2">
            <WorkerFilter filterItems={groupFilterItems} onChange={onSearchClick} />
          </div>
        </div>
        <div className={styles.list}>
          <List
            items={listItems}
            selectedId={selectedId}
            onAction={((id: number) => onListAction(id)) as (selected: string | number) => void}
          />
        </div>
      </Col>
      <Col md={8}>
        <Chart options={chartOptions} />

        <CardBody className="d-flex row my-2">
          <BadgeButton
            items={graphBadges}
            selected={selectedBadgeKeys}
            onChange={(list: number[]) => setSelectedBadgeKeys(list)}
          />
        </CardBody>
      </Col>
    </Row>
  )
}

export default WorkerPerformanceGraph
