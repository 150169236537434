import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap'

import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import type { WorkerResponse } from 'api/workers'

import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getOfficialDutyList, selectOfficialDutiesStatus } from 'slices/officialDutiesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import { getTenant, selectTenantsStatus } from 'slices/tenantsSlice'
import { updateWorker, selectWorkersStatus, getWorkerList } from 'slices/workersSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import {
  CheckBoxFormat,
  DatePicker,
  InputFormat,
  List,
  SelectBoxFormat,
  CardSubmitFooter,
  CustomButton,
  ItemEdit,
  NavMenu,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { ListItem, FilterItem, SuggestionItem } from 'components/common/types'
import { MAGIQANNEAL_APPLICATION_ID, ColumnSizes } from 'components/common/utils'

import useWorker from 'hooks/useWorker'

import placeholder from 'images/allEmpty.svg'

import PerformanceIndicesInput from './PerformanceIndicesInput'
import WorkerDelete from './WorkerDelete'
import WorkerFilter from './WorkerFilter'
import WorkersExportDialog from './WorkersExportDialog'
import WorkersImportDialog from './WorkersImportDialog'
import WorkersOptEngineDialog from './WorkersOptEngineDialog'

import styles from './WorkerList.module.scss'

import type { WorkerEditType } from './types'

// WorkerEditTypeからWorkerEditDataTypeにキー名を変更するための関数
const transformWorkerEditKeys = (data: WorkerEditType) => {
  const workerEditDataKey = {
    officialDuty: 'officialDutyId',
    workspace: 'workspaceId',
    group: 'groupId',
    skills: 'skillIds',
  }
  return _.mapKeys(data, (_value, key) => _.get(workerEditDataKey, key) || key)
}

const WorkerList: React.FC = () => {
  const { showPerformanceIndices, disabled, editData, setEditData, setNameValidity, initData } = useWorker()

  const [initEditData, setInitEditData] = React.useState<WorkerEditType>(initData)
  const [submitted, setSubmitted] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openEditChangesDiscardDialog, setOpenEditChangesDiscardDialog] = React.useState(false)
  const [openWorkersOptEngineDialog, setOpenWorkersOptEngineDialog] = React.useState(false)
  const [openWorkersImportDialog, setOpenWorkersImportDialog] = React.useState(false)
  const [openWorkersExportDialog, setOpenWorkersExportDialog] = React.useState(false)
  const [filterItems, setFilterItems] = React.useState<FilterItem[]>([])
  const [filterWord, setFilterWord] = React.useState('')
  const [currentIndex, setCurrentIndex] = React.useState<number | undefined>(0)
  const [selectedWorker, setSelectedWorker] = React.useState<WorkerResponse | undefined>(undefined)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { workers } = useSelector(selectWorkersStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  // apiKey を取得する
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const { tenants } = useSelector(selectTenantsStatus, shallowEqual)
  const { officialDuties } = useSelector(selectOfficialDutiesStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getWorkerList(false))
    dispatch(getWorkspaceList())
    dispatch(getSkillList())
    dispatch(getOfficialDutyList())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])

  const { apiKey, magiQannealTenant, magiQannealLocations } = React.useMemo(() => {
    const application = tenants[0]?.applications.find(app => app.applicationId === MAGIQANNEAL_APPLICATION_ID)
    return {
      apiKey: application?.options.apiKey ?? '',
      magiQannealTenant: application?.options.tenant ?? '',
      magiQannealLocations: application?.options.relatedWorkspaceData?.map(rw => rw.location) ?? [],
    }
  }, [tenants])

  const listItems = React.useMemo(
    () =>
      workers.reduce((acc: ListItem[], cur: WorkerResponse) => {
        const filtered = filterWord === '' || cur.name.includes(filterWord) || cur.wmsMemberId.includes(filterWord)
        if (!filtered) {
          return acc
        }
        const data = filterItems.filter(f => f.checked).find(item => item.key === cur.workspaceId)?.label
        if (data) {
          acc.push({
            id: cur.workerId,
            title: cur.name,
            data,
          })
        }
        return acc
      }, []),
    [workers, filterWord, filterItems]
  )

  React.useEffect(() => {
    const initFilterItem = workspaces
      .map(workspace => ({ key: workspace.workspaceId, label: workspace.name, checked: true }))
      .concat([{ key: 0, label: 'ワークスペース未所属', checked: true }])
    setFilterItems(initFilterItem)
    setFilterWord('')
  }, [workspaces])

  // editData, initEditDataの初期化
  const initializeWorkerData = React.useCallback(
    (worker: WorkerResponse) => {
      if (!worker) {
        return
      }
      const nextOfficialDuty = officialDuties?.find(duty => duty.id === worker.officialDutyId)
      const nextWorkspace = workspaces.find(workspace => workspace.workspaceId === worker.workspaceId)
      const nextGroup = groups.find(group => group.groupId === worker.groupId)
      const nextSelectedSkills = skills.filter(skill => worker.skillIds.includes(skill.skillId))
      const nextPerformanceIndices = worker.performanceIndices.reduce((acc, value) => {
        return { ...acc, [value.scheduleTypeId]: value.index?.toString() }
      }, {})

      const data = {
        name: worker.name,
        wmsMemberId: worker.wmsMemberId,
        officialDuty: nextOfficialDuty ? { key: nextOfficialDuty.id, value: nextOfficialDuty.name ?? '' } : undefined,
        workspace: nextWorkspace ? { key: nextWorkspace.workspaceId, value: nextWorkspace.name } : undefined,
        group: nextGroup ? { key: nextGroup.groupId, value: nextGroup.name } : undefined,
        groupLeader: worker.groupLeader,
        hiredAt: worker.hiredAt,
        skills: nextSelectedSkills,
        performanceIndices: nextPerformanceIndices,
      }

      setEditData(prev => (_.isEqual(prev, data) ? prev : data))
      setInitEditData(prev => (_.isEqual(prev, data) ? prev : data))
    },
    [officialDuties, workspaces, groups, skills, setEditData]
  )

  // 選択しているメンバーが変更されたらメンバー詳細を書き換える
  React.useEffect(() => {
    setSelectedWorker(prev => {
      if (typeof currentIndex !== 'number') {
        return prev
      }
      const target = workers[currentIndex]
      if (_.isEqual(prev, target)) {
        return prev
      }
      initializeWorkerData(workers[currentIndex])

      return target
    })
  }, [currentIndex, workers, initializeWorkerData])

  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterWord(event.target.value)
    setSelectedWorker(undefined)

    setCurrentIndex(undefined)
  }

  const onSearchClick = (items: FilterItem[]) => {
    setFilterItems(items)
    setSelectedWorker(undefined)

    setCurrentIndex(undefined)
  }

  const handleWorkspaceIdListChange = (selectedId: string | number) => {
    const index = workers.findIndex((worker: WorkerResponse) => worker.workerId === selectedId)

    setCurrentIndex(index)
  }

  React.useEffect(() => {
    if (selectedWorker?.workspaceId) {
      dispatch(getGroupList(selectedWorker.workspaceId, false))
    }
  }, [dispatch, selectedWorker?.workspaceId])

  React.useEffect(() => {
    // groupsから選択中のgroupが見つからない場合はgroup選択状態リセット
    if (editData.group && !groups.find(group => group.groupId === editData.group?.key)) {
      setEditData({ ...editData, group: undefined, groupLeader: false })
    }
  }, [groups, editData, setEditData])

  React.useEffect(() => {
    // dutiesから選択中のofficialDutyが見つからない場合はofficialDuty選択状態リセット
    setEditData(prevEditData => {
      if (prevEditData.officialDuty && !officialDuties.find(duty => duty.id === prevEditData.officialDuty?.key)) {
        return { ...prevEditData, officialDuty: undefined }
      }
      return prevEditData
    })
  }, [officialDuties, setEditData])

  // 初期化のみ
  React.useEffect(() => {
    if (!selectedWorker || !_.isEqual(initEditData, editData)) {
      return
    }
    initializeWorkerData(selectedWorker)
    setForecastColorScheduleTypeIds([])
  }, [selectedWorker, initEditData, editData, initializeWorkerData])

  const workspaceSelectItems = React.useMemo(
    () => workspaces.map(workspace => ({ key: workspace.workspaceId, value: workspace.name })),
    [workspaces]
  )

  const officialDutySelectItems = React.useMemo(
    () => officialDuties.map(officialDuty => ({ key: officialDuty.id, value: officialDuty.name ?? '' })),
    [officialDuties]
  )

  const groupSelectItems = React.useMemo(
    () => (editData.workspace?.key ? groups.map(group => ({ key: group.groupId, value: group.name })) : []),
    [editData.workspace?.key, groups]
  )

  const initSelected = React.useMemo(() => selectedWorker === undefined, [selectedWorker])
  const unchanged = React.useMemo(() => _.isEqual(editData, initEditData), [editData, initEditData])

  const showOptEngineButton = React.useMemo(() => !!apiKey, [apiKey])

  const [forecastColorScheduleTypeIds, setForecastColorScheduleTypeIds] = React.useState<number[]>([])

  const onSubmit = () => {
    if (!selectedWorker) {
      dispatch(showError({ errorMessage: '不正なデータです。' }))
      return
    }

    const performanceIndices = workspaces
      .flatMap(workspace => workspace.scheduleTypes)
      .filter(scheduleType => scheduleType.dataConnection)
      .map(({ scheduleTypeId }) => ({
        scheduleTypeId: Number(scheduleTypeId),
        index: Number(editData.performanceIndices[scheduleTypeId]),
      }))

    const allData = {
      name: editData.name || '',
      officialDutyId: editData.officialDuty?.key || null,
      workspaceId: editData.workspace?.key || 0,
      groupId: editData.group?.key || null,
      groupLeader: editData.groupLeader,
      hiredAt: editData.hiredAt || null,
      skillIds: editData.skills.map(skill => skill.skillId),
      performanceIndices,
    }

    const transformedEditData = transformWorkerEditKeys(editData)
    const transformedInitEditData = transformWorkerEditKeys(initEditData)

    // initEditDataとEditDataを比較し、変更箇所のみを送信する。
    const data = _.pickBy(
      allData,
      (_value, key) => !_.isEqual(_.get(transformedEditData, key), _.get(transformedInitEditData, key))
    )

    if (_.isEmpty(data)) {
      dispatch(showError({ errorMessage: '不正なデータです。' }))
      return
    }

    setSubmitted(true)
    dispatch(updateWorker(selectedWorker.workerId, data))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      setForecastColorScheduleTypeIds([])
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: 'ID・識別番号が重複しています。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const onDeleteSuccess = () => {
    dispatch(showSuccess())
    setOpenDelete(false)
  }

  const handleEditChangesDiscard = () => {
    setOpenEditChangesDiscardDialog(false)

    if (initEditData.workspace) {
      dispatch(getGroupList(initEditData.workspace.key, false))
    }
  }

  const handleChangeWorkspace = React.useCallback(
    (key: string | number | undefined) => {
      const selectedWorkspaceId = Number(key)
      if (isNaN(selectedWorkspaceId) || editData.workspace?.key === selectedWorkspaceId) {
        return
      }
      const workspace = workspaceSelectItems.find(w => w.key === selectedWorkspaceId)
      dispatch(getGroupList(selectedWorkspaceId, false))
      setEditData({ ...editData, workspace })
    },
    [editData, workspaceSelectItems, dispatch, setEditData]
  )

  const handleWorkersImport = () => {
    setOpenWorkersImportDialog(false)
    dispatch(
      showSuccess({
        successMessage: 'メンバー情報のアップロードに成功しました。反映まで最大30分程度かかる場合があります。',
      })
    )
  }

  const handleSkillEdit = (items: SuggestionItem[]) => {
    const skillData = skills.filter(s => items.some(i => i.id === s.skillId))
    setEditData({ ...editData, skills: skillData })
  }

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className={`d-flex justify-content-between ${styles.topContents}`}>
          <div className="font-x-large fw-bold align-self-center">メンバー一覧</div>
          <div className="d-flex">
            {showOptEngineButton && (
              <CustomButton className="me-2" outline onClick={() => setOpenWorkersOptEngineDialog(true)}>
                magiQannealと連携
              </CustomButton>
            )}
            <CustomButton className="me-2" outline onClick={() => setOpenWorkersImportDialog(true)}>
              インポート
            </CustomButton>
            <CustomButton className="me-2" outline onClick={() => setOpenWorkersExportDialog(true)}>
              エクスポート
            </CustomButton>
            <CustomButton icon="plus" onClick={() => navigate('/worker-create')}>
              メンバー追加
            </CustomButton>
          </div>
        </div>
        <Row className={`py-3 ${styles.row}`}>
          <Col md={4} className="h-100">
            <Card className={`position-sticky h-100 ${styles.list}`}>
              <div className="d-flex">
                <Input placeholder="メンバー名もしくはID・識別番号で検索" onChange={onSearchInput}></Input>
                <div className="ms-2">
                  <WorkerFilter filterItems={filterItems} onChange={onSearchClick} />
                </div>
              </div>
              {listItems.length > 0 ? (
                <List
                  items={listItems}
                  selectedId={selectedWorker?.workerId}
                  onAction={((id: number) => handleWorkspaceIdListChange(id)) as (selected: string | number) => void}
                />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto ${styles.placeholderImage}`} src={placeholder} alt="" width="80%" />
                    <div className="font-middle fw-bold py-4">メンバーがいません</div>
                    <div>メンバーを追加して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {initSelected ? (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">メンバーが選択されていません</div>
                    <div>メンバーを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              ) : (
                <>
                  <div className={styles.memberDetail}>
                    <CardBody>
                      <CardTitle className="font-large fw-bold">メンバー詳細</CardTitle>
                      <InputFormat
                        label="名前※"
                        placeholder="メンバー名もしくはID・識別番号で検索"
                        value={editData.name}
                        size={ColumnSizes.middle}
                        maxLength={100}
                        onChange={value => setEditData({ ...editData, name: value.trim() })}
                        validations={[Rules.Required]}
                        onValidate={setNameValidity}
                        className="mb-3"
                      />
                      <InputFormat
                        label="ID・識別番号"
                        value={editData.wmsMemberId}
                        size={ColumnSizes.middle}
                        disabled={true}
                        className="mb-3"
                      />
                      <FormGroup row>
                        <Label for="hiredAt" md={4}>
                          入社日
                        </Label>
                        <Col md={4} className="align-self-center">
                          <DatePicker
                            value={editData.hiredAt}
                            placeholder="入社日を選択"
                            onChange={date => setEditData({ ...editData, hiredAt: date })}
                          />
                        </Col>
                      </FormGroup>

                      {officialDutySelectItems.length > 0 && (
                        <>
                          <SelectBoxFormat
                            label="職掌"
                            placeholder="職掌を選択"
                            formText="選択された職掌の平均時給を用いて費用を計算します。"
                            value={editData.officialDuty?.key.toString()}
                            size={ColumnSizes.middle}
                            items={officialDutySelectItems}
                            onChange={e => {
                              const officialDuty = officialDutySelectItems.find(
                                g => g.key.toString() === e.key?.toString()
                              )
                              setEditData({ ...editData, officialDuty })
                            }}
                            className="mb-3"
                          />
                        </>
                      )}

                      <SelectBoxFormat
                        label="所属ワークスペース"
                        placeholder="ワークスペースを選択"
                        formText="メンバーに予定を設定するためにはワークスペースに所属させてください。"
                        value={editData.workspace?.key.toString()}
                        size={ColumnSizes.middle}
                        items={workspaceSelectItems}
                        onChange={e => handleChangeWorkspace(e.key)}
                        className="mb-3"
                      />
                      {groupSelectItems.length > 0 && (
                        <>
                          <SelectBoxFormat
                            label="所属グループ"
                            placeholder="グループを選択"
                            value={editData.group?.key.toString()}
                            size={ColumnSizes.middle}
                            items={groupSelectItems}
                            onChange={e => {
                              const group = groupSelectItems.find(g => g.key.toString() === e.key?.toString())
                              setEditData({ ...editData, group })
                            }}
                            className="mb-3"
                          />
                          {editData.group && (
                            <CheckBoxFormat
                              label="グループリーダー"
                              checkboxLabel="リーダー"
                              checked={editData.groupLeader}
                              onChange={e =>
                                setEditData({
                                  ...editData,
                                  groupLeader: e.target.checked,
                                })
                              }
                              className="mb-3"
                            />
                          )}
                        </>
                      )}
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">スキル設定</CardTitle>
                      <CardText className="py-2">
                        メンバーにはスキルを設定することができます。新しいスキルは
                        <Link className="text-decoration-none" to="/skills">
                          スキル管理
                        </Link>
                        で登録してください。
                      </CardText>
                      <ItemEdit
                        items={skills.map(s => ({ id: s.skillId, value: s.name }))}
                        selectedItems={editData.skills.map(s => ({ id: s.skillId, value: s.name }))}
                        label="メンバーにスキルを追加"
                        itemName="スキル"
                        onChange={handleSkillEdit}
                      />
                    </CardBody>
                    {showPerformanceIndices && (
                      <CardBody>
                        <CardTitle className="font-large fw-bold">人時生産性設定</CardTitle>
                        <CardText>
                          全てのワークスペースに登録されている作業に対する人時生産性を設定する事ができます。所属ワークスペース以外の作業についても人時生産性を設定しておく事でワークスペース間を移動して作業した際にも人時生産性を計算する事ができます。
                        </CardText>
                        <PerformanceIndicesInput
                          workerId={selectedWorker?.workerId}
                          forecastColorScheduleTypeIds={forecastColorScheduleTypeIds}
                          onFocus={setForecastColorScheduleTypeIds}
                          performanceIndices={editData.performanceIndices}
                          onChange={value =>
                            setEditData({
                              ...editData,
                              performanceIndices: value,
                            })
                          }
                          initialPerformanceIndices={initEditData.performanceIndices}
                        />
                      </CardBody>
                    )}

                    <CardBody>
                      <CardTitle className="font-large fw-bold">メンバーの削除</CardTitle>
                      <CardText>メンバーを削除すると、作業履歴情報などはすべて失われ、復旧できません。</CardText>
                      <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                        このメンバーを削除
                      </Button>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={() => setOpenEditChangesDiscardDialog(true)}
                    onSubmit={onSubmit}
                    updatedBy={selectedWorker?.updatedBy}
                    updatedAt={selectedWorker?.updatedAt}
                    cancelDisabled={unchanged}
                    submitDisabled={unchanged || disabled}
                    submitName="worker-list-submit"
                  />
                </>
              )}
            </Card>
          </Col>
        </Row>

        {selectedWorker && (
          <WorkerDelete
            isOpen={openDelete}
            workerId={selectedWorker.workerId}
            onSuccess={onDeleteSuccess}
            onCancel={() => setOpenDelete(false)}
          />
        )}

        <EditChangesDiscardDialog
          isOpen={openEditChangesDiscardDialog}
          onCancel={() => setOpenEditChangesDiscardDialog(false)}
          onDiscard={handleEditChangesDiscard}
        />

        <WorkersOptEngineDialog
          isOpen={openWorkersOptEngineDialog}
          apiKey={apiKey}
          magiQannealTenant={magiQannealTenant}
          magiQannealLocations={magiQannealLocations}
          onCancel={() => setOpenWorkersOptEngineDialog(false)}
        />

        <WorkersImportDialog
          isOpen={openWorkersImportDialog}
          onSuccess={handleWorkersImport}
          onCancel={() => setOpenWorkersImportDialog(false)}
        />

        <WorkersExportDialog
          isOpen={openWorkersExportDialog}
          onSuccess={() => setOpenWorkersExportDialog(false)}
          onCancel={() => setOpenWorkersExportDialog(false)}
        />
      </div>
    </NavMenu>
  )
}

export default WorkerList
