import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Button } from 'reactstrap'

import { Role } from 'api/users'
import type { UpdateWorkSchedule } from 'api/works'

import { showError } from 'slices/notificationSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { updateWork } from 'slices/worksSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  NavMenu,
  BadgeLabel,
  SubmitFooter,
  MoveDropdown,
  CustomButton,
  TimeSelect,
  SidebarButton,
} from 'components/common'
import type { EditSchedule } from 'components/common/types'
import {
  isReadOnlyWorkspace,
  getEditSchedules,
  getOriginalSchedulesFromWork,
  getUpdateWorkerSchedules,
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
  ColorTypes,
} from 'components/common/utils'

import useAssignment from 'hooks/useAssignment'
import useWorkspaceSideBar from 'hooks/useWorkspaceSideBar'

import AssignmentWorkerFilter from './AssignmentWorkerFilter'
import DroppingWorkerCards, { PREFIX_WORKSPACE_ID, PREFIX_SCHEDULE_TYPE_ID } from './DroppingWorkerCards'

import styles from './Assignment.module.scss'

import type { ScheduleEditType } from './DroppingWorkerCards'
import type { Worker, DropdownScheduleType } from './WorkerCard'

const Assignment: React.FC = () => {
  const [scheduleEditData, setScheduleEditData] = React.useState<ScheduleEditType[]>([])
  const [isOpenSidebar, setOpenSidebar] = React.useState(true)
  const [shouldResetFilters, setShouldResetFilters] = React.useState(false)
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const {
    startHour,
    startMinute,
    openMoment,
    businessStartTime,
    businessEndTime,
    workspaceId,
    work,
    isBetween,
    setSubmitted,
  } = useAssignment()

  const { workspaceName, workspace } = useWorkspaceSideBar('/assignment')

  const disabled = React.useMemo(
    () =>
      user.role === Role.ProcessAdmin &&
      !workspaces.find(w => w.workspaceId === workspaceId)?.memberIds.includes(user.userId),
    [user, workspaces, workspaceId]
  )

  const otherWorkspaces = React.useMemo(
    (): DropdownScheduleType[] =>
      workspaces
        .filter(w => w.workspaceId !== workspaceId)
        .map(w => ({ type: [PREFIX_WORKSPACE_ID, w.workspaceId].join('-'), label: w.name })),
    [workspaceId, workspaces]
  )

  const isReadOnly = React.useMemo(() => isReadOnlyWorkspace(user, workspace), [user, workspace])

  const getWorkersFromScheduleTypeId = React.useCallback(
    (scheduleTypeId: number): Worker[] => {
      if (!work) {
        return []
      }
      return work.groups
        .flatMap(group =>
          group.workers.reduce((acc: Worker[], cur) => {
            const filtered = cur.schedules.filter(s => isBetween(s.startAt, s.duration))
            const found = filtered.find(s => s.scheduleTypeId === scheduleTypeId)

            if ((filtered.length <= 1 || scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID) && found) {
              acc.push({
                id: cur.workerId.toString(),
                scheduleId: scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID ? null : found.scheduleId,
                startAt: found.startAt,
                duration: found.duration,
                name: cur.name,
                wmsMemberId: cur.wmsMemberId,
                groupName: group.name ?? '未所属',
                groupColor: group.color,
                skillIds: cur.skillIds,
                supporter: !!group.supportedWorkspaceId,
              })
            }
            return acc
          }, [])
        )
        .sort((a, b) => a.wmsMemberId.localeCompare(b.wmsMemberId, 'ja'))
    },
    [isBetween, work]
  )

  const getWorkersFromSupportSchedules = React.useCallback(
    (id: string): Worker[] => {
      if (!work) {
        return []
      }
      const splitId = Number(id.split('-')[1])
      return work.groups
        .flatMap(group =>
          group.workers.reduce((acc: Worker[], cur) => {
            const filtered = cur.schedules.filter(s => isBetween(s.startAt, s.duration))
            const found = filtered.find(s => s.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID)

            if (found?.supportWorkspaceId === splitId) {
              acc.push({
                id: cur.workerId.toString(),
                scheduleId: found.scheduleId,
                startAt: found.startAt,
                duration: found.duration,
                name: cur.name,
                wmsMemberId: cur.wmsMemberId,
                groupName: group.name ?? '未所属',
                skillIds: cur.skillIds,
              })
            }
            return acc
          }, [])
        )
        .sort((a, b) => a.wmsMemberId.localeCompare(b.wmsMemberId, 'ja'))
    },
    [isBetween, work]
  )

  const scheduleTypeSchedules = React.useMemo((): ScheduleEditType[] => {
    if (!workspace) {
      return []
    }
    return workspace.scheduleTypes.map(scheduleType => ({
      type: [PREFIX_SCHEDULE_TYPE_ID, scheduleType.scheduleTypeId].join('-'),
      color: scheduleType.color,
      label: scheduleType.name,
      skillIds: scheduleType.requiredSkills.map(s => s.skillId),
      workers: getWorkersFromScheduleTypeId(scheduleType.scheduleTypeId),
    }))
  }, [getWorkersFromScheduleTypeId, workspace])

  const supportSchedules = React.useMemo((): ScheduleEditType[] => {
    if (!otherWorkspaces) {
      return []
    }
    return otherWorkspaces.map(w => ({
      type: w.type,
      label: w.label,
      workers: getWorkersFromSupportSchedules(w.type),
    }))
  }, [getWorkersFromSupportSchedules, otherWorkspaces])

  const noSchedules = React.useMemo(
    (): ScheduleEditType[] => [
      {
        type: SHIFT_SCHEDULE_TYPE_ID.toString(),
        color: ColorTypes.Silver,
        label: '予定未入力',
        workers: getWorkersFromScheduleTypeId(SHIFT_SCHEDULE_TYPE_ID),
      },
    ],
    [getWorkersFromScheduleTypeId]
  )

  const schedules = React.useMemo(
    (): ScheduleEditType[] =>
      scheduleTypeSchedules.concat(supportSchedules, noSchedules).map(s => ({
        ...s,
        workers: s.workers.map(w => {
          return { ...w, visible: true }
        }),
      })),
    [supportSchedules, scheduleTypeSchedules, noSchedules]
  )

  const getSubmitSchedules = (worker: Worker, id: string, isSupport: boolean): UpdateWorkSchedule[] => {
    const originalSchedules: EditSchedule[] =
      work?.groups.flatMap(g =>
        g.workers.filter(w => w.workerId.toString() === worker.id).flatMap(w => getEditSchedules(w.schedules))
      ) || []
    const scheduleTypeId = isSupport ? SUPPORT_SCHEDULE_TYPE_ID : Number(id)
    const supportWorkspaceId = isSupport ? Number(id) : null
    const updateSchedules = getUpdateWorkerSchedules(
      worker.startAt,
      worker.duration,
      scheduleTypeId,
      supportWorkspaceId,
      null,
      originalSchedules
    )

    const deleteData: UpdateWorkSchedule[] = originalSchedules
      .filter(org => !updateSchedules.find(s => s.scheduleId === org.scheduleId))
      .map(org => ({ scheduleId: org.scheduleId, schedule: null }))

    const updateData: UpdateWorkSchedule[] = updateSchedules
      .filter(s => !originalSchedules.some(org => _.isEqual(org, s)))
      .map(s => ({
        scheduleId: s.scheduleId,
        schedule: {
          scheduleTypeId: s.scheduleTypeId,
          supportWorkspaceId: s.supportWorkspaceId,
          startAt: s.startAt,
          duration: s.duration,
          workerId: Number(worker.id),
          groupId: null,
        },
      }))
    return deleteData.concat(updateData)
  }

  const movePaths = React.useMemo(() => {
    const items = [{ label: 'ダッシュボード', onClick: () => navigate(`/dashboard/${workspaceId}`) }]
    if (work) {
      items.push({ label: '作業計画', onClick: () => navigate(`/schedules/${workspaceId}/${work.workId}`) })
    }
    return items
  }, [navigate, work, workspaceId])

  const onCancel = () => {
    setScheduleEditData(schedules)
    setShouldResetFilters(true)
  }
  const onSubmit = () => {
    if (!work) {
      dispatch(showError())
      return
    }
    setShouldResetFilters(true)
    setSubmitted(true)
    const compare = schedules.flatMap(s => s.workers)
    const data: UpdateWorkSchedule[] = scheduleEditData
      .filter(d => d.type !== SHIFT_SCHEDULE_TYPE_ID.toString() && d.workers.length > 0)
      .flatMap(edit => {
        const type = edit.type.split('-')
        const isSupport = type[0] === PREFIX_WORKSPACE_ID
        return edit.workers
          .filter(worker => !_.isEqual(_.find(compare, ['id', worker.id]), worker))
          .flatMap(worker => getSubmitSchedules(worker, type[1], isSupport))
      })

    const originalSchedules = getOriginalSchedulesFromWork(data, work)

    dispatch(updateWork(workspaceId, work.workId, { schedules: data, originalSchedules }))
  }

  const unchanged = React.useMemo(() => _.isEqual(schedules, scheduleEditData), [schedules, scheduleEditData])

  React.useEffect(() => setScheduleEditData(schedules), [schedules])

  return (
    <>
      <NavMenu isOpenSidebar={isOpenSidebar}>
        <>
          <div className={styles.container}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex">
                <div className="font-x-large fw-bold">人員配置</div>
                <div className="px-2 align-self-center">
                  <BadgeLabel label={workspaceName} />
                </div>
                <SidebarButton isOpenSidebar={isOpenSidebar} onClick={() => setOpenSidebar(!isOpenSidebar)} />
              </div>
              <div className="d-flex">
                <MoveDropdown items={movePaths} name="assignment-move-dropdown"></MoveDropdown>
                <CustomButton
                  icon="edit"
                  outline
                  disabled={isReadOnly}
                  className="ms-2"
                  onClick={() => navigate(`/workspaces/${workspaceId}`)}
                >
                  ワークスペース設定
                </CustomButton>
              </div>
            </div>
            <div className="d-flex align-items-center pb-3">
              <div className="pe-2">表示中の配置:</div>
              <TimeSelect
                hour={startHour}
                minute={startMinute}
                label=""
                start={businessStartTime}
                end={businessEndTime}
                onChange={(hour, minute) => navigate(`?hour=${hour}&minute=${minute}`, { replace: true })}
              />
              <div className="pe-2">からの配置</div>
              <Button
                color="link"
                className="shadow-none text-decoration-none"
                onClick={() => navigate(pathname, { replace: true })}
              >
                現在時刻の配置にする
              </Button>
            </div>

            <AssignmentWorkerFilter
              initSchedules={schedules}
              onChange={setScheduleEditData}
              setShouldReset={setShouldResetFilters}
              shouldReset={shouldResetFilters}
            />
            <Row className="pe-3">
              <DroppingWorkerCards
                scheduleEditData={scheduleEditData}
                otherWorkspaces={otherWorkspaces}
                currentTime={openMoment.format('HH:mm')}
                isOpenSidebar={isOpenSidebar}
                setScheduleEditData={setScheduleEditData}
              />
            </Row>
          </div>
          <SubmitFooter
            onCancel={onCancel}
            onSubmit={onSubmit}
            cancelDisabled={unchanged}
            submitDisabled={unchanged || disabled}
            updatedBy={work?.updatedBy}
            updatedAt={work?.updatedAt}
            submitName="assignment-submit"
          />
        </>
      </NavMenu>
    </>
  )
}

export default Assignment
