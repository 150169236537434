import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { Card, CardBody } from 'reactstrap'

import type { WorkspaceSummaryData, WorkspaceSummaryWorker, WorkspaceSummaryHourlyWorkerData } from 'api/dashboard'

import { selectWorksStatus } from 'slices/worksSlice'

import { BadgeButton } from 'components/common'
import type { BadgeItem } from 'components/common/types'
import { SUPPORT_SCHEDULE_TYPE_ID, ColorTypes } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import useDateQuery from 'hooks/useDateQuery'

import { summarizeWorkspaceSummaryGroup, NULL_GROUP_ID } from './utils'

import styles from './PerformanceList.module.scss'

export type Props = {
  workspaceId: number
  workspaceSummaryData: WorkspaceSummaryData | undefined
}

const PerformanceList: React.FC<Props> = props => {
  const { workspaceId, workspaceSummaryData } = props
  const [openGroupIds, setOpenGroupIds] = React.useState<number[]>([])
  const navigate = useNavigate()
  const { works } = useSelector(selectWorksStatus, shallowEqual)

  const { businessHourBlocks } = useBusinessTime()
  const date = useDateQuery()

  const groupPerformances = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }
    return summarizeWorkspaceSummaryGroup(workspaceSummaryData.groups)
  }, [workspaceSummaryData])

  const onGroupToggleClick = (groupId: number) => {
    const newOpenGroupIds = openGroupIds.includes(groupId)
      ? openGroupIds.filter(id => id !== groupId)
      : openGroupIds.concat([groupId])
    setOpenGroupIds(newOpenGroupIds)
  }
  const isOpen = (groupId: number) => openGroupIds.includes(groupId)

  // 現在作業中のスケジュールを抽出する
  const currentWorkerSchedules = React.useMemo(() => {
    const todayWork = works.find(work => moment().isSame(work.date, 'day'))
    if (!todayWork) {
      return []
    }
    return todayWork.groups.flatMap(group => {
      return group.workers.map(worker => {
        const currentSchedule = worker.schedules
          .filter(schedule => schedule.scheduleTypeId !== 1) // シフトを除外する
          .find(schedule => {
            const startAt = moment(schedule.startAt)
            const endAt = startAt.clone().add(schedule.duration, 'second')
            return moment().isBetween(startAt, endAt, null, '[]')
          })
        return {
          workerId: worker.workerId,
          schedule: currentSchedule,
        }
      })
    })
  }, [works])

  const workerBadgeItems = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }
    return workspaceSummaryData.groups.flatMap(group => {
      return group.workers.map(worker => {
        const currentSchedule = currentWorkerSchedules.find(current => current.workerId === worker.workerId)?.schedule
        const badgeItems: BadgeItem[] = []
        if (currentSchedule) {
          if (currentSchedule.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID) {
            // 応援作業の場合
            badgeItems.push({
              color: ColorTypes.Silver,
              key: -(currentSchedule.supportWorkspaceId || 0), // keyに指定するscheduleTypeIdとsupportWorkspaceIdが競合するためsupportWorkspaceIdをマイナスにする
              label: currentSchedule.supportWorkspaceName || '',
              disabled: true,
            })
          } else {
            const scheduleType = worker.hourlyWorkerData.find(
              data => data.scheduleTypeId === currentSchedule.scheduleTypeId
            )
            if (scheduleType) {
              badgeItems.push({
                color: scheduleType.scheduleTypeColor,
                key: scheduleType.scheduleTypeId,
                label: scheduleType.scheduleTypeName,
                disabled: true,
              })
            }
          }
        }
        return {
          workerId: worker.workerId,
          badgeItems,
        }
      })
    })
  }, [workspaceSummaryData, currentWorkerSchedules])

  const groupBadgeItems = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }
    return workspaceSummaryData.groups.map(group => {
      const badgeItems = group.workers.flatMap(worker => {
        return workerBadgeItems.find(workerBadgeItem => workerBadgeItem.workerId === worker.workerId)?.badgeItems || []
      })
      return {
        groupId: group.groupId ? group.groupId : NULL_GROUP_ID,
        badgeItems: _.uniqBy(badgeItems, 'key'),
      }
    })
  }, [workspaceSummaryData, workerBadgeItems])

  const scheduleTypesPopup = (badgeItems: BadgeItem[] | undefined) => {
    return (
      <Popup trigger={<i className={`icf-info text-secondary ${styles.scheduleTypesOpener}`} role="button" />}>
        <Card className="shadow">
          <CardBody className={`p-2 pe-3 ${styles.popupCardBody}`}>
            <div className="p-2">現在実施している作業</div>
            {badgeItems && <BadgeButton items={badgeItems} />}
          </CardBody>
        </Card>
      </Popup>
    )
  }

  const lastImportedAt = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return undefined
    }
    const data = _.chain(workspaceSummaryData.groups)
      .flatMap<WorkspaceSummaryWorker>('workers')
      .flatMap<WorkspaceSummaryHourlyWorkerData>('hourlyWorkerData')
      .filter('lastImportedAt')
      .maxBy('lastImportedAt')
      .value()
    return data ? moment(data.lastImportedAt).format('YYYY/MM/DD HH:mm:ss') : undefined
  }, [workspaceSummaryData])

  return (
    <Card className="mt-3">
      <CardBody className="fw-bold">グループ/メンバー別パフォーマンスリスト</CardBody>
      <CardBody className="pt-0">
        <div className="d-flex">
          <div className={styles.labelWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={`${styles.teamHeader} bg-secondary-pale p-2`}>グループ</th>
                </tr>
              </thead>

              <tbody>
                {groupPerformances.map(group => (
                  <React.Fragment key={group.groupId}>
                    <tr key={`tr-${group.groupId}`}>
                      <td className={`${styles.label} d-flex justify-content-between align-items-center p-2`}>
                        <div className={`${styles.workerName} d-flex align-items-center`}>
                          <i
                            className={`icf-carot_${isOpen(group.groupId) ? 'down' : 'right'} me-1`}
                            role="button"
                            onClick={() => onGroupToggleClick(group.groupId)}
                          />
                          <div
                            className="text-truncate"
                            role="button"
                            onClick={() =>
                              navigate(
                                `/dashboard/${workspaceId}/performance-graph/groups/${group.groupId}?date=${date}`
                              )
                            }
                          >
                            {group.groupName}
                          </div>
                        </div>

                        {scheduleTypesPopup(groupBadgeItems.find(items => items.groupId === group.groupId)?.badgeItems)}
                      </td>
                    </tr>

                    {isOpen(group.groupId) &&
                      group.workerDataList.map(worker => (
                        <tr key={`tr-${group.groupId}-${worker.workerId}`}>
                          <td className={`${styles.label} d-flex justify-content-between align-items-center`}>
                            <div
                              className="text-truncate"
                              role="button"
                              onClick={() =>
                                navigate(
                                  `/dashboard/${workspaceId}/performance-graph/workers/${worker.workerId}?date=${date}`
                                )
                              }
                            >
                              {worker.workerName}
                            </div>

                            {scheduleTypesPopup(
                              workerBadgeItems.find(items => items.workerId === worker.workerId)?.badgeItems
                            )}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className={styles.dataWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  {businessHourBlocks.map(h => (
                    <th key={`d-th-${h}`} className={`${styles.dataCell} bg-secondary-pale p-2 text-center`}>
                      {h}:00
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {groupPerformances.map(group => (
                  <React.Fragment key={`d-${group.groupId}`}>
                    <tr key={`d-tr-${group.groupId}`}>
                      {businessHourBlocks.map(h => {
                        const rate = group.groupData[`${h}:00`]?.rate || null
                        const warning = rate !== null && Math.abs(100 - rate) >= 10
                        return (
                          <td
                            key={`d-td-${group.groupId}-${h}`}
                            className={`${warning ? 'text-danger' : ''} ${styles.dataCell} p-2 text-center`}
                          >
                            {rate ? `${rate}%` : ''}
                          </td>
                        )
                      })}
                    </tr>

                    {isOpen(group.groupId) &&
                      group.workerDataList.map(worker => (
                        <tr key={`d-tr-${group.groupId}-${worker.workerId}`}>
                          {businessHourBlocks.map(h => {
                            const rate = worker.workerData[`${h}:00`]?.rate || null
                            const warning = rate !== null && Math.abs(100 - rate) >= 10
                            return (
                              <td
                                key={`d-td-${group.groupId}-${worker.workerId}-${h}`}
                                className={`${warning ? 'text-danger' : ''} ${styles.dataCell} p-2 text-center`}
                              >
                                {rate ? `${rate}%` : ''}
                              </td>
                            )
                          })}
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CardBody>

      <CardBody className="d-flex text-muted align-self-end pt-0">
        {lastImportedAt && <i className="icf-updated align-self-center pe-1" />}
        <small className={styles.date}>{lastImportedAt}</small>
      </CardBody>
    </Card>
  )
}

export default PerformanceList
