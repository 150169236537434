import _ from 'lodash'
import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { matchPath, useLocation, useParams } from 'react-router-dom'

import { Role } from 'api/users'
import type { WorkspaceResponse } from 'api/workspaces'

import { selectSessionStatus } from 'slices/sessionSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import useBopReportsQuery from './useBopReportsQuery'
import useDateQuery from './useDateQuery'

type MenuItemsType = {
  path: string
  label: string
}

type SideBarProps = {
  previousItem?: MenuItemsType
  items?: {
    label?: string
    paths: MenuItemsType[]
  }[]
}

type Page = {
  path: string
  sidebar: SideBarProps
}

const workersProps = {
  items: [
    {
      paths: [
        {
          label: 'メンバー一覧',
          path: '/workers',
        },
        {
          label: 'シフト管理',
          path: '/shifts',
        },
        {
          label: 'スキル管理',
          path: '/skills',
        },
      ],
    },
  ],
}
const tenantsProps = {
  items: [
    {
      paths: [
        {
          label: 'テナント一覧',
          path: '/tenants',
        },
      ],
    },
  ],
}
const userProps = {
  items: [
    {
      paths: [
        {
          label: 'ユーザー一覧',
          path: '/users',
        },
      ],
    },
  ],
}
const workspacesProps = {
  items: [
    {
      paths: [
        {
          label: 'ワークスペース一覧',
          path: '/workspaces',
        },
      ],
    },
  ],
}

const usePages = () => {
  const { pathname } = useLocation()
  const { workspaceId, workId } = useParams<'workspaceId' | 'workId'>()
  const date = useDateQuery()
  const { queryStart, queryEnd } = useBopReportsQuery()
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectTenantsStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  //   ワークスペース
  const workspaceProps = React.useMemo(
    () => ({
      previousItem: {
        label: 'すべてのワークスペース',
        path: '/workspaces',
      },
      items: [
        {
          paths: [
            {
              label: '作業管理',
              path: `/workspaces/${workspaceId}`,
            },
            {
              label: 'グループ管理',
              path: `/workspaces/${workspaceId}/groups`,
            },
            {
              label: 'テンプレート管理',
              path: `/workspaces/${workspaceId}/templates`,
            },
            {
              label: '作業間配賦管理',
              path: `/workspaces/${workspaceId}/allocation`,
            },
            {
              label: '設定',
              path: `/workspaces/${workspaceId}/edit`,
            },
          ],
        },
      ],
    }),
    [workspaceId]
  )

  const accountSettingsProps = React.useMemo(() => {
    const paths = [
      {
        label: 'アカウント情報',
        path: '/account/edit',
      },
    ]
    if (user.role === Role.Admin) {
      paths.push({
        label: 'ご登録企業情報',
        path: '/company/edit',
      })
    }

    return {
      items: [
        {
          paths,
        },
      ],
    }
  }, [user.role])

  const dashboardProps = React.useMemo(() => {
    const items = _.sortBy(tenantSummary?.workspaceData, 'workspaceName').map(data => {
      const targetPath = data.workspaceId === Number(workspaceId) ? pathname : `/dashboard/${data.workspaceId}`
      const queries = `?date=${date}`
      return {
        path: targetPath + queries,
        label: data.workspaceName,
      }
    })
    const paths = [
      {
        path: `/dashboard?date=${date}`,
        label: '業務モニタリング',
      },
      {
        path: `/dashboard/balance-of-payments?date=${date}`,
        label: '収支モニタリング',
      },
    ]
    return {
      items: [
        {
          paths,
        },
        {
          label: 'ワークスペース別',
          paths: items,
        },
      ],
    }
  }, [tenantSummary?.workspaceData, date, workspaceId, pathname])

  const bopProps = React.useMemo(
    () => ({
      items: [
        {
          paths: [
            {
              label: 'ワークスペース収支管理',
              path: '/balance-of-payments',
            },
            {
              label: '職掌別時給管理',
              path: '/hourly-wage',
            },
          ],
        },
      ],
    }),

    []
  )

  const bopReportsProps = React.useMemo(
    () => ({
      items: [
        {
          paths: [
            {
              label: '収支',
              path: `/bop-reports/bop?start=${queryStart}&end=${queryEnd}`,
            },
            {
              label: '損益詳細',
              path: `/bop-reports/profit-and-loss?start=${queryStart}&end=${queryEnd}`,
            },
          ],
        },
      ],
    }),
    [queryStart, queryEnd]
  )

  const createPaths = React.useCallback(
    (path: string, targetWorkspace: WorkspaceResponse) => {
      const targetWorkspaceId = String(targetWorkspace.workspaceId)
      const targetPath = path
        .replace(':workspaceId', String(targetWorkspaceId))
        .replace('/:workId', workspaceId === targetWorkspaceId ? '/' + (workId || '') : '')
      return {
        path: targetPath,
        label: targetWorkspace.name,
      }
    },
    [workId, workspaceId]
  )

  const createProps = React.useCallback(
    (path: string) => {
      if (_.isEmpty(workspaces)) {
        return {}
      }

      if (user.role !== Role.ProcessAdmin) {
        const paths = _.sortBy(workspaces, 'name').map(w => createPaths(path, w))
        return {
          items: [
            {
              paths,
            },
          ],
        }
      }

      const { managementWorkspaces, etcWorkspaces } = _.sortBy(workspaces, 'name').reduce(
        (acc, cur) => {
          const target = cur.memberIds.some(id => id === user.userId) ? acc.managementWorkspaces : acc.etcWorkspaces
          target.push(createPaths(path, cur))
          return acc
        },
        { managementWorkspaces: [], etcWorkspaces: [] } as {
          managementWorkspaces: MenuItemsType[]
          etcWorkspaces: MenuItemsType[]
        }
      )

      return {
        items: [
          {
            label: '管理中',
            paths: managementWorkspaces,
          },
          {
            label: 'その他',
            paths: etcWorkspaces,
          },
        ],
      }
    },
    [createPaths, user.role, user.userId, workspaces]
  )
  const sideBarProps = React.useMemo(() => {
    const pages: Page[] = [
      { path: '/tenants', sidebar: tenantsProps },
      { path: '/tenants/:tenantId/detail', sidebar: tenantsProps },
      { path: '/tenants/:tenantId/users', sidebar: tenantsProps },
      { path: '/tenants/:tenantId/statistics', sidebar: tenantsProps },
      { path: '/tenants/:tenantId/services', sidebar: tenantsProps },
      { path: '/users', sidebar: userProps },
      { path: '/dashboard', sidebar: dashboardProps },
      { path: '/dashboard/balance-of-payments', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId/workspace', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId/performance-list', sidebar: dashboardProps },
      { path: '/dashboard/:workspaceId/performance-graph/workspace', sidebar: dashboardProps },
      {
        path: '/dashboard/:workspaceId/performance-graph/groups/:groupId',
        sidebar: dashboardProps,
      },
      { path: '/dashboard/:workspaceId/performance-graph/workers', sidebar: dashboardProps },
      {
        path: '/dashboard/:workspaceId/performance-graph/workers/:workerId',
        sidebar: dashboardProps,
      },
      { path: '/assignment', sidebar: createProps('/assignment/:workspaceId') },
      { path: '/assignment/:workspaceId', sidebar: createProps('/assignment/:workspaceId') },
      { path: '/workers', sidebar: workersProps },
      { path: '/shifts', sidebar: workersProps },
      { path: '/skills', sidebar: workersProps },
      { path: '/workspaces', sidebar: workspacesProps },
      { path: '/workspaces/:workspaceId', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/edit', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/groups', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/allocation', sidebar: workspaceProps },
      { path: '/workspaces/:workspaceId/templates', sidebar: workspaceProps },
      { path: '/schedules', sidebar: createProps('/schedules/:workspaceId') },
      { path: '/schedules/:workspaceId', sidebar: createProps('/schedules/:workspaceId') },
      { path: '/schedules/:workspaceId/:workId', sidebar: createProps('/schedules/:workspaceId/:workId') },
      { path: '/reports', sidebar: createProps('/reports/:workspaceId') },
      { path: '/reports/:workspaceId', sidebar: createProps('/reports/:workspaceId') },
      {
        path: '/reports/:workspaceId/:date',
        sidebar: createProps(`/reports/:workspaceId/${date}`),
      },
      { path: '/account/edit', sidebar: accountSettingsProps },
      { path: '/company/edit', sidebar: accountSettingsProps },
      { path: '/balance-of-payments', sidebar: bopProps },
      { path: '/hourly-wage', sidebar: bopProps },
      { path: '/bop-reports/bop', sidebar: bopReportsProps },
      { path: '/bop-reports/profit-and-loss', sidebar: bopReportsProps },
    ]
    return pages.find(page => matchPath({ path: page.path }, pathname))?.sidebar || {}
  }, [accountSettingsProps, createProps, dashboardProps, date, pathname, workspaceProps, bopProps, bopReportsProps])

  return sideBarProps
}
export default usePages
