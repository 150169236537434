import * as React from 'react'

import type { CostArrayToString, ValidationType, FieldNames } from 'components/BalanceOfPayments/types'
import * as Rules from 'components/common/FormFormat/ValidationRules'

type FieldName = (typeof FieldNames)[keyof typeof FieldNames]

// （資材・材料費、その他売上設定、管理設定、ワークスペース費用設定）名称・金額の共通フック
export const useBopFormInput = (
  items: CostArrayToString[],
  setItems: (val: (latest: CostArrayToString[]) => CostArrayToString[]) => void,
  validations?: ValidationType,
  maxItems?: number
) => {
  const { showAddButton, showAddSmallButton } = React.useMemo(() => {
    const showAdd = items.length === 0
    const showAddSmall = maxItems !== undefined && items.length > 0 && items.length < maxItems
    return { showAddButton: showAdd, showAddSmallButton: showAddSmall }
  }, [items, maxItems])

  const addItem = React.useCallback(() => {
    const newItem = { name: '', cost: '0' }
    setItems(currentItems => [...currentItems, newItem])
  }, [setItems])

  const deleteItem = React.useCallback(
    (index: number) => {
      setItems(currentItems => currentItems.filter((_, idx) => idx !== index))
    },
    [setItems]
  )

  const updateItem = React.useCallback(
    (index: number, field: FieldName, value: string | number) => {
      setItems(currentItems =>
        currentItems.map((item, idx) => {
          if (idx === index) {
            return { ...item, [field]: value }
          }
          return item
        })
      )
    },
    [setItems]
  )

  // デフォルトのバリデーションルール
  const nameValidations = React.useMemo(() => {
    const defaultNameValidations = [Rules.Required]
    return validations?.nameValidations || defaultNameValidations
  }, [validations])

  const costValidations = React.useMemo(() => {
    const defaultCostValidations = [Rules.PositiveInteger]
    return validations?.costValidations || defaultCostValidations
  }, [validations])

  return {
    items,
    showAddButton,
    showAddSmallButton,
    addItem,
    deleteItem,
    updateItem,
    setItems,
    nameValidations,
    costValidations,
  }
}
