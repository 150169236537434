import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import { selectReportsStatus, getReportPlanAccuracy } from 'slices/reportsSlice'

import { colorTypeToCode } from 'components/Dashboard/utils'
import { BadgeButton, Chart, PeriodSelect } from 'components/common'
import type { Series } from 'components/common/types'
import { createLineChartOptions, PERIOD_LIST } from 'components/common/utils'

import { createXAxis } from './utils'

type DailyPlanAccuracyRow = {
  date: string
  archivePlanCount: number //アーカイブ
  lastPlanCount: number //最終計画
}

type PlanAccuracyRatesRow = {
  date: string
  rates: number[]
}

type PropsType = {
  workspaceId?: number
}

const ReportPlanAccuracy: React.FC<PropsType> = ({ workspaceId }) => {
  const [period, setPeriod] = React.useState<{ start: Date; end: Date }>({
    start: moment().subtract(PERIOD_LIST.DAYS_7, 'days').toDate(),
    end: moment().subtract(1, 'days').toDate(),
  })
  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    const from = moment(period.start).format('YYYY-MM-DD')
    const to = moment(period.end).format('YYYY-MM-DD')
    dispatch(getReportPlanAccuracy(workspaceId, from, to))
  }, [dispatch, workspaceId, period])
  const { planAccuracies } = useSelector(selectReportsStatus, shallowEqual)

  const badgeItems = React.useMemo(() => {
    return _.chain(planAccuracies)
      .map(planAccuracy => ({
        color: planAccuracy.scheduleTypeColor,
        key: planAccuracy.scheduleTypeId,
        label: planAccuracy.scheduleTypeName,
      }))
      .uniqBy('key')
      .sortBy('key')
      .value()
  }, [planAccuracies])

  React.useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const formatter: Highcharts.TooltipFormatterCallbackFunction = function () {
    return `${moment(this.x).format('YYYY/MM/DD')}<br>±${this.y}%`
  }

  const chartOptions = React.useMemo(() => {
    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))
    const xAxisData = createXAxis(period.start, period.end)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const dailyRows = planAccuracies
        .filter(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)
        .flatMap(planAccuracy =>
          planAccuracy.data.map<DailyPlanAccuracyRow>(row => ({
            date: moment(row.time).format('YYYY-MM-DD'),
            archivePlanCount: row.archivePlanCount || 0,
            lastPlanCount: row.lastPlanCount || 0,
          }))
        )
        .filter(planAccuracy => planAccuracy.lastPlanCount > 0 || planAccuracy.archivePlanCount > 0)

      // 最終計画か、アーカイブのどちらかが0だった場合、当てはまる時間の割合を100%にする
      // 各時間の差の割合の平均を出す
      const rates = dailyRows
        .reduce<PlanAccuracyRatesRow[]>((acc, cur) => {
          // 誤差を減らすため、rateでは切り捨てしない
          const rate =
            cur.lastPlanCount === 0 || cur.archivePlanCount === 0
              ? 100
              : Math.abs((1 - cur.archivePlanCount / cur.lastPlanCount) * 100)

          const target = acc.find(row => row.date === cur.date)
          if (!target) {
            return acc.concat([{ date: cur.date, rates: [rate] }])
          }
          target.rates.push(rate)
          return acc
        }, [])
        // rateは小数点一桁まで出す
        .map(accuracy => ({
          date: accuracy.date,
          rate: Math.floor((_.sum(accuracy.rates) / accuracy.rates.length) * 10) / 10,
        }))

      // 最終計画、アーカイブのどちらもデータがない日は0とする
      const data = xAxisData.map(date => {
        const target = rates.find(rate => date === rate.date)
        if (!target) {
          return 0
        }
        return target.rate
      })

      yAxis.push({
        type: 'area',
        color: colorCode,
        data,
        name: graphBadge.label,
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    _.merge(options, {
      xAxis: {
        tickInterval: Math.ceil(xAxisData.length / 7),
      },
      tooltip: { formatter },
      plotOptions: {
        series: {
          cursor: 'pointer',
          events: {
            click(e: Highcharts.SeriesClickEventObject) {
              const date = moment(e.point.category).format('YYYY-MM-DD')
              navigate(`/reports/${workspaceId}/${date}`)
            },
          },
        },
      },
    })
    return options
  }, [planAccuracies, badgeItems, selectedBadgeKeys, period, navigate, workspaceId])

  return (
    <Card className="mb-3">
      <CardBody className="d-flex justify-content-between align-items-center">
        <div className="fw-bold">計画変更率の推移</div>
        <PeriodSelect period={period} onChange={(start, end) => setPeriod({ start, end })} />
      </CardBody>

      <CardBody>
        <Chart options={chartOptions} />
      </CardBody>
      <CardBody className="d-flex row my-2">
        <BadgeButton
          items={badgeItems}
          selected={selectedBadgeKeys}
          onChange={selected => setSelectedBadgeKeys(selected)}
        />
      </CardBody>
    </Card>
  )
}

export default ReportPlanAccuracy
