import _ from 'lodash'
import React from 'react'

import type { CostArrayToString, UnitCostsArrayToString, ValidationListType } from 'components/BalanceOfPayments/types'

const useBopValidation = (
  items: UnitCostsArrayToString[] | CostArrayToString[],
  onValidate: (val: boolean) => void
) => {
  const [validationList, setValidationList] = React.useState<ValidationListType[]>([])

  React.useEffect(() => {
    setValidationList(prev => {
      if (items.length === prev.length) {
        return prev
      }
      return items.map((_item, i) => {
        return prev[i] || { first: false, second: false }
      })
    })
  }, [items])

  const updateValidationList = (index: number, field: keyof ValidationListType, isValid: boolean) => {
    setValidationList(prev => {
      const newValue = prev.map((item, idx) => (idx === index ? { ...item, [field]: isValid } : item))
      return _.isEqual(newValue, prev) ? prev : newValue
    })
  }

  React.useEffect(() => {
    onValidate(validationList.every(v => v.first && v.second))
  }, [onValidate, validationList])
  return { updateValidationList }
}
export default useBopValidation
