import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { TenantStatus } from 'api/tenants'
import { Role } from 'api/users'
import { UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import { login, selectSessionStatus, clearErrorMessage } from 'slices/sessionSlice'

import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { ColumnSizes } from 'components/common/utils'

import styles from './Signup.module.scss'

const Signup: React.FC = () => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [email, setEmail] = React.useState<string | undefined>(undefined)
  const [password, setPassword] = React.useState<string | undefined>(undefined)
  const [emailValidity, setEmailValidity] = React.useState(false)
  const [passwordValidity, setPasswordValidity] = React.useState(false)

  const dispatch = useDispatch()
  const { loggedIn, activated, user, errorMessage } = useSelector(selectSessionStatus, shallowEqual)
  const navigate = useNavigate()
  const onSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    if (email && password) {
      dispatch(login({ email, password }))
    }
  }
  React.useEffect(() => {
    if (!loggedIn) {
      return
    }
    if (!activated) {
      return navigate('/activate')
    }

    if (user.role === Role.TenantAdmin) {
      navigate('/tenants')
    } else {
      if (user.tenants[0].status === TenantStatus.Active) {
        navigate('/dashboard')
      } else {
        navigate('/suspension')
      }
    }
  }, [navigate, loggedIn, activated, user])

  React.useEffect(() => {
    if (errorMessage === UNAUTHORIZED_ERROR_STATUS_CODE) {
      setModalErrorMessage('メールアドレスかパスワードが間違っています。')
    } else if (errorMessage !== '') {
      setModalErrorMessage('サインアップに失敗しました。')
    } else {
      setModalErrorMessage(undefined)
    }
  }, [errorMessage])

  const disabled = React.useMemo(
    () => !(email && emailValidity && password && passwordValidity),
    [email, emailValidity, password, passwordValidity]
  )

  return (
    <>
      <SinglePageCard
        title="サインアップ"
        errorMessage={modalErrorMessage}
        onHideNotification={() => {
          setModalErrorMessage(undefined)
          dispatch(clearErrorMessage())
        }}
      >
        <Container>
          <InputFormat
            label="メールアドレス"
            placeholder="メールアドレス"
            value={email}
            size={ColumnSizes.large}
            maxLength={100}
            formText="招待されたメールアドレスを入力してください"
            onChange={value => setEmail(value)}
            validations={[Rules.Required, Rules.Email]}
            onValidate={setEmailValidity}
            className="mb-3"
          />

          <InputFormat
            label="仮パスワード"
            type="password"
            placeholder="仮パスワード"
            value={password}
            size={ColumnSizes.large}
            maxLength={32}
            onChange={value => setPassword(value)}
            validations={[Rules.MinLength(8), Rules.Password]}
            onValidate={setPasswordValidity}
          />

          <Row>
            <Col className="mt-3">
              この先続行することで
              <a className="text-decoration-none" href="/#/">
                利用規約
              </a>
              と
              <a className="text-decoration-none" href="/#/">
                プライバシーポリシー
              </a>
              に同意したものとします。
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <Button color="primary" className={styles.button} disabled={disabled} onClick={onSubmit}>
                続行
              </Button>
            </Col>
          </Row>
        </Container>
        <div className={styles.loginLink}>
          スマイルボードアカウントをお持ちの場合は
          <Link className="text-decoration-none" to="/login">
            こちらからログイン
          </Link>
          してください。
        </div>
      </SinglePageCard>
    </>
  )
}

export default Signup
