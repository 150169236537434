import axios from 'axios'

import type { TenantStatusType } from 'api/tenants'

import type { CommonParams } from 'slices/utils'

export const Role = {
  Root: 'root',
  TenantAdmin: 'tenantAdmin',
  Admin: 'admin',
  ProcessAdmin: 'processAdmin',
  TeamAdmin: 'teamAdmin',
} as const
export type RoleType = (typeof Role)[keyof typeof Role]

export type UserCreateDataType = {
  name: string
  role: string
}

export type UserEditDataType = {
  name?: string
  email?: string
}

export type UserResponse = {
  userId: string
  email: string
  name: string
  role: RoleType
  canViewBOP: boolean
  canManageBOP: boolean
  tenants: Array<{
    tenantId: number
    name: string
    salesOfficeName: string | undefined
    status: TenantStatusType
  }>
  createdAt?: string
  updatedAt?: string
  updatedByName?: string
}
type DashboardFilterScheduleTypeProps = {
  scheduleTypeId: number
  isFilteredInSummary: boolean
  isFilteredInEachWorkspace: boolean
}
export type DashboardFilterTypeProps = {
  workspaceId: number
  scheduleTypes: DashboardFilterScheduleTypeProps[]
}
export type DashboardFilterProps = {
  workspaces: DashboardFilterTypeProps[]
}

type DashboardFilterScheduleType = DashboardFilterScheduleTypeProps & {
  scheduleTypeName: string
}
export type DashboardFilterType = {
  workspaceName: string
  workspaceId: number
  scheduleTypes: DashboardFilterScheduleType[]
}
export type DashboardFilterResponse = {
  workspaces: DashboardFilterType[]
}

export type UserListResponse = {
  users: UserResponse[]
}

export type CreateUserProps = {
  email: string
  role: RoleType
}

export type UpdateUserProps = {
  name: string
  email: string
}

export type UpdateUserPasswordProps = {
  oldPassword: string
  newPassword: string
}

export type RequestPasswordResetProps = {
  email: string
}

export type ConfirmPasswordResetProps = {
  email: string
  code: string
  password: string
}

export type UpdateUserAuthorizationProps = {
  role?: RoleType
  canViewBOP?: boolean
  canManageBOP?: boolean
}

export const getUserList = (params: CommonParams): Promise<UserListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createUser = (params: CommonParams, data: CreateUserProps): Promise<CreateUserProps> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateUserRole = (params: CommonParams, userId: string, role: RoleType): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/${userId}/role`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { role, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateUserAuthorization = (
  params: CommonParams,
  userId: string,
  data: UpdateUserAuthorizationProps
): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/${userId}/authorization`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateUser = (params: CommonParams, data: UpdateUserProps): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/${params.userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateUserPassword = (params: CommonParams, data: UpdateUserPasswordProps): Promise<UserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/${params.userId}/password`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteUser = (params: CommonParams, userId: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/${userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers, params: { updatedBy: params.updatedBy } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const requestPasswordReset = (data: RequestPasswordResetProps): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/request_password_reset`
    axios
      .post(url, data)
      .then(() => resolve())
      .catch(error => reject(error))
  })

export const confirmPasswordReset = (data: ConfirmPasswordResetProps): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/confirm_password_reset`
    axios
      .post(url, data)
      .then(() => resolve())
      .catch(error => reject(error))
  })

export const getDashboardFilter = (params: CommonParams): Promise<DashboardFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/${params.userId}/tenants/${params.tenantId}/dashboard_filter`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
export const updateDashboardFilter = (
  params: CommonParams,
  data: DashboardFilterProps
): Promise<DashboardFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/users/${params.userId}/tenants/${params.tenantId}/dashboard_filter`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
