import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Input, Label, Row } from 'reactstrap'

import { TenantStatus } from 'api/tenants'
import type { TenantUserListData, TenantUserData, EditManagedWorkspaces, TenantUserResponse } from 'api/tenants'
import { Role } from 'api/users'
import type { UserResponse } from 'api/users'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { showSuccess, showError } from 'slices/notificationSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenantUserList, getTenantUser, selectTenantsStatus, updateUserPermission } from 'slices/tenantsSlice'
import { getUserList, selectUsersStatus, clearErrorMessage } from 'slices/usersSlice'

import { NavMenu, List, VerticalTable, Table, CardRadioButton, CardSubmitFooter } from 'components/common'
import type {
  ListItem,
  TableHeaderType,
  TableCellType,
  VerticalItem,
  CardRadioButtonItem,
} from 'components/common/types'
import { accountTypeName } from 'components/common/utils'

import useUser from 'hooks/useUser'
import type { EditPermissionProps } from 'hooks/useUser'

import UserDelete from './UserDelete'
import UserInvitation from './UserInvitation'

import styles from './Users.module.scss'

const Users: React.FC = () => {
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openInvite, setOpenInvite] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)
  const [initEditPermissionData, setInitEditPermissionData] = React.useState<EditPermissionProps | undefined>()
  const {
    user: { userId, role, tenants },
  } = useSelector(selectSessionStatus, shallowEqual)
  const isTenantAdmin = React.useMemo(() => role === Role.TenantAdmin, [role])
  const isProcessAdmin = React.useMemo(() => role === Role.ProcessAdmin, [role])

  const dispatch = useDispatch()

  const {
    editPermissionData,
    setEditPermissionData,
    disabled,
    bopManagementChangeBoxItems,
    managedWorkspaceCheckBoxItems,
  } = useUser()

  React.useEffect(() => {
    if (isTenantAdmin) {
      dispatch(getUserList())
    } else if (tenants[0]?.tenantId) {
      dispatch(getTenantUserList(tenants[0].tenantId))
    }
  }, [dispatch, tenants, isTenantAdmin])

  const allUsers = useSelector(selectUsersStatus, shallowEqual)
  const tenantUsers = useSelector(selectTenantsStatus, shallowEqual)
  const users = React.useMemo(
    () => (isTenantAdmin ? allUsers.users : tenantUsers.users),
    [isTenantAdmin, allUsers, tenantUsers]
  )

  React.useEffect(() => {
    if (!selectedId || isTenantAdmin) {
      return
    }
    dispatch(getTenantUser(selectedId))
  }, [dispatch, isTenantAdmin, selectedId])

  React.useEffect(() => {
    if (!submitted || allUsers.isRequesting || tenantUsers.isRequesting) {
      return
    }
    if (isTenantAdmin) {
      if (allUsers.errorMessage === '') {
        dispatch(showSuccess())
      } else {
        if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(allUsers.errorMessage)) {
          dispatch(showError())
        }
        dispatch(clearErrorMessage())
      }
    } else {
      if (tenantUsers.errorMessage === '') {
        dispatch(showSuccess())
      } else {
        if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(tenantUsers.errorMessage)) {
          dispatch(showError())
        }
        dispatch(clearErrorMessage())
      }
      dispatch(getTenantUser(selectedId))
    }
    setSubmitted(false)
  }, [submitted, allUsers, tenantUsers, dispatch, tenants, isTenantAdmin, selectedId])

  const listItems: ListItem[] = React.useMemo(() => {
    if (isTenantAdmin) {
      return (users as UserResponse[])
        .filter(user => user.role === Role.TenantAdmin)
        .map(user => ({ id: user.userId, title: user.name }))
    }

    return (users as TenantUserListData[])
      .filter(user => user.role === Role.Admin || user.role === Role.ProcessAdmin)
      .map(user => ({ id: user.id, title: user.name, data: accountTypeName(user.role) }))
  }, [users, isTenantAdmin])

  React.useEffect(() => setSelectedId(prev => prev || (listItems[0]?.id as string)), [listItems])

  const selectedUser: TenantUserResponse | UserResponse | undefined = React.useMemo(() => {
    if (isTenantAdmin) {
      return allUsers.users.find(user => selectedId === user.userId)
    }

    if (!tenantUsers.user) {
      return
    }

    return {
      ...tenantUsers.user.users[0],
      updatedAt: tenantUsers.user.updatedAt,
      updatedByName: tenantUsers.user.updatedByName,
    }
  }, [isTenantAdmin, tenantUsers.user, allUsers.users, selectedId])

  const isReadOnly = React.useMemo(
    () => (editPermissionData ? editPermissionData.role === Role.Admin : true),
    [editPermissionData]
  )

  const updatePermissionData = React.useCallback(
    (userData: TenantUserData | undefined) => {
      const newEditData = userData && {
        role: userData.role,
        canViewBOP: userData.canViewBOP,
        canManageBOP: userData.canManageBOP,
        managedWorkspaces: userData.managedWorkspaces,
      }

      setEditPermissionData(newEditData)
      setInitEditPermissionData(newEditData)
    },
    [setEditPermissionData, setInitEditPermissionData]
  )

  React.useEffect(() => {
    if (!selectedUser || isTenantAdmin) {
      return
    }

    updatePermissionData(selectedUser as TenantUserData)
  }, [isTenantAdmin, selectedUser, updatePermissionData])

  const unchangedUserPermission = React.useMemo(
    () => _.isEqual(initEditPermissionData, editPermissionData),
    [initEditPermissionData, editPermissionData]
  )

  const handleDelete = () => {
    setOpenDelete(false)
    dispatch(showSuccess())
    if (isTenantAdmin) {
      dispatch(getUserList())
    } else {
      dispatch(getTenantUserList(tenants[0]!.tenantId))
    }
  }

  const userInformationItems: VerticalItem[] = React.useMemo(() => {
    const items = [
      { title: '名前', data: selectedUser?.name },
      { title: 'メールアドレス', data: selectedUser?.email },
    ]

    return items
  }, [selectedUser])

  const accountTypeItems: VerticalItem[] = React.useMemo(() => {
    if (!selectedUser) {
      return []
    }

    const items = isProcessAdmin
      ? [{ title: 'アカウントタイプ', data: selectedUser.role === Role.Admin ? 'オーナー' : 'オペレーター' }]
      : []

    return items
  }, [isProcessAdmin, selectedUser])

  const header: TableHeaderType[] = [
    { value: 'テナント ID', width: '20%' },
    { value: '企業名', width: '30%' },
    { value: '事業所名', width: '40%' },
    { value: 'ご利用状況', width: '10%' },
  ]

  const tableItems: TableCellType[][] = React.useMemo(
    () =>
      selectedUser?.tenants.map(tenant => [
        { value: tenant.tenantId },
        { value: tenant.name },
        { value: tenant.salesOfficeName || '-' },
        {
          value: tenant.status === TenantStatus.Active ? '利用中' : '停止中',
          className: tenant.status === TenantStatus.Active ? 'text-success' : 'text-danger',
        },
      ]) || [],
    [selectedUser]
  )

  const navigate = useNavigate()
  const handleRowClick = (index: number) => {
    const tenantId = tableItems[index][0].value
    navigate(`/tenants/${tenantId}/detail`)
  }

  const radioButtonItems: CardRadioButtonItem[] = [
    {
      value: Role.Admin,
      label: accountTypeName(Role.Admin),
      subtitle: '全てのワークスペースの管理、ユーザーの招待/削除が行える管理者アカウント',
    },
    {
      value: Role.ProcessAdmin,
      label: accountTypeName(Role.ProcessAdmin),
      subtitle: '特定のワークスペースの管理が行える利用者アカウント',
    },
  ]

  const onRadioButtonClick = React.useCallback(
    (value: string) => {
      const isAdmin = value === Role.Admin

      setEditPermissionData(prev => {
        if (!prev) {
          return prev
        }

        const newManagedWorkspaces = prev.managedWorkspaces.map(item => ({
          ...item,
          isManagement: isAdmin,
        }))

        return {
          role: isAdmin ? Role.Admin : Role.ProcessAdmin,
          canViewBOP: isAdmin,
          canManageBOP: isAdmin,
          managedWorkspaces: newManagedWorkspaces,
        }
      })
    },
    [setEditPermissionData]
  )

  const getUpdateUserPermissionData = React.useCallback(() => {
    if (unchangedUserPermission || !initEditPermissionData) {
      return null
    }

    const newPermissionData = _.omitBy(editPermissionData, (value, key) =>
      _.isEqual(value, initEditPermissionData[key as keyof EditPermissionProps])
    )
    if (newPermissionData.managedWorkspaces) {
      newPermissionData.managedWorkspaces = (newPermissionData.managedWorkspaces as EditManagedWorkspaces[]).map(item =>
        _.pick(item, ['id', 'isManagement'])
      )
    }

    return { permission: newPermissionData }
  }, [unchangedUserPermission, editPermissionData, initEditPermissionData])

  const onSubmit = React.useCallback(() => {
    if (!editPermissionData) {
      return
    }

    const updateUserPermissionData = getUpdateUserPermissionData()
    setSubmitted(true)
    dispatch(updateUserPermission(selectedId, updateUserPermissionData!))
  }, [editPermissionData, getUpdateUserPermissionData, dispatch, selectedId])

  const onCancel = React.useCallback(() => {
    // テナント管理者の場合は、キャンセルボタンがないためonCancelは実行されない
    if (isTenantAdmin) {
      return
    }

    selectedUser && updatePermissionData(selectedUser as TenantUserData)
  }, [isTenantAdmin, selectedUser, updatePermissionData])

  const onManagedWorkspaceCheckboxChange = React.useCallback(
    (id: number, checked: boolean) => {
      setEditPermissionData(prev => {
        if (!prev) {
          return prev
        }

        const newManagedWorkspaces = prev.managedWorkspaces.map(item =>
          item.id === id ? { ...item, isManagement: checked } : item
        )

        return {
          ...prev,
          managedWorkspaces: newManagedWorkspaces,
        }
      })
    },
    [setEditPermissionData]
  )

  const onBopManagementCheckboxChange = React.useCallback(
    (index: number, checked: boolean) => {
      index === 0
        ? setEditPermissionData(prev => prev && { ...prev, canViewBOP: checked })
        : setEditPermissionData(prev => prev && { ...prev, canManageBOP: checked })
    },
    [setEditPermissionData]
  )

  return (
    <>
      <NavMenu>
        <div className="mt-3 mx-3">
          <div className="mb-3">
            <div className="d-flex justify-content-between">
              <div className="font-x-large fw-bold align-self-center">ユーザー一覧</div>
              {!isProcessAdmin && (
                <div className="bg-white rounded">
                  <Button color="primary" className="d-flex align-items-center" onClick={() => setOpenInvite(true)}>
                    <i className="icf-plus pe-2 font-large" />
                    <div className="ps-1">ユーザーの追加</div>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Row className={isProcessAdmin ? styles.row : styles.rowNoButton}>
            <Col md={4} className="h-100">
              <Card className={`position-sticky ${styles.list}`}>
                <List
                  items={listItems}
                  selectedId={selectedId}
                  onAction={((id: string) => setSelectedId(id)) as (selected: string | number) => void}
                />
              </Card>
            </Col>
            <Col md={8} className="h-100">
              <Card className="h-100">
                <div className="h-100 overflow-auto">
                  <CardBody>
                    <CardTitle className="font-large fw-bold">ユーザー詳細</CardTitle>
                    <Row className="my-2 mx-0">
                      <Col className="border-top border-end border-start p-0">
                        <VerticalTable items={userInformationItems} titleColumn={3} />
                      </Col>
                    </Row>
                  </CardBody>

                  {!isTenantAdmin && (
                    <>
                      <CardBody>
                        <CardTitle className="font-large fw-bold">アカウントタイプ</CardTitle>
                        {isProcessAdmin ? (
                          <Row className="my-2 mx-0">
                            <Col className="border-top border-end border-start">
                              <VerticalTable items={accountTypeItems} titleColumn={3} />
                            </Col>
                          </Row>
                        ) : (
                          <Card>
                            <CardBody>
                              <CardRadioButton
                                items={radioButtonItems}
                                name="userAuthority"
                                selected={editPermissionData?.role || Role.ProcessAdmin}
                                onClick={onRadioButtonClick}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">管理権限設定</CardTitle>
                        <Card>
                          <CardBody>
                            <CardTitle className="font-large fw-bold">ワークスペース管理</CardTitle>
                            <Row md={2} className="ms-1">
                              {!_.isEmpty(managedWorkspaceCheckBoxItems) &&
                                managedWorkspaceCheckBoxItems.map(item => {
                                  return (
                                    <div className="form-check" key={item.id}>
                                      <Input
                                        className="form-check-input"
                                        id={`${item.id}_checkBox`}
                                        checked={item.checked}
                                        type="checkbox"
                                        onChange={e => onManagedWorkspaceCheckboxChange(item.id, e.target.checked)}
                                        disabled={isProcessAdmin || isReadOnly}
                                      />
                                      <Label className="form-check-label" for={`${item.id}_checkBox`}>
                                        {item.label}
                                      </Label>
                                    </div>
                                  )
                                })}
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="mt-3">
                          <CardBody>
                            <CardTitle className="font-large fw-bold">収支管理</CardTitle>
                            <Row md={2} className="ms-1">
                              {!_.isEmpty(bopManagementChangeBoxItems) &&
                                bopManagementChangeBoxItems.map((item, index) => {
                                  return (
                                    <div className="form-check" key={item.id}>
                                      <Input
                                        className="form-check-input"
                                        id={`${item.id}_checkBox`}
                                        checked={item.checked}
                                        type="checkbox"
                                        onChange={e => onBopManagementCheckboxChange(index, e.target.checked)}
                                        disabled={isProcessAdmin || isReadOnly}
                                      />
                                      <Label className="form-check-label" for={`${item.id}_checkBox`}>
                                        {item.label}
                                      </Label>
                                    </div>
                                  )
                                })}
                            </Row>
                          </CardBody>
                        </Card>
                      </CardBody>
                    </>
                  )}
                  {isTenantAdmin && tableItems.length > 0 && (
                    <CardBody>
                      <CardTitle className="font-large fw-bold">担当テナント</CardTitle>
                      <Container className="px-0">
                        <Table header={header} body={tableItems} onRowClick={handleRowClick} />
                      </Container>
                    </CardBody>
                  )}

                  {userId !== selectedId && !isProcessAdmin && (
                    <CardBody>
                      <CardTitle className="font-large fw-bold">ユーザーアカウント削除</CardTitle>
                      <CardText className="py-2 mb-0">
                        ユーザーアカウントを削除すると、アカウント情報などはすべて失われ、復旧できません。
                      </CardText>

                      <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                        このユーザーを削除
                      </Button>
                    </CardBody>
                  )}
                </div>

                <CardSubmitFooter
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                  submitDisabled={unchangedUserPermission || disabled}
                  cancelDisabled={unchangedUserPermission}
                  updatedBy={selectedUser?.updatedByName}
                  updatedAt={selectedUser?.updatedAt}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </NavMenu>

      <UserDelete
        isOpen={openDelete}
        userId={selectedId}
        onSuccess={handleDelete}
        onCancel={() => setOpenDelete(false)}
      />
      <UserInvitation
        isOpen={openInvite}
        onClose={() => setOpenInvite(false)}
        onSuccess={() => dispatch(showSuccess())}
      />
    </>
  )
}

export default Users
