import axios from 'axios'

import type { CommonParams } from 'slices/utils'

export const allocationTypes = {
  Manual: 'manual',
  Auto: 'auto',
} as const

export type AllocationType = (typeof allocationTypes)[keyof typeof allocationTypes]

export type AllocationResponse = {
  workDate: string
  allocations: AllocationData[]
}

export type AllocationListResponse = {
  workDate: string
  allocations: AllocationListData[]
}

export type EditAllocationProps = {
  name?: string
  scheduleTypeId: number | null
  scheduleTypeName: string | null
  type: string
  keys: EditKeyScheduleType[]
  remainderScheduleTypeId: number | null
}

export type UpdateAllocationProps = {
  workDate?: string
  allocation: {
    name?: string
    scheduleTypeId?: number | null
    scheduleTypeName?: string | null
    type?: string
    keys?: KeyScheduleType[]
    remainderScheduleTypeId?: number | null
  }
}

type AllocationListData = {
  id: number
  name: string
  scheduleTypeId: number
  keyNames: string[]
  isError: boolean
}

export type AllocationData = {
  workDate: string
  revision: number
  id: number
  name: string
  scheduleTypeId: number | null
  scheduleTypeName: string
  type: AllocationType
  keys: KeyScheduleType[]
  remainderScheduleTypeId: number | null
  updatedAt: string
  updatedByName: string
}

type KeyScheduleType = {
  scheduleTypeId: number
  ratio?: number
}

export type EditKeyScheduleType = {
  scheduleTypeId: number
  ratio: string
}

export const getAllocationList = (params: CommonParams, workspaceId: number): Promise<AllocationListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getAllocation = (
  params: CommonParams,
  workspaceId: number,
  allocationId: number
): Promise<AllocationResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations/${allocationId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createAllocation = (
  params: CommonParams,
  workspaceId: number,
  data: UpdateAllocationProps
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateAllocation = (
  params: CommonParams,
  workspaceId: number,
  allocationId: number,
  data: UpdateAllocationProps
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations/${allocationId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...data }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteAllocation = (params: CommonParams, workspaceId: number, allocationId: number): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/allocations/${allocationId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
