import * as React from 'react'
import { Row, Col, FormText, Input, InputGroup, Label, FormFeedback } from 'reactstrap'

import { ColorPicker } from 'components/common/ColorPicker/ColorPicker'
import { SelectBox } from 'components/common/FormFormat/SelectBox'
import type { ColorType, ColumnSize } from 'components/common/types'
import { getUUID, ColumnSizes } from 'components/common/utils'

type FormProps = {
  label: string | JSX.Element
  labelSize?: ColumnSize
  size?: ColumnSize
  placeholder?: string
  formText?: string | JSX.Element
  className?: string
}

type InputFormProps = FormProps & {
  value?: string
  type?: 'text' | 'email' | 'password'
  maxLength?: number
  disabled?: boolean
  onChange?: (value: string) => void
  validations?: Array<(s: string | undefined) => string>
  onValidate?: (b: boolean) => void
}

type SelectBoxFormProps = FormProps & {
  value?: string
  items: Array<{ key?: string | number; value: string }>
  disabled?: boolean
  onChange: (item: { key?: string | number; value: string }) => void
  validations?: Array<(s: string | undefined) => string>
  onValidate?: (b: boolean) => void
}

type CheckBoxFormProps = FormProps & {
  checkboxLabel?: string
  checked: boolean
  disabled?: boolean
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

type ColorPickerFormProps = FormProps & {
  color: ColorType
  disabled?: boolean
  onChange: (value: ColorType) => void
}

type InputGroupFormProps = FormProps & {
  value?: string
  addonText: string
  position?: 'append' | 'prepend'
  maxLength?: number
  disabled?: boolean
  onChange: (value: string) => void
  validations?: Array<(s: string | undefined) => string>
  onValidate?: (b: boolean) => void
  backgroundColor?: string
  onFocus?: () => void
  onBlur?: () => void
}

export const InputFormat: React.FC<InputFormProps> = ({
  label,
  value,
  labelSize = ColumnSizes.short,
  size = ColumnSizes.large,
  type = 'text',
  maxLength,
  disabled,
  placeholder,
  formText,
  className,
  onChange,
  validations,
  onValidate,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>('')
  const invalid = React.useCallback(
    (inputValue: string | undefined) => validations?.find(validation => validation(inputValue))?.(inputValue),
    [validations]
  )

  React.useEffect(() => {
    setErrorMessage(invalid(value))
  }, [invalid, value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) =>
    onChange?.(e.target.value)

  const id = React.useMemo(() => `id-${getUUID()}`, [])

  // 直接onValidateを実行すると親のrender中に子コンポーネントから状態を変えることになりwarningが出る
  React.useEffect(() => onValidate?.(!errorMessage), [onValidate, value, errorMessage])
  return (
    <>
      <div className={`row ${className}`}>
        <Label for={id} md={labelSize}>
          {label}
        </Label>
        <Col md={size} className="align-self-center">
          <Input
            value={value || ''}
            invalid={!!errorMessage}
            type={type}
            maxLength={maxLength}
            disabled={disabled}
            id={id}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleChange}
          />
          <FormText color="muted" hidden={!formText}>
            {formText}
          </FormText>
          <FormFeedback hidden={!errorMessage}>{errorMessage}</FormFeedback>
        </Col>
      </div>
    </>
  )
}

export const SelectBoxFormat: React.FC<SelectBoxFormProps> = ({
  label,
  items,
  size = ColumnSizes.large,
  placeholder,
  formText,
  className,
  value = '',
  disabled,
  onChange,
  validations,
  onValidate,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)
  const invalid = React.useCallback(
    (inputValue: string | undefined) => validations?.find(validation => validation(inputValue))?.(inputValue),
    [validations]
  )

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const str = event.target.value !== placeholder ? event.target.value : ''
    setErrorMessage(invalid(str))
  }

  const id = React.useMemo(() => `id-${getUUID()}`, [])
  const defaultValue = React.useMemo(() => {
    const target = items.find(item => (item?.key ? item.key.toString() === value : item.value === value))
    return target ? target.value : placeholder
  }, [items, placeholder, value])

  React.useEffect(() => {
    if (value !== '' && defaultValue === placeholder) {
      // required指定・初期値指定ありかつplaceholderのとき用
      setErrorMessage(invalid(''))
    } else if (value !== '') {
      // valueが更新されたとき用
      setErrorMessage(invalid(value))
    }
  }, [defaultValue, placeholder, invalid, value])

  // 直接onValidateを実行すると親のrender中に子コンポーネントから状態を変えることになりwarningが出る
  React.useEffect(() => onValidate?.(!errorMessage), [onValidate, errorMessage])

  return (
    <>
      <div className={`row ${className}`}>
        <Label for={id} md={4}>
          {label}
        </Label>
        <Col md={size}>
          <SelectBox
            id={id}
            items={items}
            value={defaultValue}
            invalid={!!errorMessage}
            placeholder={placeholder}
            onSelect={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
          <div className="pt-1 text-muted font-x-small" style={{ display: formText ? '' : 'none' }}>
            {formText}
          </div>
          <div className="pt-1 text-danger font-x-small" style={{ display: errorMessage ? '' : 'none' }}>
            {errorMessage}
          </div>
        </Col>
      </div>
    </>
  )
}

export const CheckBoxFormat: React.FC<CheckBoxFormProps> = ({
  label,
  checkboxLabel,
  checked,
  formText,
  className,
  disabled,
  onChange,
}) => {
  const id = React.useMemo(() => `id-${getUUID()}`, [])
  return (
    <>
      <div className={`row ${className}`}>
        <Col md={4}>{label}</Col>
        <Col md={4}>
          <div className="form-check">
            <Input
              className="form-check-input"
              checked={checked}
              type="checkbox"
              disabled={disabled}
              id={id}
              onChange={onChange}
            />
            <Label className="form-check-label" for={id}>
              {checkboxLabel}
            </Label>
          </div>
          <FormText color="muted" hidden={!formText}>
            {formText}
          </FormText>
        </Col>
      </div>
    </>
  )
}

export const ColorPickerFormat: React.FC<ColorPickerFormProps> = ({
  label,
  color,
  size = ColumnSizes.large,
  disabled,
  onChange,
}) => {
  const id = React.useMemo(() => `id-${getUUID()}`, [])
  return (
    <Row>
      <Label for={id} md={4}>
        {label}
      </Label>
      <Col md={size} id={id}>
        <ColorPicker selected={color} disabled={disabled} onChange={onChange} />
      </Col>
    </Row>
  )
}

export const InputGroupFormat: React.FC<InputGroupFormProps> = ({
  label,
  labelSize = ColumnSizes.short,
  value,
  addonText,
  position = 'append',
  size = ColumnSizes.short,
  maxLength,
  formText,
  disabled,
  onChange,
  validations,
  onValidate,
  backgroundColor,
  onFocus,
  onBlur,
  className,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>('')
  const invalid = React.useCallback(
    (inputValue: string | undefined) => validations?.find(validation => validation(inputValue))?.(inputValue),
    [validations]
  )

  React.useEffect(() => {
    setErrorMessage(invalid(value))
  }, [invalid, value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const id = React.useMemo(() => `id-${getUUID()}`, [])
  const style = { backgroundColor: backgroundColor || '#fff' }

  // 直接onValidateを実行すると親のrender中に子コンポーネントから状態を変えることになりwarningが出る
  React.useEffect(() => onValidate?.(!errorMessage), [onValidate, value, errorMessage])
  return (
    <>
      <div className={`row ${className}`}>
        <Label for={id} md={labelSize}>
          {label}
        </Label>
        <Col md={size}>
          <InputGroup>
            {position === 'prepend' && (
              <div className="input-group-prepend">
                <span className="input-group-text">{addonText}</span>
              </div>
            )}
            <Input
              id={id}
              style={style}
              value={value || ''}
              invalid={!!errorMessage}
              maxLength={maxLength}
              className="form-control"
              disabled={disabled}
              onChange={handleChange}
              onBlur={onBlur ? () => onBlur() : handleChange}
              onFocus={() => onFocus?.()}
            />
            {position === 'append' && (
              <div className={`input-group-text rounded-end bg-body-secondary`}>{addonText}</div>
            )}
            <FormFeedback hidden={!errorMessage}>{errorMessage}</FormFeedback>
          </InputGroup>
          <FormText color="muted" hidden={!formText}>
            {formText}
          </FormText>
        </Col>
      </div>
    </>
  )
}
