import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

/*
必ずworkspaceIdが選択されなければならないコンポーネントで使用する
workspaceIdが選択されていない場合(pathにworkspaceIdが存在しないとき)は、最初のworkspaceを選択する
*/

const useWorkspaceSideBar = (path: string) => {
  const navigate = useNavigate()
  const { workspaceId } = useParams<'workspaceId'>()
  const dispatch = useDispatch()

  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    if (_.isEmpty(workspaces)) {
      return
    }
    if (!workspaceId) {
      navigate(`${path}/${_.sortBy(workspaces, 'name')[0].workspaceId}`, { replace: true })
    }
  }, [workspaceId, navigate, workspaces, path])

  const workspace = React.useMemo(
    () => workspaces.find(w => w.workspaceId === Number(workspaceId)),
    [workspaces, workspaceId]
  )

  const workspaceName = React.useMemo(() => workspace?.name || '', [workspace])

  return { workspaceName, workspaceId, workspace }
}
export default useWorkspaceSideBar
