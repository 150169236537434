import { createSlice } from '@reduxjs/toolkit'

import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'
import * as API from 'api/works'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import type { CommonParams } from 'slices/utils'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type WorksToCompareType = API.WorkResponse & { workspaceId: number }
type WorkState = API.WorkListResponse & {
  isRequesting: boolean
  errorMessage: string
  worksToCompare: WorksToCompareType[]
  scheduleTruncated: boolean
}

const initialState: WorkState = {
  isRequesting: false,
  errorMessage: '',
  works: [],
  worksToCompare: [],
  scheduleTruncated: false,
}

export const worksSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
      state.works = []
    },
    apiFailureUnclearWorks: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkListSuccess: (state, action: PayloadAction<API.WorkListResponse>) => {
      state.isRequesting = false
      state.works = action.payload.works
    },
    getWorkSuccess: (state, action: PayloadAction<API.WorkResponse>) => {
      state.isRequesting = false
      const index = state.works.findIndex(work => work.workId === action.payload.workId)
      state.works.splice(index, 1, action.payload)
    },
    updateWorkSuccess: (state, action: PayloadAction<API.WorkResponse>) => {
      state.isRequesting = false
      const index = state.works.findIndex(work => work.workId === action.payload.workId)
      state.works.splice(index, 1, action.payload)
      state.scheduleTruncated = action.payload.scheduleTruncated
    },
    getWorkByDateSuccess: (state, action: PayloadAction<API.WorkResponse>) => {
      state.isRequesting = false
      state.works = [action.payload]
    },
    getWorkForCompareSuccess: (state, action: PayloadAction<WorksToCompareType>) => {
      state.isRequesting = false
      const index = state.worksToCompare.findIndex(work => work.workId === action.payload.workId)
      const deleteCount = index === -1 ? 0 : 1
      state.worksToCompare.splice(index, deleteCount, action.payload)
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  apiFailureUnclearWorks,
  getWorkListSuccess,
  getWorkSuccess,
  updateWorkSuccess,
  getWorkByDateSuccess,
  getWorkForCompareSuccess,
} = worksSlice.actions

export const getWorkList =
  (workspaceId: number, from?: string, to?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkList(commonParams(getState), workspaceId, from, to)
      .then((res: API.WorkListResponse) => dispatch(getWorkListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWork =
  (workspaceId: number, workId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWork(commonParams(getState), workspaceId, workId)
      .then((res: API.WorkResponse) => dispatch(getWorkSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorkByDate =
  (workspaceId: number, date: string, filterByShift: boolean, shouldClearWorksWhenError: boolean = false): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkByDate(commonParams(getState), workspaceId, date, filterByShift)
      .then((res: API.WorkResponse) => dispatch(getWorkByDateSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (shouldClearWorksWhenError) {
          dispatch(apiFailure({ errorMessage: errorCode }))
        } else {
          // 作業計画画面から別の日付に移動する際に選択先の日付が存在しない場合､ エラー処理が実行される
          // works が空になると作業計画画面が nodata になるのでここでは getWorkByDate がエラーでも works を空にしない
          dispatch(apiFailureUnclearWorks({ errorMessage: errorCode }))
        }
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorkForCompare =
  (workspaceId: number, date: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkByDate(commonParams(getState), workspaceId, date, false)
      .then((res: API.WorkResponse) => dispatch(getWorkForCompareSuccess({ ...res, workspaceId })))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(apiFailureUnclearWorks({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateWork =
  (workspaceId: number, workId: number, data: API.UpdateWorkType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    dispatch(Spinner.start())

    try {
      const { requestId } = await API.updateWorkAsync(commonParams(getState), workspaceId, workId, data)

      if (!requestId) {
        return
      }

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const workResponse = await API.getWorkAsync(commonParams(getState), workspaceId, workId, requestId)
        if (workResponse?.workId) {
          return dispatch(updateWorkSuccess(workResponse))
        }
      }
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

const updatePerformanceRate = async (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  data: API.UpdatePerformanceRateType
) => {
  if (!data || !workId) {
    return
  }
  const { requestId } = await API.updatePerformanceRateAsync(params, workspaceId, workId, data)
  if (!requestId) {
    return
  }
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const performanceRateResponse = await API.getPerformanceRateAsync(params, workspaceId, workId, requestId)
    if (performanceRateResponse?.workId) {
      return performanceRateResponse
    }
  }
}

export const updatePerformanceRatesAndWork =
  (
    workspaceId: number,
    workId: number,
    workData: API.UpdateWorkType | undefined,
    performanceRatesData: API.UpdatePerformanceRateType | undefined
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    dispatch(Spinner.start())

    try {
      if (performanceRatesData) {
        const performanceRateResponse = await updatePerformanceRate(
          commonParams(getState),
          workspaceId,
          workId,
          performanceRatesData
        )
        if (!workData) {
          performanceRateResponse && dispatch(getWorkSuccess(performanceRateResponse))
          return
        }
      }
      if (!workData) {
        return
      }
      const { requestId } = await API.updateWorkAsync(commonParams(getState), workspaceId, workId, workData)

      if (!requestId) {
        return
      }
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const workResponse = await API.getWorkAsync(commonParams(getState), workspaceId, workId, requestId)
        if (workResponse?.workId) {
          return dispatch(updateWorkSuccess(workResponse))
        }
      }
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailureUnclearWorks({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const updateTargetValues =
  (workspaceId: number, workId: number, data: API.UpdateTargetValuesType, showErrorDialog?: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTargetValues(commonParams(getState), workspaceId, workId, data)
      .then((res: API.WorkResponse) => dispatch(getWorkSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (showErrorDialog) {
          if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
            dispatch(SessionTimeoutDialog.open())
          } else {
            dispatch(NetworkErrorDialog.open({ code: errorCode }))
          }
        }
        dispatch(apiFailureUnclearWorks({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectWorksStatus = (state: RootState) => ({ ...state.works })

export default worksSlice.reducer
