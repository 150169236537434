import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap'

import { Role } from 'api/users'

import { getTenantUserList, getTenantUser, selectTenantsStatus } from 'slices/tenantsSlice'

import AccountTypeChange from 'components/AccountTypeChange/AccountTypeChange'
import { List, VerticalTable } from 'components/common'
import type { VerticalItem } from 'components/common/types'
import { accountTypeName } from 'components/common/utils'

import placeholder from 'images/allEmpty.svg'

import TenantUserDelete from './TenantUserDelete'

import styles from './TenantUsers.module.scss'

type Props = {
  showSuccess: () => void
}

const TenantUsers: React.FC<Props> = props => {
  const { showSuccess } = props
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [accountType, setAccountType] = React.useState('')
  const [openChange, setOpenChange] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState('')

  const params = useParams<'tenantId'>()
  const tenantId = Number(params.tenantId)
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getTenantUserList(tenantId))
  }, [dispatch, tenantId])
  const { users, user } = useSelector(selectTenantsStatus, shallowEqual)

  React.useEffect(() => {
    if (!selectedId) {
      return
    }

    dispatch(getTenantUser(selectedId))
  }, [dispatch, selectedId])

  const listItems = React.useMemo(
    () =>
      users
        .filter(tenantUser => tenantUser.role === Role.Admin || tenantUser.role === Role.ProcessAdmin)
        .map(tenantUser => ({ id: tenantUser.id, title: tenantUser.name })),
    [users]
  )

  React.useEffect(() => setSelectedId(prev => prev || (listItems[0]?.id as string)), [listItems])

  const selectedUser = React.useMemo(() => {
    if (!user) {
      return
    }

    return {
      ...user.users[0],
      updatedAt: user.updatedAt,
      updatedByName: user.updatedByName,
    }
  }, [user])

  React.useEffect(() => {
    setName(selectedUser?.name || '')
    setEmail(selectedUser?.email || '')
    setAccountType(accountTypeName(selectedUser?.role))
  }, [selectedUser])

  const verticalItems: VerticalItem[] = React.useMemo(() => {
    return [
      { title: '名前', data: name },
      { title: 'メールアドレス', data: email },
      { title: 'アカウントタイプ', data: accountType },
    ]
  }, [name, email, accountType])

  const handleAccountTypeChange = () => {
    // AccountTypeChangeではTenantStateの状態が更新されないためtenantを再取得する
    dispatch(getTenantUserList(tenantId))
    setOpenChange(false)
  }

  const handleDeleteUser = () => {
    setOpenDelete(false)
    showSuccess()
  }

  return (
    <>
      <Row className="g-0">
        <Col md={4}>
          <Card className={`position-sticky ${styles.list}`}>
            {listItems.length > 0 ? (
              <List
                items={listItems}
                selectedId={selectedId}
                onAction={((id: string) => setSelectedId(id)) as (selected: string | number) => void}
              />
            ) : (
              <CardBody className="text-center">
                <img
                  className={`mx-auto my-auto d-block h-75 w-100 ${styles.placeholderImage}`}
                  src={placeholder}
                  alt=""
                />
                <div className="font-middle fw-bold py-4">テナントに登録されたユーザーがいません</div>
              </CardBody>
            )}
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            {listItems.length > 0 ? (
              <>
                <CardBody>
                  <CardTitle className="font-large fw-bold">ユーザー詳細</CardTitle>
                  <Row className="my-2 mx-0">
                    <Col className="border-top border-end border-start">
                      <VerticalTable items={verticalItems} titleColumn={3} />
                      <Row className="border-bottom">
                        <Col
                          className="text-center text-primary py-3 bg-bgcolor"
                          role="button"
                          onClick={() => setOpenChange(true)}
                        >
                          アカウントタイプの変更
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

                <CardBody>
                  <CardTitle className="font-large fw-bold">ユーザーアカウント削除</CardTitle>
                  <CardText className="py-2">
                    ユーザーアカウントを削除すると、アカウント情報などはすべて失われ、復旧できません。
                  </CardText>
                  <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                    このユーザーを削除
                  </Button>
                </CardBody>
              </>
            ) : (
              <CardBody className="text-center">
                <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                <div className="font-middle fw-bold py-4">登録ユーザーが選択されていません</div>
                <div>登録ユーザーを選択して、編集しましょう。</div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {selectedUser && (
        <AccountTypeChange
          isOpen={openChange}
          userId={selectedUser.userId}
          role={selectedUser.role}
          onSuccess={handleAccountTypeChange}
          onCancel={() => setOpenChange(false)}
        />
      )}

      <TenantUserDelete
        isOpen={openDelete}
        userId={selectedId}
        onSuccess={handleDeleteUser}
        onCancel={() => setOpenDelete(false)}
      />
    </>
  )
}

export default TenantUsers
