import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Col, Card, CardBody, Button } from 'reactstrap'

import { BadgeLabel } from 'components/common'
import { SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'

import WorkerCard from './WorkerCard'
import WorkerReassign from './WorkerReassign'

import styles from './DroppingWorkerCards.module.scss'

import type { Worker, DropdownScheduleType } from './WorkerCard'
import type { DropResult, DragStart } from 'react-beautiful-dnd'

export type ScheduleEditType = DropdownScheduleType & {
  workers: Worker[]
}

type selectedType = {
  droppableType: string
  ids: string[]
}

type Props = {
  scheduleEditData: ScheduleEditType[]
  otherWorkspaces: DropdownScheduleType[]
  currentTime: string
  isOpenSidebar?: boolean
  setScheduleEditData: (data: ScheduleEditType[]) => void
}

export const PREFIX_WORKSPACE_ID = 'workspace'
export const PREFIX_SCHEDULE_TYPE_ID = 'scheduletype'

const DroppingWorkerCards: React.FC<Props> = props => {
  const { scheduleEditData, otherWorkspaces, currentTime, isOpenSidebar = true, setScheduleEditData } = props
  const [selectedWorkers, setSelectedWorkers] = React.useState<selectedType>({ droppableType: '', ids: [] })
  const [selectedScheduleType, setSelectedScheduleType] = React.useState<DropdownScheduleType>({ type: '', label: '' })
  const [assignWorkers, setAssignWorkers] = React.useState<Worker[]>([])
  const [draggingId, setDraggingId] = React.useState<string | undefined>(undefined)
  const [isOpen, setIsOpen] = React.useState(false)

  const [isUnscheduledWorkerViewOpen, setIsUnscheduledWorkerViewOpen] = React.useState(false)

  const handleClickUnscheduledWorkerViewOpenButton = React.useCallback(() => {
    setIsUnscheduledWorkerViewOpen(prevState => !prevState)
  }, [])

  const schedules: DropdownScheduleType[] = React.useMemo(
    () =>
      scheduleEditData
        .filter(s => s.type !== SHIFT_SCHEDULE_TYPE_ID.toString() && s.color)
        .map(s => _.pick(s, ['type', 'label', 'color', 'skillIds'])),
    [scheduleEditData]
  )

  const date = React.useMemo(() => moment(currentTime, 'HH:mm').format('YYYY/MM/DD HH:mm'), [currentTime])
  React.useEffect(() => setSelectedWorkers({ droppableType: '', ids: [] }), [date, scheduleEditData])

  const onDropDownMenuClick = React.useCallback(
    (schedule: DropdownScheduleType) => {
      setSelectedScheduleType(schedule)
      setAssignWorkers(
        scheduleEditData
          .find(edit => edit.type === selectedWorkers.droppableType)
          ?.workers.filter(worker => selectedWorkers.ids.includes(worker.id)) || []
      )
      setIsOpen(true)
    },
    [setSelectedScheduleType, setAssignWorkers, scheduleEditData, selectedWorkers, setIsOpen]
  )

  const onReassignWorkers = (workers: Worker[]) => {
    const newEditList: ScheduleEditType[] = scheduleEditData.map((edit: ScheduleEditType) => {
      if (edit.type === selectedScheduleType.type) {
        // 移動先にメンバーを追加
        return { ...edit, workers: edit.workers.concat(workers) }
      } else if (edit.type === selectedWorkers.droppableType) {
        // 移動元からメンバーを削除
        const newWorkers = edit.workers.filter(worker => !workers.map(w => w.id).includes(worker.id))
        return { ...edit, workers: newWorkers }
      }
      return edit
    })
    setScheduleEditData(newEditList)
    setIsOpen(false)
  }

  const onDragStart = (start: DragStart) => {
    const { source, draggableId } = start
    const selected = selectedWorkers.ids.find(workerId => workerId === draggableId)
    if (!selected) {
      setSelectedWorkers({ droppableType: source.droppableId, ids: [draggableId] })
    }
    setDraggingId(draggableId)
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    setDraggingId(undefined)

    // 移動元と移動先が同じ時・移動先が「予定未入力」の時は何もしない
    if (
      !destination ||
      source.droppableId === destination.droppableId ||
      destination.droppableId === SHIFT_SCHEDULE_TYPE_ID.toString()
    ) {
      return
    }

    // 移動先がワークスペースの時、応援に来ている作業者を別のワークスペースへ再応援させることは不可とする
    const moveToWorkspace = destination.droppableId.includes(PREFIX_WORKSPACE_ID)
    const filteredWorker =
      scheduleEditData
        .find(edit => edit.type === selectedWorkers.droppableType)
        ?.workers.filter(
          worker => selectedWorkers.ids.includes(worker.id) && (!moveToWorkspace || !worker.supporter)
        ) || []

    // 移動可能メンバーがいるときに処理を実行
    if (filteredWorker.length > 0) {
      const list = schedules.concat(otherWorkspaces)
      setSelectedScheduleType(list.find(data => data.type === destination.droppableId) || { type: '', label: '' })
      setAssignWorkers(filteredWorker)
      setIsOpen(true)
    }
  }

  const workerCardClickHandler = React.useCallback(
    (event: React.MouseEvent, droppableType: string, id: string) => {
      if ((event.target as HTMLElement).tagName !== 'DIV') {
        return
      }

      // 無限レンダリングを回避するためにselectedWorkersを使わずにprevを使うようにしています
      setSelectedWorkers(prev => {
        if (droppableType !== prev.droppableType) {
          return { droppableType, ids: [id] }
        }

        if (event.shiftKey) {
          return {
            droppableType,
            ids: prev.ids.includes(id) ? prev.ids.filter(select => select !== id) : [...prev.ids, id],
          }
        }
        return {
          droppableType,
          ids: prev.ids.includes(id) && prev.ids.length === 1 ? [] : [id],
        }
      })
    },
    [setSelectedWorkers]
  )

  const filteredOtherWorkspaces = React.useCallback(
    (type: string, scheduleId: number | null, supporter: boolean) => {
      const workers = scheduleEditData.flatMap(d => d.workers).filter(d => selectedWorkers.ids.includes(d.id))
      const data = workers.filter(w => !!w.supporter)
      return otherWorkspaces.filter(w => w.type !== type && !supporter && data.length === 0)
    },
    [scheduleEditData, selectedWorkers.ids, otherWorkspaces]
  )

  const sortSchedule = (a: ScheduleEditType, b: ScheduleEditType) => {
    // workersの数でソートする
    const aHasWorkers = a.workers.length > 0
    const bHasWorkers = b.workers.length > 0

    // workersがいる場合は先、いない場合は後に来るようにする
    if (aHasWorkers && !bHasWorkers) {
      return -1
    }
    if (!aHasWorkers && bHasWorkers) {
      return 1
    }

    return 0
  }

  const sortedData = React.useMemo(
    () =>
      scheduleEditData
        .slice()
        .map(s => ({ ...s, workers: s.workers.filter(w => w.visible) }))
        .sort(sortSchedule),
    [scheduleEditData]
  )

  const sortWorker = React.useCallback((workers: Worker[]) => {
    const { grouped, supporters, ungrouped } = workers.reduce(
      (acc, cur) => {
        if (cur.supporter) {
          acc.supporters.push(cur)
          return acc
        }
        if (cur.groupName === '未所属') {
          acc.ungrouped.push(cur)
          return acc
        }
        acc.grouped.push(cur)
        return acc
      },
      { grouped: [], supporters: [], ungrouped: [] } as { grouped: Worker[]; supporters: Worker[]; ungrouped: Worker[] }
    )
    return _.sortBy(grouped, 'groupName').concat(supporters).concat(ungrouped)
  }, [])

  const droppableWorkerCard = React.useCallback(
    (item: ScheduleEditType) => {
      return (
        <Droppable droppableId={item.type}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className={`user-select-none ${snapshot.isDraggingOver && 'bg-primary-pale'}`}>
              {item.workers.length > 0 ? (
                <CardBody className="pt-0 pb-3 px-3">
                  <div className="d-flex flex-wrap">
                    {sortWorker(item.workers).map((worker, index) => (
                      <div
                        key={`worker-card-${worker.id}`}
                        className={`p-1 ${isOpenSidebar ? styles.cardPerSeven : styles.cardPerEight}`}
                      >
                        {snapshot.draggingFromThisWith === worker.id && (
                          <Card className="bg-light-gray" style={{ height: '74px' }}></Card>
                        )}
                        <Draggable key={worker.id} draggableId={worker.id} index={index}>
                          {p => (
                            <div ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps}>
                              <WorkerCard
                                worker={worker}
                                selected={selectedWorkers.ids}
                                dropdownMenu={{
                                  schedules: schedules.filter(s => s.type !== item.type),
                                  otherWorkspaces: filteredOtherWorkspaces(
                                    item.type,
                                    worker.scheduleId,
                                    !!worker.supporter
                                  ),
                                }}
                                draggingId={draggingId}
                                onDropDownMenuClick={onDropDownMenuClick}
                                onClick={(e, id) => workerCardClickHandler(e, item.type, id)}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                  </div>
                </CardBody>
              ) : (
                <CardBody className="text-center text-muted pt-0">現在、誰もこの作業をしていません</CardBody>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )
    },
    [
      selectedWorkers,
      schedules,
      onDropDownMenuClick,
      workerCardClickHandler,
      draggingId,
      filteredOtherWorkspaces,
      sortWorker,
      isOpenSidebar,
    ]
  )

  const unscheduledWorkerViewButton = React.useMemo(() => {
    return (
      <div className={isOpenSidebar ? 'col-md-10' : 'col-md-12'}>
        <Button
          className={`d-flex align-items-center mx-auto text-white rounded-pill ${
            isUnscheduledWorkerViewOpen ? 'mb-2' : 'mb-3'
          }`}
          onClick={handleClickUnscheduledWorkerViewOpenButton}
          color="gray"
          size="sm"
        >
          <i className={`${isUnscheduledWorkerViewOpen ? 'icf-upToDown' : 'icf-downToUp'} pe-2 font-large`} />
          {isUnscheduledWorkerViewOpen ? '閉じる' : '予定未入力'}
        </Button>
      </div>
    )
  }, [handleClickUnscheduledWorkerViewOpenButton, isUnscheduledWorkerViewOpen, isOpenSidebar])

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {sortedData
          .filter(item => item.label !== '予定未入力')
          .map((item: ScheduleEditType) => (
            <Col key={item.type} className="h-100 p-0" md={item.workers.length ? 12 : 4}>
              <Card className="mb-3 ms-3">
                <CardBody className="d-flex">
                  <BadgeLabel label={item.label} color={item.color} />
                </CardBody>
                {droppableWorkerCard(item)}
              </Card>
            </Col>
          ))}
        <div className={`position-fixed w-100 px-0 ${styles.bottomOffset}`}>
          {unscheduledWorkerViewButton}
          {isUnscheduledWorkerViewOpen && (
            <div>
              <div className={`bg-white ${styles.unscheduledSpace} ${isOpenSidebar ? 'col-md-10' : 'col-md-12'}`}>
                {sortedData
                  .filter(item => item.label === '予定未入力')
                  .map((item: ScheduleEditType) => (
                    <Col key={item.type} className="h-100 p-0" md="12">
                      <div className={`mb-3 ${styles.pseudoCard}`}>
                        <CardBody className="d-flex p-3">
                          <BadgeLabel label={item.label} color={item.color} />
                        </CardBody>
                        {droppableWorkerCard(item)}
                      </div>
                    </Col>
                  ))}
              </div>
            </div>
          )}
        </div>
      </DragDropContext>

      <WorkerReassign
        isOpen={isOpen}
        workers={assignWorkers}
        selected={selectedScheduleType}
        currentTime={currentTime}
        onReassignWorkers={onReassignWorkers}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

export default DroppingWorkerCards
