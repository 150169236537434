import moment from 'moment/moment'
import React from 'react'

import useQuery from './useQuery'

const useBopReportsQuery = () => {
  const query = useQuery()

  const getStartDate = () => {
    return moment().day() === 0
      ? moment().subtract(1, 'days').day(1).format('YYYY-MM-DD')
      : moment().day(1).format('YYYY-MM-DD')
  }

  const getEndDate = () => {
    return moment().day() === 0 ? moment().format('YYYY-MM-DD') : moment().day(1).add(6, 'days').format('YYYY-MM-DD')
  }

  const queryStart = React.useMemo(() => query.get('start') || getStartDate(), [query])
  const queryEnd = React.useMemo(() => query.get('end') || getEndDate(), [query])

  return {
    queryStart,
    queryEnd,
  }
}

export default useBopReportsQuery
