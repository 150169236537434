import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/schedule_types'
import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type ScheduleTypeState = API.ScheduleTypeListResponse & {
  allScheduleTypes: API.ScheduleTypeResponse[]
  isRequesting: boolean
  errorMessage: string
}

const initialState: ScheduleTypeState = {
  isRequesting: false,
  errorMessage: '',
  scheduleTypes: [],
  allScheduleTypes: [],
}

export const scheduleTypesSlice = createSlice({
  name: 'scheduleTypes',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getScheduleTypeListSuccess: (state, action: PayloadAction<API.ScheduleTypeListResponse>) => {
      state.isRequesting = false
      state.scheduleTypes = action.payload.scheduleTypes
    },
    createScheduleTypeSuccess: (state, action: PayloadAction<API.ScheduleTypeResponse>) => {
      state.isRequesting = false
      state.scheduleTypes.push(action.payload)
    },
    updateScheduleTypeSuccess: (state, action: PayloadAction<API.ScheduleTypeResponse>) => {
      state.isRequesting = false
      state.scheduleTypes = state.scheduleTypes.map(scheduleType =>
        scheduleType.scheduleTypeId === action.payload.scheduleTypeId ? action.payload : scheduleType
      )
    },
    deleteScheduleTypeSuccess: (state, action: PayloadAction<number>) => {
      state.isRequesting = false
      state.scheduleTypes = state.scheduleTypes.filter(scheduleType => scheduleType.scheduleTypeId !== action.payload)
    },
    getAllScheduleTypesSuccess: (state, action: PayloadAction<API.ScheduleTypeListResponse[]>) => {
      state.isRequesting = false
      state.allScheduleTypes = action.payload.flatMap(s => s.scheduleTypes)
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getScheduleTypeListSuccess,
  createScheduleTypeSuccess,
  updateScheduleTypeSuccess,
  deleteScheduleTypeSuccess,
  getAllScheduleTypesSuccess,
} = scheduleTypesSlice.actions

export const getScheduleTypeList =
  (workspaceId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getScheduleTypeList(commonParams(getState), workspaceId)
      .then((res: API.ScheduleTypeListResponse) => dispatch(getScheduleTypeListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createScheduleType =
  (workspaceId: number, data: API.ScheduleTypeEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createScheduleType(commonParams(getState), workspaceId, data)
      .then((res: API.ScheduleTypeResponse) => dispatch(createScheduleTypeSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateScheduleType =
  (workspaceId: number, scheduleTypeId: number, data: API.ScheduleTypeEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateScheduleType(commonParams(getState), workspaceId, scheduleTypeId, data)
      .then((res: API.ScheduleTypeResponse) => dispatch(updateScheduleTypeSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteScheduleType =
  (workspaceId: number, scheduleTypeId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteScheduleType(commonParams(getState), workspaceId, scheduleTypeId)
      .then(() => dispatch(deleteScheduleTypeSuccess(scheduleTypeId)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const getAllScheduleTypes =
  (workspaceIds: number[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    const promises: Promise<API.ScheduleTypeListResponse>[] = workspaceIds.map(workspaceId =>
      API.getScheduleTypeList(commonParams(getState), workspaceId)
    )

    await Promise.all(promises)
      .then((res: API.ScheduleTypeListResponse[]) => dispatch(getAllScheduleTypesSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectScheduleTypesStatus = (state: RootState) => ({ ...state.scheduleTypes })

export default scheduleTypesSlice.reducer
