import axios from 'axios'

import type { CreateUserProps, RoleType } from 'api/users'

import type { CommonParams } from 'slices/utils'

export const TenantStatus = {
  Active: 'active',
  Stopped: 'stopped',
} as const
export type TenantStatusType = (typeof TenantStatus)[keyof typeof TenantStatus]

export type TenantStatusResponse = {
  status: TenantStatusType
}

export type TenantApplicationSettingType = {
  applicationId: number
  applicationName: string
  options: { apiKey: string; tenant: string; relatedWorkspaceData: { location: string; relatedWorkspaceId: number }[] }
}

type TenantBaseDataType = {
  name: string
  salesOfficeName: string
  postalCode: string
  prefecture: string
  municipality: string
  otherAddress: string
  phoneNumber: string
  personInCharge: string
}

export type TenantEditDataType = TenantBaseDataType & {
  businessStartHour: string
  businessStartMinute: string
  businessEndHour: string
  businessEndMinute: string
}

type TenantEditRequestType = TenantBaseDataType & {
  businessStartTime: string
  businessEndTime: string
}

export type TenantResponse = TenantStatusResponse & {
  tenantId: number
  name: string
  salesOfficeName: string | null
  postalCode: string | null
  prefecture: string | null
  municipality: string | null
  otherAddress: string | null
  phoneNumber: string | null
  personInCharge: string | null
  businessStartTime: string
  businessEndTime: string
  applications: TenantApplicationSettingType[]
  startedAt: string | null
  endedAt: string | null
  createdAt: string
  updatedBy: string
  updatedAt: string
}

export type TenantListResponse = {
  tenants: TenantResponse[]
}

export type APIStatusResponse = {
  tenantId: number
  lastCountedAt: string
  data: APIStatusValue[]
}

export type APIStatusValue = {
  date: string
  count: number
}

export type DataConnectionInfoResponse = {
  data: DataConnectionInfo[]
}

export type DataConnectionInfo = {
  targetDatabase: {
    databaseName: string
    displayName: string
  }
  targetColumns: TargetColumn[]
  filterColumns: FilterColumn[]
}

export type TargetColumn = {
  columnName: string
  displayName: string
}

export type FilterColumn = {
  columnName: string
  displayName: string
  values: string[]
}

export type ForecastProductivityResponse = {
  workspaces: ForecastProductivity[]
}

export type ForecastProductivity = {
  workspaceId: number
  workspaceName: string
  scheduleTypes: ScheduleTypeForecastProductivity[]
}

export type ScheduleTypeForecastProductivity = {
  scheduleTypeId: number
  scheduleTypeName: string
  productivity: number | null
}

export const ExportDataTypes = {
  PlanAndRecord: 'planAndRecord',
  PlanDetail: 'planDetail',
} as const

export type ExportDataType = (typeof ExportDataTypes)[keyof typeof ExportDataTypes]

export type CreateExportDataInfo = {
  startDate: string
  endDate: string
  targetWorkspaces: number[]
  includeProductivityValue: boolean
  includeRecord?: boolean
  exportDataType: ExportDataType
}

type CreateExportDataResponse = {
  requestId: string
}

export type CheckExportDataReadyResponse = {
  downloadUrl: string
}

export type TenantUserListResponse = {
  users: TenantUserListData[]
}

export type TenantUserListData = {
  id: string
  name: string
  role: RoleType
}

export type TenantUserResponse = {
  users: TenantUserData[]
  updatedAt?: string
  updatedByName?: string
}

export type TenantUserData = {
  userId: string
  email: string
  name: string
  role: RoleType
  canViewBOP: boolean
  canManageBOP: boolean
  tenants: Array<{
    tenantId: number
    name: string
    salesOfficeName: string | undefined
    status: TenantStatusType
  }>
  managedWorkspaces: ManagedWorkspaces[]
}

export type UserManagedWorkspacesResponse = {
  workDate: string
  managedWorkspaces: ManagedWorkspaces[]
  updatedAt: string
  updatedByName: string
}

export type UpdateUserManagedWorkspacesResponse = {
  id: number
  revision: number
  workDate: string
  updatedAt: string
  updatedByName: string
}

export type ManagedWorkspaces = {
  id: number
  name: string
  isManagement: boolean
  revision: number
}

export type EditManagedWorkspaces = {
  id: number
  isManagement: boolean
  name?: string
}

export type EditUserManagedWorkspacesProps = {
  workDate: string
  managedWorkspaces: EditManagedWorkspaces[]
}

export type EditUserPermissionProps = {
  role?: RoleType
  canViewBOP?: boolean
  canManageBOP?: boolean
  managedWorkspaces?: EditManagedWorkspaces[]
}

export type UpdateUserPermissionProps = {
  permission: EditUserPermissionProps
}

export type UpdateUserPermissionResponse = {
  id: number
  updatedAt: string
  updatedByName: string
}

type DashboardDisplayFilterWorkspaces = {
  id: number
  scheduleTypes: Array<{
    id: number
    isFilteredInSummary: boolean
    isFilteredInEachWorkspace: boolean
  }>
}

type BopDashboardDisplayFilterWorkspaces = {
  id: number
  isFilteredInSummary: boolean
  scheduleTypes: Array<{
    id: number
    isFilteredInUnitCost: boolean
  }>
}

type BopReportDisplayFilterWorkspaces = {
  id: number
  isFilteredInSummary: boolean
}

type DashboardDisplayFilterType = {
  workspaces: DashboardDisplayFilterWorkspaces[]
}

type BopDashboardDisplayFilterType = {
  workspaces: BopDashboardDisplayFilterWorkspaces[]
}

type BopReportDisplayFilterType = {
  workspaces: BopReportDisplayFilterWorkspaces[]
}

export type DisplayFilterResponse = {
  dashboard: DashboardDisplayFilterType
  BOPDashboard: BopDashboardDisplayFilterType
  BOPReport: BopReportDisplayFilterType
}

export type UpdateDisplayFilterProps = {
  dashboard?: DashboardDisplayFilterType
  BOPDashboard?: BopDashboardDisplayFilterType
  BOPReport?: BopReportDisplayFilterType
}

export type UpdateDisplayFilterResponse = {
  revision: number
  workDate: string
  updatedByName: string
  updatedAt: string
}

export const BopExportDataTypes = {
  Summary: 'summary',
  Detail: 'detail',
} as const
export type BopExportDataType = (typeof BopExportDataTypes)[keyof typeof BopExportDataTypes]

export type CreateBopExportDataInfo = {
  startDate: string
  endDate: string
  targetWorkspaces: number[]
  exportDataType: BopExportDataType
}

export const getTenantList = (params: CommonParams): Promise<TenantListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getTenant = (params: CommonParams, tenantId: number): Promise<TenantResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenantUserList = (params: CommonParams, tenantId: number): Promise<TenantUserListResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId}/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenantUser = (params: CommonParams, userId: string): Promise<TenantUserResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/users/${userId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const createTenantUser = (
  params: CommonParams,
  tenantId: number,
  data: CreateUserProps
): Promise<CreateUserProps> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId}/users`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const createTenant = (params: CommonParams, data: TenantEditDataType): Promise<TenantResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, convertToEditRequest(data, params.updatedBy), { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenant = (
  params: CommonParams,
  tenantId: number,
  data: TenantEditDataType,
  applications?: TenantApplicationSettingType[]
): Promise<TenantResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    const update = Object.assign({ applications }, convertToEditRequest(data, params.updatedBy))
    axios
      .patch(url, update, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantStatus = (
  params: CommonParams,
  tenantId: number,
  status: TenantStatusType
): Promise<TenantResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId}/status`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { status, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTenantAPIStatus = (
  params: CommonParams,
  tenantId: number,
  from: string,
  to: string
): Promise<APIStatusResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId}/api_stats`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { from, to } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getDataConnectionInfo = (params: CommonParams): Promise<DataConnectionInfoResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/data_connection_info`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getForecastProductivity = (
  params: CommonParams,
  workerId: number
): Promise<ForecastProductivityResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workers/${workerId}/forecast_productivity`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTenantApplication = (
  params: CommonParams,
  tenantId: number,
  tenant: TenantEditRequestType,
  applications: TenantApplicationSettingType[]
): Promise<TenantResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...tenant, applications, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const createExportData = (params: CommonParams, data: CreateExportDataInfo): Promise<CreateExportDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/data_export/async`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getExportData = (params: CommonParams, requestId: string): Promise<CheckExportDataReadyResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/data_export/update_status/${requestId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getUserManagedWorkspaces = (
  params: CommonParams,
  userId: string
): Promise<UserManagedWorkspacesResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/users/${userId}/managedWorkspaces`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateUserManagedWorkspaces = (
  params: CommonParams,
  userId: string,
  data: EditUserManagedWorkspacesProps
): Promise<UpdateUserManagedWorkspacesResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/users/${userId}/managedWorkspaces`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateUserPermission = (
  params: CommonParams,
  userId: string,
  data: UpdateUserPermissionProps
): Promise<UpdateUserPermissionResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/users/${userId}/permission`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...data }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getDisplayFilter = (params: CommonParams): Promise<DisplayFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/users/${params.userId}/display-filter`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const createBopExportData = (
  params: CommonParams,
  data: CreateBopExportDataInfo
): Promise<CreateExportDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/bop-export/async`

    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateDisplayFilter = (
  params: CommonParams,
  data: UpdateDisplayFilterProps
): Promise<UpdateDisplayFilterResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/users/${params.userId}/display-filter`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getBopExportData = (params: CommonParams, requestId: string): Promise<CheckExportDataReadyResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/bop-export/update-status/${requestId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

/**
 * 画面編集用の営業時間をAPIの形式に変換する。
 */

const convertToEditRequest = (src: TenantEditDataType, updatedBy: string) => {
  const { businessStartHour, businessStartMinute, businessEndHour, businessEndMinute, ...data } = src
  return {
    ...data,
    businessStartTime: businessStartHour + ':' + businessStartMinute,
    businessEndTime: businessEndHour + ':' + businessEndMinute,
    updatedBy,
  }
}
