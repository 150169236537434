import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { showError } from 'slices/notificationSlice'
import { selectWorkersStatus, importWorkers, exportWorkers, resetExportDataUrl } from 'slices/workersSlice'

import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import type { CharacterCodeType } from 'components/common/types'
import { CharacterCodeTypes, downloadByURL, ColumnSizes } from 'components/common/utils'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

type CharacterCodeItemType = { key: CharacterCodeType; value: CharacterCodeType }

const characterCodeItem: CharacterCodeItemType[] = [
  { key: CharacterCodeTypes.shiftJis, value: CharacterCodeTypes.shiftJis },
  { key: CharacterCodeTypes.utf8, value: CharacterCodeTypes.utf8 },
]

const WorkersImportDialog: React.FC<Props> = ({ isOpen, onSuccess, onCancel }) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const [file, setFile] = React.useState<File | null>(null)
  const [characterCode, setCharacterCode] = React.useState(characterCodeItem[0])
  const dispatch = useDispatch()
  const { isRequesting, errorMessage, downloadUrl } = useSelector(selectWorkersStatus, shallowEqual)

  const disabled = React.useMemo(() => !(file && /\.csv$/i.test(file.name)), [file])

  const handleSaveClick = () => {
    if (!file) {
      return
    }

    const reader = new FileReader()
    if (characterCode.value === CharacterCodeTypes.shiftJis) {
      reader.readAsText(file, 'SJIS')
    } else {
      reader.readAsText(file)
    }
    reader.addEventListener('load', () => {
      const content = reader.result
      if (content) {
        dispatch(importWorkers(file.name, content.toString()))
        setSubmitted(true)
      } else {
        setModalErrorMessage('ファイルが空です。')
      }
    })
    reader.addEventListener('error', () => setModalErrorMessage('ファイルの読み込みに失敗しました。'))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const handleDownloadFormat = () => {
    dispatch(exportWorkers([], false, false))
  }

  const downloadCsv = React.useCallback(
    async (url: string, filename: string) => {
      try {
        await downloadByURL(url, filename)
      } catch {
        dispatch(showError())
      } finally {
        dispatch(resetExportDataUrl())
      }
    },
    [dispatch]
  )

  React.useEffect(() => {
    if (!downloadUrl) {
      return
    }

    const filename = `members-${moment().format('YYYY-MM-DD-HHmm')}.csv`
    downloadCsv(downloadUrl, filename)
  }, [downloadUrl, downloadCsv])

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="メンバーインポート"
      approveLabel="CSVインポート"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="member-import-submit"
    >
      <div className="pb-4">
        <div className="mb-3">
          下記メンバーインポートデータフォーマットをダウンロードして、同じフォーマットでCSVアップロードをしてください。
        </div>
        <SelectBoxFormat
          label="文字コード選択"
          value={characterCode.key}
          size={ColumnSizes.middle}
          items={characterCodeItem}
          onChange={item => setCharacterCode(item as CharacterCodeItemType)}
          className="w-60 mb-3"
        />
        <ul className="mb-3">
          <li>
            メンバーインポートについて詳しくは
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href="https://help.smileboard.jp/member-import"
            >
              こちら
            </a>
            をご確認ください。
          </li>
          <li>データを更新する場合は現在のメンバーマスタをエクスポートし、編集してインポートしてください。</li>
          <li>
            一度にインポートできるのはヘッダーを除いて500行までです。適宜分割してインポートしてください。
            <span className="text-danger">
              アップロードされたCSVファイルがヘッダーを除いて500行より多い場合は処理は実施されません。
            </span>
          </li>
        </ul>
        <Button color="link" className="mb-3 ps-0 shadow-none text-decoration-none" onClick={handleDownloadFormat}>
          メンバーインポートデータフォーマットをダウンロード
        </Button>
        <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
      </div>
    </CustomModal>
  )
}

export default WorkersImportDialog
