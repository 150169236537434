import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/reports'
import { compactAverage, makeErrorMessage } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams, getSplitPeriods } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type ReportsState = {
  isRequesting: boolean
  errorMessage: string
  reportAverages: API.ReportAverage[]
  productivity: API.ReportProductivityResponse | undefined
  planAccuracies: API.PlanAccuracy[]
}

const initialState: ReportsState = {
  isRequesting: false,
  errorMessage: '',
  reportAverages: [],
  productivity: undefined,
  planAccuracies: [],
}

interface ExtendedDailyWork extends API.DailyWork {
  hourlyAvgProductivityPool: (number | null)[]
}
interface ExtendedProductivityWorker extends API.ProductivityWorker {
  dailyWorkData: ExtendedDailyWork[]
}
interface ExtendedProductivityGroup extends API.ProductivityGroup {
  workers: ExtendedProductivityWorker[]
}
interface ExtendedReportProductivity extends API.ReportProductivityResponse {
  groups: ExtendedProductivityGroup[]
}

// hourlyAvgProductivity の集計をするために productivityList を拡張する
const extendProductivityList = (productivityList: API.ReportProductivityResponse[]) => {
  return productivityList.map<ExtendedReportProductivity>(productivity => {
    const groups = productivity.groups.map<ExtendedProductivityGroup>(productivityGroup => {
      const workers = productivityGroup.workers.map<ExtendedProductivityWorker>(productivityWorker => {
        const dailyWorkData = productivityWorker.dailyWorkData.map<ExtendedDailyWork>(dailyWork => {
          return { ...dailyWork, hourlyAvgProductivityPool: [dailyWork.hourlyAvgProductivity] }
        })
        return { ...productivityWorker, dailyWorkData }
      })
      return { ...productivityGroup, workers }
    })
    return { ...productivity, groups }
  })
}

// hourlyAvgProductivityPool を集計する
const accumulateProductivity = (extendedProductivity: ExtendedReportProductivity) => {
  const accumulatedGroups = extendedProductivity.groups.map<API.ProductivityGroup>(productivityGroup => {
    const workers = productivityGroup.workers.map<API.ProductivityWorker>(productivityWorker => {
      const dailyWorkData = productivityWorker.dailyWorkData.map<API.DailyWork>(dailyWork => {
        return {
          scheduleTypeId: dailyWork.scheduleTypeId,
          scheduleTypeName: dailyWork.scheduleTypeName,
          scheduleTypeColor: dailyWork.scheduleTypeColor,
          unit: dailyWork.unit,
          hourlyAvgProductivity: compactAverage(dailyWork.hourlyAvgProductivityPool),
          data: dailyWork.data,
        }
      })
      return { ...productivityWorker, dailyWorkData }
    })
    return { ...productivityGroup, workers }
  })
  return { ...extendedProductivity, groups: accumulatedGroups }
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getReportAverageSuccess: (state, action: PayloadAction<API.ReportAverageResponse>) => {
      state.isRequesting = false
      state.reportAverages = action.payload.data
    },
    getReportProductivitySuccess: (state, action: PayloadAction<API.ReportProductivityResponse>) => {
      state.isRequesting = false
      state.productivity = action.payload
      state.productivity.groups = _.orderBy(action.payload.groups, ['groupName'], ['asc'])
    },
    getReportPlanAccuracySuccess: (state, action: PayloadAction<API.ReportPlanAccuracyResponse>) => {
      state.isRequesting = false
      state.planAccuracies = action.payload.data
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getReportAverageSuccess,
  getReportProductivitySuccess,
  getReportPlanAccuracySuccess,
} = reportsSlice.actions

export const getReportAverage =
  (workspaceId: number, from: string, to: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getReportAverage(commonParams(getState), workspaceId, from, to)
      .then((res: API.ReportAverageResponse) => dispatch(getReportAverageSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

const dailyWorkDataMerge = (dstData: ExtendedDailyWork[], srcData: ExtendedDailyWork[]) => {
  const scheduleTypeIds = _.uniq([...dstData.map(d => d.scheduleTypeId), ...srcData.map(d => d.scheduleTypeId)])
  return scheduleTypeIds.map(id => {
    const dstTarget = dstData.find(d => d.scheduleTypeId === id)
    const srcTarget = srcData.find(d => d.scheduleTypeId === id)
    if (dstTarget && srcTarget) {
      return {
        ...dstTarget,
        data: _.unionBy(dstTarget.data, srcTarget.data, 'date'),
        hourlyAvgProductivityPool: [...dstTarget.hourlyAvgProductivityPool, ...srcTarget.hourlyAvgProductivityPool],
      }
    }
    return (dstTarget || srcTarget)!
  })
}

const workerMerge = (dstWorkers: ExtendedProductivityWorker[], srcWorkers: ExtendedProductivityWorker[]) => {
  const workerIds = _.uniq([...dstWorkers.map(w => w.workerId), ...srcWorkers.map(w => w.workerId)])
  return workerIds.map(id => {
    const dstTarget = dstWorkers.find(w => w.workerId === id)
    const srcTarget = srcWorkers.find(w => w.workerId === id)
    if (dstTarget && srcTarget) {
      return {
        ...dstTarget,
        dailyWorkData: dailyWorkDataMerge(dstTarget.dailyWorkData, srcTarget.dailyWorkData),
      }
    }
    return (dstTarget || srcTarget)!
  })
}

const groupMerge = (dstGroups: ExtendedProductivityGroup[], srcGroups: ExtendedProductivityGroup[]) => {
  const groupIds = _.uniq([...dstGroups.map(g => g.groupId), ...srcGroups.map(g => g.groupId)])
  return groupIds.map(id => {
    const dstTarget = dstGroups.find(g => g.groupId === id)
    const srcTarget = srcGroups.find(g => g.groupId === id)
    if (dstTarget && srcTarget) {
      return {
        ...dstTarget,
        workers: workerMerge(dstTarget.workers, srcTarget.workers),
      }
    }
    return (dstTarget || srcTarget)!
  })
}

export const getReportProductivity =
  (workspaceId: number, from: string, to: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    const splitPeriods = getSplitPeriods(from, to)
    const promises: Promise<API.ReportProductivityResponse>[] = splitPeriods.map((period: [string, string]) =>
      API.getReportProductivity(commonParams(getState), workspaceId, period[0], period[1])
    )

    await Promise.all(promises)
      .then(productivityList => {
        // hourlyAvgProductivity の集計をするために productivityList を拡張する
        const extendedProductivityList = extendProductivityList(productivityList)

        // 複数の productivity を合成する
        const productivity = extendedProductivityList.reduce((acc: ExtendedReportProductivity, cur) => {
          // dailyWorkData を合成する
          const dailyWorkData = acc.dailyWorkData.map(dailyWork => {
            const target = cur.dailyWorkData.find(d => d.scheduleTypeId === dailyWork.scheduleTypeId)
            return { ...dailyWork, data: dailyWork.data.concat(target?.data || []) }
          })

          return {
            ...acc,
            dailyWorkData,
            groups: groupMerge(acc.groups, cur.groups),
          }
        })

        // hourlyAvgProductivity を集計する
        const accumulatedProductivity = accumulateProductivity(productivity)

        dispatch(getReportProductivitySuccess(accumulatedProductivity))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

const planAccuraciesMergeCustomizer = (objValue: API.PlanAccuracy, srcValue: API.PlanAccuracy) => {
  if (_.isArray(objValue) && _.isArray(srcValue)) {
    return _.union(objValue, srcValue)
  }
}

export const getReportPlanAccuracy =
  (workspaceId: number, from: string, to: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    const splitPeriods = getSplitPeriods(from, to)
    const promises: Promise<API.ReportPlanAccuracyResponse>[] = splitPeriods.reduce(
      (acc: Promise<API.ReportPlanAccuracyResponse>[], cur: [string, string]) => {
        acc.push(API.getReportPlanAccuracy(commonParams(getState), workspaceId, cur[0], cur[1]))
        return acc
      },
      []
    )
    await Promise.all(promises)
      .then(planAccuracies => {
        const planAccuracy = planAccuracies.reduce((acc: API.ReportPlanAccuracyResponse, cur) => ({
          data: _.mergeWith(acc.data, cur.data, planAccuraciesMergeCustomizer),
        }))
        dispatch(getReportPlanAccuracySuccess(planAccuracy))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectReportsStatus = (state: RootState) => ({ ...state.reports })

export default reportsSlice.reducer
