import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { CardText } from 'reactstrap'

import { selectAllocationStatus } from 'slices/allocationsSlice'

type Props = {
  allocationItemId: number
  keyNames: string
}

const WorkspaceAllocationListItem: React.FC<Props> = ({ allocationItemId, keyNames }) => {
  const { selectedAllocationId } = useSelector(selectAllocationStatus, shallowEqual)

  const cardTextColor = React.useMemo(
    () => (allocationItemId === selectedAllocationId ? 'text-white' : 'text-black-50'),
    [allocationItemId, selectedAllocationId]
  )

  return (
    <CardText className={`small mt-2 text-truncate ${cardTextColor}`}>
      <i className="icf-relation pr-2" />
      {keyNames}
    </CardText>
  )
}

export default WorkspaceAllocationListItem
