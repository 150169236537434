import * as React from 'react'

import type { WorkspaceDataItem } from 'hooks/useBopProfitAndLoss'

import styles from './BopProfitAndLoss.module.scss'

type BopColumnProps = {
  title: string
  data: WorkspaceDataItem[]
  isCheckedEstimate: boolean
}

const formatNumber = (value: number) => {
  const formattedNumber = value.toLocaleString('ja-JP')
  const formattedClassName = value < 0 ? 'text-danger' : ''
  return <span className={formattedClassName}>{formattedNumber}</span>
}

export const BopColumn: React.FC<BopColumnProps> = ({ title, data, isCheckedEstimate }) => {
  return (
    <table>
      <thead>
        <tr>
          <td className={`${styles.workspaceName} px-2 bg-secondary-pale text-start`}>{title}</td>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td className={`pe-1 ${item.className}`}>
              {formatNumber(item.actualValue)}
              {isCheckedEstimate && (
                <span className={`d-block text-end font-small ${styles.colorEstimate}`}>
                  {formatNumber(item.estimateValue)}
                </span>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const BopColumnTitle: React.FC = () => {
  return (
    <table>
      <thead>
        <tr>
          <td colSpan={2} className="ps-3 bg-secondary-pale">
            科目
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowSpan={3} className={`${styles.main} ps-3`}>
            売上
          </td>
          <td className={`${styles.sub} ps-5`}>売上</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-5`}>その他売上</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-3 ${styles.bgColorTotal}`}>売上合計</td>
        </tr>
        <tr>
          <td rowSpan={4} className={`${styles.main} ps-3`}>
            売上原価
          </td>
          <td className={`${styles.sub} ps-5`}>変動直接労務費</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-5`}>変動間接労務費</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-5`}>資材・材料費</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-3 ${styles.bgColorTotal}`}>売上原価合計</td>
        </tr>
        <tr>
          <td colSpan={2} className={`ps-3 ${styles.bgColorProfit}`}>
            限界利益
          </td>
        </tr>
        <tr>
          <td rowSpan={3} className={`${styles.main} ps-3`}>
            固定費
          </td>
          <td className={`${styles.sub} ps-5`}>関節労務費</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-5`}>管理費</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-5`}>固定費合計</td>
        </tr>
        <tr>
          <td colSpan={2} className={`ps-3 ${styles.bgColorProfit}`}>
            貢献利益
          </td>
        </tr>

        <tr>
          <td rowSpan={3} className={`${styles.main} ps-3`}>
            その他
            <br />
            費用
          </td>
          <td className={`${styles.sub} ps-5`}>WS費用</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-5`}>事業所費用</td>
        </tr>
        <tr>
          <td className={`${styles.sub} ps-5`}>その他費用合計</td>
        </tr>
        <tr>
          <td colSpan={2} className={`ps-3 ${styles.bgColorProfit}`}>
            営業利益
          </td>
        </tr>
      </tbody>
    </table>
  )
}
