import axios from 'axios'

import type { CommonParams } from 'slices/utils'

export type OfficialDutyListArray = {
  id: number
  revision: number
  workDate: string
  name: string
}

export type OfficialDutyListResponse = {
  workDate: string
  officialDuties: OfficialDutyListArray[]
}

export type OfficialDutyDetailArray = {
  id: number
  revision: number
  workDate: string
  name: string
  averageHourlyWage: number
  updatedAt: string
  updatedByName: string
}

export type OfficialDutyDetailResponse = {
  workDate: string
  officialDuties: OfficialDutyDetailArray[]
}

export type CreateOfficialDutyResponse = {
  id: number
  revision: number
  workDate: string
  createdAt: string
  createdByName: string
}

export type UpdateDeleteOfficialDutyResponse = {
  id: number
  revision: number
  workDate: string
  updatedAt: string
  updatedByName: string
}

export const getOfficialDutyList = (params: CommonParams): Promise<OfficialDutyListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/official-duties`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getOfficialDuty = (params: CommonParams, id: number): Promise<OfficialDutyDetailResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/official-duties/${id}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createOfficialDuty = (
  params: CommonParams,
  name: string,
  averageHourlyWage: number
): Promise<CreateOfficialDutyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/official-duties`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(
        url,
        {
          officialDuties: {
            name,
            averageHourlyWage,
          },
        },
        { headers }
      )
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateOfficialDuty = (
  params: CommonParams,
  id: number,
  name: string,
  averageHourlyWage: number
): Promise<UpdateDeleteOfficialDutyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/official-duties/${id}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(
        url,
        {
          officialDuties: {
            name,
            averageHourlyWage,
          },
        },
        { headers }
      )
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteOfficialDuty = (params: CommonParams, id: number): Promise<UpdateDeleteOfficialDutyResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/official-duties/${id}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
