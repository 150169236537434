import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText, CardColumns } from 'reactstrap'

import { allocationTypes } from 'api/allocations'
import type { EditAllocationProps, AllocationData, EditKeyScheduleType } from 'api/allocations'
import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import {
  getAllocationList,
  getAllocation,
  selectAllocationStatus,
  clearErrorMessage,
  createAllocation,
  updateAllocation,
  storeSelectedAllocationId,
} from 'slices/allocationsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { getWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  NavMenu,
  CustomButton,
  BadgeLabel,
  InputFormat,
  List,
  SelectBoxFormat,
  CardSubmitFooter,
  InputGroupFormat,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { ListItem } from 'components/common/types'
import { ColumnSizes } from 'components/common/utils'

import useWorkspaceAllocation from 'hooks/useWorkspaceAllocation'

import placeholder from 'images/allEmpty.svg'

import WorkspaceAllocationDelete from './WorkspaceAllocationDelete'
import WorkspaceAllocationErrorIcon from './WorkspaceAllocationErrorIcon'
import WorkspaceAllocationListItem from './WorkspaceAllocationListItem'

import styles from './WorkspaceAllocation.module.scss'

const onDetailClick = () => window.open('https://help.smileboard.jp/setup_work', '_blank') // 仮URL

const MAX_ALLOCATION_SETTING_NUM = 30
const MAX_KEY_SCHEDULE_SETTING_NUM = 20

const WorkspaceAllocation: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [allocationListItems, setAllocationListItems] = React.useState<ListItem[]>([])
  const [selectedAllocationId, setSelectedAllocationId] = React.useState<number | undefined>(undefined)
  const [initEditData, setInitEditData] = React.useState<EditAllocationProps | undefined>()

  const [submitted, setSubmitted] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)

  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const {
    allocations,
    allocation,
    isRequesting,
    errorMessage,
    workspaceId: stateWorkspaceId,
  } = useSelector(selectAllocationStatus, shallowEqual)

  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaces, workspaceId])

  const dispatch = useDispatch()

  const {
    NEW_ALLOCATION_ID,
    NEW_KEY_SCHEDULE_ID,
    allocationTypeData,
    disabled,
    editData,
    setEditData,
    keyScheduleTypes,
    targetKeyScheduleTypes,
    remainKeyScheduleTypes,
    allocationTypeText,
    setAllocationNameValidity,
    setTargetScheduleTypeValidity,
    setAllocationTypeValidity,
    setAllocationRatioTypeValidity,
    setRemainderScheduleTypeValidity,
    distributionRatioValidation,
    deletedKeyScheduleTypeValidation,
  } = useWorkspaceAllocation()

  const initAllocationData = React.useMemo(() => {
    return {
      id: null,
      name: '',
      scheduleTypeId: null,
      scheduleTypeName: null,
      type: allocationTypes.Manual,
      keys: [{ scheduleTypeId: NEW_KEY_SCHEDULE_ID, ratio: '0.00' }],
      remainderScheduleTypeId: null,
    }
  }, [NEW_KEY_SCHEDULE_ID])

  React.useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getAllocationList(workspaceId))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  const isNewItem = React.useMemo(
    () => selectedAllocationId === NEW_ALLOCATION_ID,
    [selectedAllocationId, NEW_ALLOCATION_ID]
  )

  React.useEffect(() => {
    if (
      selectedAllocationId &&
      selectedAllocationId !== NEW_ALLOCATION_ID &&
      allocationListItems.some(item => item.id === selectedAllocationId)
    ) {
      dispatch(getAllocation(workspaceId, selectedAllocationId))
    }
  }, [dispatch, allocationListItems, workspaceId, selectedAllocationId, NEW_ALLOCATION_ID])

  React.useEffect(() => {
    if (workspaceId !== stateWorkspaceId) {
      return
    }

    const allocationItems: ListItem[] = allocations.map(allocationItem => {
      return {
        id: allocationItem.id,
        title: allocationItem.name,
        data: (
          <WorkspaceAllocationListItem
            allocationItemId={allocationItem.id}
            keyNames={allocationItem.keyNames.join(',')}
          />
        ),
        option: allocationItem.isError ? (
          <WorkspaceAllocationErrorIcon allocationItemId={allocationItem.id} />
        ) : undefined,
      }
    })
    setAllocationListItems(allocationItems)
  }, [allocations, workspaceId, stateWorkspaceId])

  const getAllocationKeys = (type: string, keys: EditKeyScheduleType[]) => {
    return type === allocationTypes.Manual
      ? keys
      : keys.map(item => ({ scheduleTypeId: item.scheduleTypeId, ratio: '0.00' }))
  }

  const updateEditData = React.useCallback(
    (allocationData: AllocationData | undefined) => {
      const newEditData = allocationData && {
        name: allocationData.name,
        scheduleTypeId: allocationData.scheduleTypeId,
        scheduleTypeName: allocationData.scheduleTypeName,
        type: allocationData.type,
        keys: getAllocationKeys(
          allocationData.type,
          !_.isEmpty(allocationData.keys)
            ? allocationData.keys.map(key => {
                return {
                  scheduleTypeId: key.scheduleTypeId,
                  ratio: key.ratio ? _.floor(key.ratio, 2).toFixed(2) : '0.00',
                }
              })
            : [{ scheduleTypeId: NEW_KEY_SCHEDULE_ID, ratio: '0.00' }]
        ),
        remainderScheduleTypeId: allocationData.remainderScheduleTypeId,
      }

      setEditData(newEditData)
      setInitEditData(newEditData)
    },
    [setEditData, setInitEditData, NEW_KEY_SCHEDULE_ID]
  )

  React.useEffect(() => {
    if (isNewItem) {
      setEditData(initAllocationData)
      return
    }

    if (!allocation || _.isEmpty(allocation.allocations[0])) {
      return
    }

    if (allocation.allocations[0].id !== selectedAllocationId) {
      return
    }

    updateEditData(allocation.allocations[0])
  }, [allocation, selectedAllocationId, setEditData, updateEditData, initAllocationData, isNewItem])

  React.useEffect(() => {
    if (_.isEmpty(allocationListItems)) {
      return
    }

    setSelectedAllocationId(prev => {
      // 追加アイテムを保存した時
      if (prev === NEW_ALLOCATION_ID && !allocationListItems.some(item => item.id === NEW_ALLOCATION_ID)) {
        return Number(_.last(allocationListItems)!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || (prev !== NEW_ALLOCATION_ID && !allocationListItems.some(item => item.id === prev))) {
        return Number(allocationListItems[0].id)
      }
      return prev
    })
  }, [allocationListItems, NEW_ALLOCATION_ID])

  React.useEffect(() => {
    dispatch(storeSelectedAllocationId(selectedAllocationId))
  }, [dispatch, selectedAllocationId])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: '登録上限に達しているため、保存できませんでした。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        dispatch(showError())
      }
      dispatch(clearErrorMessage())
    }
    dispatch(getAllocationList(workspaceId))
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId])

  const badgeLabel = React.useMemo(() => {
    const item = workspaces.find(w => w.workspaceId === workspaceId)
    return item?.name || ''
  }, [workspaces, workspaceId])

  const targetScheduleTypes = React.useMemo(() => {
    const allocatedScheduleTypeIds = isRequesting
      ? []
      : allocations.filter(a => a.id !== selectedAllocationId).map(a => a.scheduleTypeId)
    const filteredScheduleTypes = scheduleTypes.filter(
      item => !allocatedScheduleTypeIds.includes(item.scheduleTypeId) && !item.isKey && !item.isCostless
    )

    return filteredScheduleTypes.map(scheduleType => ({
      key: scheduleType.scheduleTypeId,
      value: scheduleType.name,
    }))
  }, [allocations, scheduleTypes, selectedAllocationId, isRequesting])

  const unchanged = React.useMemo(() => _.isEqual(initEditData, editData), [initEditData, editData])

  const onSelectItem = React.useCallback(
    (id: number) => {
      setSelectedAllocationId(prevAllocationId => {
        if (prevAllocationId === id) {
          return prevAllocationId
        }
        if (prevAllocationId === NEW_ALLOCATION_ID) {
          setAllocationListItems(prev => prev.filter(item => item.id !== NEW_ALLOCATION_ID))
        }

        return id
      })
    },
    [NEW_ALLOCATION_ID]
  )

  const onSubmit = React.useCallback(() => {
    if (!editData) {
      return
    }

    const newKeys =
      editData.type === allocationTypes.Manual
        ? editData.keys.map(key => ({ scheduleTypeId: key.scheduleTypeId, ratio: Number(key.ratio) }))
        : editData.keys.map(key => ({ scheduleTypeId: key.scheduleTypeId }))

    if (selectedAllocationId === NEW_ALLOCATION_ID) {
      setSubmitted(true)
      dispatch(
        createAllocation(workspaceId, {
          allocation: {
            ...editData,
            keys: newKeys,
          },
        })
      )
      return
    }

    if (!selectedAllocationId || !initEditData) {
      return
    }

    const omitParams = _.omitBy(editData, (value, key) =>
      _.isEqual(value, initEditData[key as keyof EditAllocationProps])
    )
    const updateData =
      omitParams.keys || omitParams.type || omitParams.remainderScheduleTypeId
        ? {
            ...omitParams,
            type: editData.type,
            remainderScheduleTypeId: editData.remainderScheduleTypeId,
            keys: newKeys,
          }
        : omitParams

    setSubmitted(true)
    dispatch(updateAllocation(workspaceId, selectedAllocationId, { allocation: updateData }))
  }, [dispatch, editData, initEditData, selectedAllocationId, workspaceId, NEW_ALLOCATION_ID])

  const onCancel = React.useCallback(() => {
    if (selectedAllocationId === NEW_ALLOCATION_ID) {
      setAllocationListItems(prev => prev.filter(item => item.id !== NEW_ALLOCATION_ID))
      const newId = !_.isEmpty(allocations) && allocations[0]?.id
      setSelectedAllocationId(newId || undefined)
      return
    }

    allocation && !_.isEmpty(allocation.allocations) && updateEditData(allocation.allocations[0])
  }, [allocations, allocation, selectedAllocationId, updateEditData, NEW_ALLOCATION_ID])

  const onAddNewItem = React.useCallback(() => {
    setAllocationListItems(prev =>
      prev.concat({
        id: NEW_ALLOCATION_ID,
        title: '未保存の項目',
        data: (
          <CardText className={'small mt-2 text-truncate text-white'}>
            <i className="icf-relation pr-2" />
          </CardText>
        ),
      })
    )

    setSelectedAllocationId(NEW_ALLOCATION_ID)
  }, [NEW_ALLOCATION_ID])

  const onAddNewKeySchedule = React.useCallback(() => {
    if (!editData) {
      return
    }

    setEditData({
      ...editData,
      keys: editData.keys.concat({ scheduleTypeId: NEW_KEY_SCHEDULE_ID, ratio: '0.00' }),
    })
  }, [editData, setEditData, NEW_KEY_SCHEDULE_ID])

  const onDeleteNewKeySchedule = React.useCallback(
    (KeyScheduleTypeId: number) => {
      if (!editData) {
        return
      }

      const newKeys = editData.keys.filter(item => item.scheduleTypeId !== KeyScheduleTypeId)
      setEditData({
        ...editData,
        keys: newKeys,
        remainderScheduleTypeId: newKeys.length === 1 ? newKeys[0].scheduleTypeId : editData.remainderScheduleTypeId,
      })
    },
    [editData, setEditData]
  )

  const onChangeAllocationType = React.useCallback(
    (type: string) => {
      if (!editData) {
        return
      }

      setEditData({
        ...editData,
        type: type,
        keys: getAllocationKeys(type, editData.keys),
      })
    },
    [editData, setEditData]
  )

  const onChangeKeySchedule = React.useCallback(
    (changeItem: { key?: string | number; value: string }, index: number) => {
      if (!editData) {
        return
      }

      const newKeys = _.isEmpty(editData.keys)
        ? [{ scheduleTypeId: Number(changeItem.key), ratio: '0.00' }]
        : editData.keys.map((item, itemIndex) => {
            if (index !== itemIndex) {
              return item
            }

            return {
              scheduleTypeId: Number(changeItem.key),
              ratio: item.ratio,
            }
          })
      setEditData({
        ...editData,
        keys: newKeys,
        remainderScheduleTypeId: newKeys.length === 1 ? newKeys[0].scheduleTypeId : editData.remainderScheduleTypeId,
      })
    },
    [editData, setEditData]
  )

  const onChangeKeyScheduleRatio = React.useCallback(
    (value: string, index: number) => {
      if (!editData) {
        return
      }

      // 数値以外の値の場合
      if (isNaN(Number(value))) {
        return
      }

      setEditData({
        ...editData,
        keys: editData.keys.map((item, itemIndex) => {
          if (index !== itemIndex) {
            return item
          }

          return {
            scheduleTypeId: item.scheduleTypeId,
            ratio: value,
          }
        }),
      })
    },
    [editData, setEditData]
  )

  const onBlurKeyScheduleRatio = React.useCallback(
    (index: number) => {
      if (!editData) {
        return
      }

      setEditData({
        ...editData,
        keys: editData.keys.map((item, itemIndex) => {
          if (index !== itemIndex) {
            return item
          }

          return {
            scheduleTypeId: item.scheduleTypeId,
            ratio: _.floor(Number(item.ratio), 2).toFixed(2),
          }
        }),
      })
    },
    [editData, setEditData]
  )

  const onChangeRemainKeySchedule = React.useCallback(
    (changeItem: { key?: string | number; value: string }) => {
      if (!editData) {
        return
      }

      setEditData({
        ...editData,
        remainderScheduleTypeId: Number(changeItem.key),
      })
    },
    [editData, setEditData]
  )

  return (
    <>
      <NavMenu>
        <div className="mt-3 mx-3">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="font-x-large fw-bold">作業間配賦管理</div>
                <div className="px-2">{workspace && <BadgeLabel label={badgeLabel} />}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-2 text-gray">
                  {allocationListItems.length} / {MAX_ALLOCATION_SETTING_NUM} 利用中
                </div>
                <CustomButton
                  icon="plus"
                  disabled={
                    allocationListItems.length >= MAX_ALLOCATION_SETTING_NUM ||
                    allocationListItems.some(item => item.id === NEW_ALLOCATION_ID)
                  }
                  onClick={onAddNewItem}
                >
                  作業間配賦設定を追加
                </CustomButton>
              </div>
            </div>
          </div>

          <Row className={styles.row}>
            <Col md={4} className="h-100">
              <Card className={styles.list}>
                {allocationListItems.length > 0 ? (
                  <List
                    items={allocationListItems}
                    selectedId={selectedAllocationId}
                    onAction={((id: number) => onSelectItem(id)) as (id: number | string) => void}
                  />
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業間配賦設定がまだ登録されていません</div>
                      <div>まずは最初の作業間配賦設定を登録してみましょう。</div>
                      <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                        作業間配賦設定についてもっと詳しく
                      </Button>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col md={8} className="h-100">
              <Card className="h-100">
                {allocationListItems.length > 0 && editData ? (
                  <>
                    <div className="h-100 overflow-auto">
                      <CardBody>
                        <CardColumns className="d-flex justify-content-between">
                          <CardTitle className="font-large fw-bold">作業間配賦設定</CardTitle>
                          <span className="font-x-small text-muted">※必須項目</span>
                        </CardColumns>
                        <InputFormat
                          label="名称※"
                          placeholder="作業間配賦設定を入力"
                          value={editData.name}
                          size={ColumnSizes.middle}
                          maxLength={100}
                          onChange={name => setEditData({ ...editData, name })}
                          validations={[Rules.StringLengthRange(1, 30)]}
                          onValidate={setAllocationNameValidity}
                          className="mb-3"
                        />
                        <SelectBoxFormat
                          label="作業を選択※"
                          placeholder="作業を選択"
                          value={editData.scheduleTypeId?.toString()}
                          size={ColumnSizes.middle}
                          items={targetScheduleTypes}
                          disabled={false}
                          onChange={e =>
                            setEditData({
                              ...editData,
                              scheduleTypeId: Number(e.key),
                              scheduleTypeName: e.value?.toString(),
                            })
                          }
                          validations={[Rules.Required]}
                          onValidate={setTargetScheduleTypeValidity}
                          className="mb-3"
                        />
                        <SelectBoxFormat
                          label="按分方法"
                          placeholder="按分方法を選択"
                          formText={allocationTypeText}
                          value={editData.type}
                          size={ColumnSizes.middle}
                          items={allocationTypeData}
                          disabled={false}
                          onChange={e => onChangeAllocationType(e.key!.toString())}
                          validations={[Rules.Required]}
                          onValidate={setAllocationTypeValidity}
                          className="mb-3"
                        />
                        <CardText>
                          登録済みのキー作業への配賦を設定できます。設定に応じて、選択した作業の費用がキー作業の費用に含める事ができます。配賦設定の詳細に関してはヘルプをご確認ください。
                        </CardText>
                        <CardText className="text-end text-gray">
                          {editData.keys.length} / {MAX_KEY_SCHEDULE_SETTING_NUM} 利用中
                        </CardText>
                        <Card>
                          {editData.keys.map((item, index) => {
                            return (
                              <div key={item.scheduleTypeId || `deletedScheduleType${index}`}>
                                {index > 0 && <hr className="m-0" />}
                                <div className="d-flex justify-content-between m-3">
                                  <div className="w-75 d-flex flex-column gap-3">
                                    <SelectBoxFormat
                                      label={`キー作業${index + 1}※`}
                                      placeholder={item.scheduleTypeId === null ? '-' : 'キー作業を選択'}
                                      value={item.scheduleTypeId === null ? '-' : item.scheduleTypeId.toString()}
                                      size={ColumnSizes.middle}
                                      items={targetKeyScheduleTypes(item.scheduleTypeId)}
                                      disabled={false}
                                      onChange={e => onChangeKeySchedule(e, index)}
                                      validations={
                                        item.scheduleTypeId === null
                                          ? [deletedKeyScheduleTypeValidation]
                                          : [Rules.Required]
                                      }
                                    />

                                    {editData.type === allocationTypes.Manual && (
                                      <InputGroupFormat
                                        label="按分割合※"
                                        placeholder=""
                                        addonText="%"
                                        value={editData.keys[index].ratio || undefined}
                                        size={ColumnSizes.middle}
                                        maxLength={10}
                                        onChange={value => onChangeKeyScheduleRatio(value, index)}
                                        onBlur={() => onBlurKeyScheduleRatio(index)}
                                        validations={[Rules.Required, distributionRatioValidation]}
                                        onValidate={setAllocationRatioTypeValidity}
                                      />
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {index + 1 === editData.keys.length && (
                                      <CustomButton
                                        outline
                                        icon="plus"
                                        disabled={
                                          item.scheduleTypeId === NEW_KEY_SCHEDULE_ID ||
                                          editData.keys.length === keyScheduleTypes.length ||
                                          editData.keys.length === MAX_KEY_SCHEDULE_SETTING_NUM
                                        }
                                        onClick={onAddNewKeySchedule}
                                      >
                                        追加
                                      </CustomButton>
                                    )}
                                    {editData.keys.length > 1 && (
                                      <Button
                                        className="ms-2"
                                        outline
                                        color="danger"
                                        disabled={false}
                                        onClick={() => onDeleteNewKeySchedule(item.scheduleTypeId!)}
                                      >
                                        削除
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          {editData.keys.length > 1 && (
                            <>
                              <hr className="m-0" />
                              <CardBody className="d-flex">
                                <div className="w-75">
                                  <SelectBoxFormat
                                    className="mb-0"
                                    label="余りの配賦先※"
                                    placeholder={editData.remainderScheduleTypeId === null ? '-' : 'キー作業を選択'}
                                    value={
                                      editData.remainderScheduleTypeId === null
                                        ? '-'
                                        : editData.remainderScheduleTypeId.toString()
                                    }
                                    size={ColumnSizes.middle}
                                    items={remainKeyScheduleTypes}
                                    disabled={false}
                                    onChange={e => onChangeRemainKeySchedule(e)}
                                    validations={
                                      editData.remainderScheduleTypeId === null
                                        ? [deletedKeyScheduleTypeValidation]
                                        : [Rules.Required]
                                    }
                                    onValidate={setRemainderScheduleTypeValidity}
                                  />
                                </div>
                              </CardBody>
                            </>
                          )}
                        </Card>
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">作業間配賦設定の削除</CardTitle>
                        <CardText className="py-2">
                          作業間配賦設定を削除すると、⚪︎⚪︎⚪︎などの情報はすべて失われ、復旧できません。
                        </CardText>
                        <Button
                          outline
                          color="danger"
                          disabled={selectedAllocationId === NEW_ALLOCATION_ID}
                          onClick={() => setOpenDelete(true)}
                        >
                          作業間配賦設定を削除
                        </Button>
                      </CardBody>
                    </div>

                    <CardSubmitFooter
                      onCancel={onCancel}
                      onSubmit={onSubmit}
                      submitDisabled={unchanged || disabled}
                      cancelDisabled={unchanged}
                      updatedBy={isNewItem ? undefined : allocation?.allocations[0]?.updatedByName}
                      updatedAt={isNewItem ? undefined : allocation?.allocations[0]?.updatedAt}
                    />
                  </>
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業間配賦設定が選択されていません</div>
                      <div>作業間配賦設定を選択して、詳細情報を編集しましょう。</div>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>

          <WorkspaceAllocationDelete
            isOpen={openDelete}
            workspaceId={workspaceId}
            allocationId={selectedAllocationId}
            onSuccess={() => setOpenDelete(false)}
            onCancel={() => setOpenDelete(false)}
          />
        </div>
      </NavMenu>
    </>
  )
}

export default WorkspaceAllocation
