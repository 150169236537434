import * as React from 'react'
import { Modal as ReactModal } from 'reactstrap'

import styles from './Modal.module.scss'

type Props = {
  isOpen: boolean
  children: React.ReactNode
  zIndex?: number
  overflow?: string
}

export const Modal: React.FC<Props> = ({ isOpen, children, zIndex = 2000, overflow }) => {
  return (
    <ReactModal className={`position-absolute ${styles.modal}`} isOpen={isOpen} style={{ zIndex, overflow }}>
      <div className="mx-3 mt-3">{children}</div>
    </ReactModal>
  )
}

export default Modal
