import React from 'react'

import { ReactComponent as dashboardActive } from './icons/dashboard-active.svg'
import { ReactComponent as dashboard } from './icons/dashboard.svg'
import { ReactComponent as importHistoryActive } from './icons/importHistory-active.svg'
import { ReactComponent as importHistory } from './icons/importHistory.svg'
import { ReactComponent as incomePenceActive } from './icons/incomePence-active.svg'
import { ReactComponent as incomePence } from './icons/incomePence.svg'
import { ReactComponent as incomePenceReportActive } from './icons/incomePenceReport-active.svg'
import { ReactComponent as incomePenceReport } from './icons/incomePenceReport.svg'
import { ReactComponent as memberActive } from './icons/member-active.svg'
import { ReactComponent as member } from './icons/member.svg'
import { ReactComponent as planActive } from './icons/plan-active.svg'
import { ReactComponent as plan } from './icons/plan.svg'
import { ReactComponent as relocationActive } from './icons/relocation-active.svg'
import { ReactComponent as relocation } from './icons/relocation.svg'
import { ReactComponent as reportActive } from './icons/report-active.svg'
import { ReactComponent as report } from './icons/report.svg'
import { ReactComponent as tenantActive } from './icons/tenant-active.svg'
import { ReactComponent as tenant } from './icons/tenant.svg'
import { ReactComponent as workspaceActive } from './icons/workspace-active.svg'
import { ReactComponent as workspace } from './icons/workspace.svg'

const drawerIcons = {
  dashboard,
  dashboardActive,
  importHistory,
  importHistoryActive,
  incomePence,
  incomePenceActive,
  incomePenceReport,
  incomePenceReportActive,
  member,
  memberActive,
  plan,
  planActive,
  relocation,
  relocationActive,
  report,
  reportActive,
  tenant,
  tenantActive,
  workspace,
  workspaceActive,
}

type DrawerIconProps = {
  name: string
  height?: number
  width?: number
  fill?: string
}

const DrawerIcon: React.FC<DrawerIconProps> = ({ name, height = 50, width = 50, fill }) => {
  const SvgComponent = drawerIcons[name as keyof typeof drawerIcons]

  return <SvgComponent style={{ height: `${height}px`, width: `${width}px` }} fill={fill} />
}

export default DrawerIcon
