import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import type { UpdateShiftsType } from 'api/schedules'
import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'
import * as API from 'api/workers'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type CreateUpdateParam = {
  createData: API.WorkerScheduleUpdateType[]
  updateData: Array<{ data: API.WorkerScheduleUpdateType; scheduleId: number }>
}

type WorkersState = API.WorkerListResponse & {
  isRequesting: boolean
  errorMessage: string
  downloadUrl: string | undefined
}

const initialState: WorkersState = {
  isRequesting: false,
  errorMessage: '',
  workers: [],
  downloadUrl: undefined,
}

export const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkerListSuccess: (state, action: PayloadAction<API.WorkerListResponse>) => {
      state.isRequesting = false
      state.workers = action.payload.workers
    },
    getWorkerSuccess: (state, action: PayloadAction<API.WorkerResponse>) => {
      state.isRequesting = false
      const index = state.workers.findIndex(worker => worker.workerId === action.payload.workerId)
      state.workers.splice(index, 1, action.payload)
    },
    createWorkerSuccess: (state, action: PayloadAction<API.WorkerResponse>) => {
      state.isRequesting = false
      state.workers.push(action.payload)
    },
    updateWorkerSuccess: (state, action: PayloadAction<API.WorkerResponse>) => {
      state.isRequesting = false
      state.workers = state.workers.map(worker =>
        worker.workerId === action.payload.workerId ? action.payload : worker
      )
    },
    deleteWorkerSuccess: (state, action: PayloadAction<{ workerId: number }>) => {
      state.isRequesting = false
      const index = state.workers.findIndex(worker => worker.workerId === action.payload.workerId)
      state.workers.splice(index, 1)
    },
    updateWorkerCompleted: state => {
      state.isRequesting = false
    },
    importWorkersSuccess: state => {
      state.isRequesting = false
    },
    getExportDataUrlSuccess: (state, action: PayloadAction<API.CheckExportDataReadyResponse>) => {
      state.isRequesting = false
      state.downloadUrl = action.payload.downloadUrl
    },
    resetExportDataUrl: state => (state.downloadUrl = undefined),
  },
})

export const {
  startRequest,
  apiFailure,
  getWorkerListSuccess,
  getWorkerSuccess,
  createWorkerSuccess,
  updateWorkerSuccess,
  deleteWorkerSuccess,
  updateWorkerCompleted,
  importWorkersSuccess,
  getExportDataUrlSuccess,
  resetExportDataUrl,
} = workersSlice.actions

export const getWorkerList =
  (useSchedules = true): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkerList(commonParams(getState), useSchedules)
      .then((res: API.WorkerListResponse) => dispatch(getWorkerListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorker =
  (workerId: number, useSchedules = true, from: string, to: string, filterByShift = false): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorker(commonParams(getState), workerId, useSchedules, from, to, filterByShift)
      .then((res: API.WorkerResponse) => dispatch(getWorkerSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createWorker =
  (data: API.WorkerEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createWorker(commonParams(getState), data)
      .then((res: API.WorkerResponse) => dispatch(createWorkerSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateWorker =
  (workerId: number, data: API.WorkerEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateWorker(commonParams(getState), workerId, data)
      .then((res: API.WorkerResponse) => dispatch(updateWorkerSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteWorker =
  (workerId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteWorker(commonParams(getState), workerId)
      .then(() => dispatch(deleteWorkerSuccess({ workerId })))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateWorkerShifts =
  (data: UpdateShiftsType, workspaceId: number, date: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    const schedulesPerWorker = _.groupBy(
      data.schedules,
      o => o.schedule?.workerId || data.originalSchedules.find(os => o.scheduleId === os.scheduleId)?.workerId
    )
    const originalSchedulesPerWorker = _.groupBy(data.originalSchedules, o => o.workerId)
    const updateSchedulesPerWorker = async (workerId: string) => {
      const workerPayload = {
        schedules: schedulesPerWorker[workerId],
        originalSchedules: originalSchedulesPerWorker[workerId] || [],
      }
      return await API.updateWorkerShifts(commonParams(getState), workspaceId, Number(workerId), date, workerPayload)
    }

    dispatch(Spinner.start())
    // 一括でシフトを更新するAPIが実装されていないため、作業者ごとに分割し、直列でリクエストする
    await Object.keys(schedulesPerWorker)
      .reduce(
        (promise, workerId) => {
          return promise.then(async works => [...works, await updateSchedulesPerWorker(workerId)])
        },
        Promise.resolve([] as API.WorkerResponse[])
      )
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => {
        dispatch(updateWorkerCompleted())
        dispatch(Spinner.stop())
      })
  }

export const importWorkers =
  (fileName: string, csvContent: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      const response = await API.workersUploadUrl(commonParams(getState), fileName)
      await API.putUploadUrl(commonParams(getState), response.uploadUrl, csvContent)
      dispatch(importWorkersSuccess())
    } catch (error) {
      const errorCode = makeErrorMessage(error as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const exportWorkers =
  (targetWorkspaces: number[], forecastProductivity: boolean, includeUnaffiliatedWorker: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      const exportDataResponse = await API.exportWorkers(
        commonParams(getState),
        targetWorkspaces,
        forecastProductivity,
        includeUnaffiliatedWorker
      )
      if (exportDataResponse?.downloadUrl) {
        return dispatch(getExportDataUrlSuccess(exportDataResponse))
      }
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const selectWorkersStatus = (state: RootState) => ({ ...state.workers })

export default workersSlice.reducer
