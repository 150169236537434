import * as React from 'react'
import { Card, CardBody, Col, Row, Button } from 'reactstrap'

import { FieldNames } from 'components/BalanceOfPayments/types'
import type { CostArrayToString, ValidationType, BadgeLabelTypes } from 'components/BalanceOfPayments/types'
import { InputFormat, CustomButton } from 'components/common'
import { ColumnSizes } from 'components/common/utils'

import { useBopFormInput } from 'hooks/useBopFormInput'
import useBopValidation from 'hooks/useBopValidation'

type BadgeLabelType = (typeof BadgeLabelTypes)[keyof typeof BadgeLabelTypes]

type FieldNameType = (typeof FieldNames)[keyof typeof FieldNames]

type FormInputProps = {
  costItems: CostArrayToString[]
  toFixed: boolean
  maxItems: number
  onChange: (val: (latest: CostArrayToString[]) => CostArrayToString[]) => void
  onBlur?: (value: string) => string | undefined
  onValidate: (isValid: boolean) => void
  titleText?: string
  addButtonText: string
  labelText: string
  BadgeLabelInput: BadgeLabelType
  addonText: string
  validations?: ValidationType
}

export const FormInput: React.FC<FormInputProps> = ({
  costItems,
  toFixed,
  maxItems,
  onChange,
  onBlur,
  onValidate,
  titleText,
  addButtonText,
  labelText,
  BadgeLabelInput,
  addonText,
  validations,
}) => {
  const { items, showAddButton, showAddSmallButton, nameValidations, costValidations } = useBopFormInput(
    costItems,
    onChange,
    validations,
    maxItems
  )
  const { updateValidationList } = useBopValidation(items, onValidate)
  const handleUpdateItem = React.useCallback(
    (index: number, field: FieldNameType, value: string | number) => {
      onChange(prev => prev.map((item, idx) => (idx === index ? { ...item, [field]: value } : item)))
    },
    [onChange]
  )

  const handleAddItem = React.useCallback(() => {
    const newItem = { name: '', cost: toFixed ? '0.00' : '0' }
    onChange(prev => [...prev, newItem])
  }, [onChange, toFixed])

  const handleDeleteItem = React.useCallback(
    (index: number) => {
      onChange(prev => prev.filter((_, idx) => idx !== index))
    },
    [onChange]
  )

  return (
    <>
      <CustomButton icon="plus" outline size="sm" onClick={handleAddItem} show={showAddButton}>
        {addButtonText}
      </CustomButton>
      {items.length ? (
        <>
          {titleText ? <div className="pt-2">{titleText}</div> : null}
          <div className="d-flex justify-content-end align-self-center mb-2 text-gray">
            {items.length} / {maxItems} 利用中
          </div>
          <Card>
            {items.map((item, index) => (
              <div key={index}>
                <CardBody>
                  <Row>
                    <Col xxl="9">
                      <InputFormat
                        label={labelText}
                        labelSize={ColumnSizes.x_short}
                        className="mb-3"
                        maxLength={30}
                        value={item.name}
                        onChange={value => handleUpdateItem(index, FieldNames.name, value)}
                        validations={nameValidations}
                        onValidate={isValid => updateValidationList(index, 'first', isValid)}
                      />
                      <BadgeLabelInput
                        label="金額※"
                        labelSize={ColumnSizes.x_short}
                        addonText={addonText}
                        maxLength={10}
                        value={item.cost}
                        onChange={value => handleUpdateItem(index, FieldNames.cost, value)}
                        onBlur={() => {
                          if (onBlur) {
                            const formattedValue = onBlur(item.cost)
                            if (formattedValue !== undefined) {
                              handleUpdateItem(index, FieldNames.cost, formattedValue)
                            }
                          }
                        }}
                        validations={costValidations}
                        onValidate={isValid => updateValidationList(index, 'second', isValid)}
                      />
                    </Col>
                    <Col xxl="3" className="d-flex justify-content-end align-items-center">
                      {index === items.length - 1 && (
                        <CustomButton
                          icon="plus"
                          outline
                          className="my-3 d-block text-nowrap"
                          onClick={handleAddItem}
                          show={showAddSmallButton}
                        >
                          追加
                        </CustomButton>
                      )}
                      <Button
                        outline
                        color="danger"
                        className="my-3 ms-2 d-block text-nowrap"
                        onClick={() => handleDeleteItem(index)}
                      >
                        削除
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
                {index < items.length - 1 && <hr className="m-0" />}
              </div>
            ))}
          </Card>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
